import { useEffect, useState } from "react";
import { IItemProperty } from "../../../../types/overview/itemTypes";
import ItemsServiceNew from "../../../../services/items/ItemsService";
import { Box, Typography } from "@mui/material";
import ItemFilterField from "./ItemFilterField";

export interface IItemFilterWindowProps {
    definitionId: number;
    definitionName: string | undefined;
}

export default function ItemFilterWindow(props: IItemFilterWindowProps){
    const [properties, setProperties] = useState<IItemProperty[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    
    useEffect(() => {
        loadData();
    }, [props.definitionId])
    
    function loadData(){
        ItemsServiceNew.getDefinitionPropertiesNonShared(props.definitionId).then(
            (result) => {
                setLoaded(true);
                setProperties(result);
            },
            (error) => {
                setError(true);
            }
        )
    }

    return <Box sx={{
        height: '100%',
        overflow: 'auto',
        '& > :not(style)': { mb: 2},
        }}>
            <Typography variant="h6">{props.definitionName}</Typography>
            {properties.map((x, i) => <ItemFilterField key={i} property={x}/>)}
    </Box>
}