import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../../../services/overview/EstateService";
import { SideWindowType } from "../OverviewSplitManager";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import FormModal from "../../../general/modals/FormModal";
import FloorForm from "../../forms/hierarchy/FloorForm";
import { SkobaEntityType, ISelect } from "../../../../types/overview/skobaTypes";
import { IFloorDetailed } from "../../../../types/overview/estateTypes";
import SingleTextFilter from "../../filters/SingleTextFilter";
import FloorsTable from "../../tables/hierarchy/FloorsTable";
import HierarchyFiltersMultipleNew from "../../../general/content/HierarchyFiltersMultipleNew";
import { prepareWFSLink } from "../../../general/maps/MapFunctions";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import FloorsSummary from "../../summary/FloorsSummary";


export interface IFloorsHierarchyProps{
    parent?: ISelect;
    showLocalMap?: boolean;
    getData : () => Promise<any[]>;
}

export default function FloorsHierarchy(props: IFloorsHierarchyProps){    
    const { changeSideWindow, openSideWindow, closeSideWindow, openHierarchy, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IFloorDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        if(props.showLocalMap){            
            changeSideWindow(SideWindowType.LocalMap)
            openHierarchy();
            openSideWindow();
        }
        else{            
            openHierarchy();
            closeSideWindow();
        }
    }, [])
    useEffect(() => {
        if(props.showLocalMap)
            setMapVectorData()
    }, [data, localMap])

    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(props.parent && localMap){            
            localMap?.setWfsLayers(prepareWFSLink(props.parent?.id, SkobaEntityType.Building));            
        }
    } 
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            <HierarchyFiltersMultipleNew
                data={data}
                getChildrenTable={(data) => <FloorsTable data={data} parent={props.parent}/>}
                getFilters={(data, onChange) => 
                    <SingleTextFilter 
                    data={data} 
                    onChange={onChange} 
                    filterProperty="name"
                    labelText="Hledat dle názvu patra"/> 
                }
                modifyButtons={props.parent ? [
                    <FormModal 
                        buttonText="Vytvořit patro"
                        title="Vytvořit nové patro"    
                        form={<FloorForm buttonText="Vytvořit" model={null} 
                                selectOptions={[{name : props.parent!.name, id: props.parent!.id}]} 
                                selectable={true}
                                onSubmit={EstateService.createFloor}
                            />}
                    />  
                ] : []}
                />
            <FloorsSummary data={data}/>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }