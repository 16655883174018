import * as React from "react";
import type { SVGProps } from "react";
const SvgSoubory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M28.039 69.26H291.96v181.479H28.039zM28.039 135.107 144.743 69.26"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M144.743 69.26v65.847H28.039M56.722 218.016h210.709M56.722 204.74h210.709M56.722 231.292h210.709"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
  </svg>
);
export default SvgSoubory;
