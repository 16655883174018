import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

export interface IComponentWithChildrenProps{
    children?: React.ReactNode;
}

export function HalfCenteredBox(props : IComponentWithChildrenProps){
    return <Box sx={{flexGrow : 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
        {props.children}
    </Box>
}
export function RowBox(props : IComponentWithChildrenProps){
    return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {props.children}
    </Box>
}
export function Loading(){
    return <Box sx={{ display: 'flex' }}>
        <CircularProgress />
    </Box>
}
export interface IError{
    error: any;
}
export function GError(props : IError){
    console.error(props.error)
    console.log(props.error.message ? props.error.message : props.error)
    return <Box sx={{ display: 'flex' }}>
        <Typography variant="h6">Nastala chyba při načítání dat:</Typography>
        <Typography variant="h6" color="red">{props.error.message ? props.error.message : props.error}</Typography>
    </Box>
}

export interface ILoadingErrorCompProps{
    loaded: boolean;
    error: any;
}
