import { IFile, IFileDetailed, IFileDownload } from "../../types/overview/fileTypes";
import ApiService from "../general/ApiService";

const getFiles = () : Promise<IFileDetailed[]>=> {
    return ApiService.apiGet("/File");
};
const getSpecificFile = (id : number) : Promise<IFileDetailed>=> {
    return ApiService.apiGet("/File/" + id);
};
const updateFile = (data: FormData) : Promise<void> => {
    return ApiService.apiPutForm("/File/" + data.get("id"), data);
}
const downloadFile = (id : number) : Promise<IFileDownload>=> {
    return ApiService.apiGetBlob("/File/download/link/" + id);
};
const createFile = (data: FormData) : Promise<IFile>=> {
    return ApiService.apiPostForm("/File", data);
};
const deleteFile = (item: IFile) : Promise<void> => {
    return ApiService.apiDelete("/File/" + item.id);
  }; 
const FilesService = {
    updateFile,
    getFiles, 
    getSpecificFile,
    downloadFile,
    createFile,
    deleteFile
};

export default FilesService;