import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import React from 'react';

 const SignInValidationSchema = Yup.object().shape({
    name: Yup.string().required('Jméno nemůže být prázdné'),
    surname: Yup.string().required('Přijmení nemůže být prázdné'),
    company: Yup.string(),
    cin: Yup.number().positive("IČO je kladné číslo"),
    adress: Yup.string().required('Adresa nemůže být prázdná'),
    postalCode: Yup.string().required('PSČ nemůže být prázdné'),
    city: Yup.string().required('Město nemůže být prázdné'),
    email: Yup.string()
        .max(100, 'Too Long!')
        .email('Email není ve správném formátu')
        .required('Email nemůže být prázdný'),
    password: Yup.string()
        .min(6,"Heslo musí obsahovat alespoň 6 znaků")
        .required(),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), ""], 'Passwords must match')
});

export interface ISignUpProps{
    onSubmit: (values : any) => Promise<void>;
    disabled?: boolean;
}

export default function SignUpForm(props : ISignUpProps) {
    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            company: '',
            cin: '',
            adress: '',
            postalCode: '',
            city: '',
            email: '',
            password: '',
            passwordConfirm: ''
        },
        validationSchema: SignInValidationSchema,
        onSubmit: props.onSubmit,
    });    
    const dis = props.disabled ? props.disabled : false;
    return <div>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { mb: 1.5},
            }}
            onSubmit={formik.handleSubmit}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
                <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Jméno*"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    sx={{ marginRight: 1 }}
                    disabled={dis}
                />
                <TextField
                    fullWidth
                    id="surname"
                    name="surname"
                    label="Přijmení*"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    error={formik.touched.surname && Boolean(formik.errors.surname)}
                    helperText={formik.touched.surname && formik.errors.surname}
                    disabled={dis}
                />
            </Box>
            <TextField                
                fullWidth
                autoComplete="new-password"
                id="email"
                name="email"
                type="email"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={dis}
            />   
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
                <TextField
                    autoComplete="off"
                    fullWidth
                    id="company"
                    name="company"
                    label="Společnost"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                    sx={{ marginRight: 1 }}
                    disabled={dis}
                />
                <TextField
                    autoComplete="off"
                    fullWidth
                    id="cin"
                    name="cin"
                    label="IČO"
                    value={formik.values.cin}
                    onChange={formik.handleChange}
                    error={formik.touched.cin && Boolean(formik.errors.cin)}
                    helperText={formik.touched.cin && formik.errors.cin}
                    disabled={dis}
                />
            </Box>   
            <TextField                
                fullWidth
                autoComplete="off"
                id="adress"
                name="adress"
                label="Adresa*"
                value={formik.values.adress}
                onChange={formik.handleChange}
                error={formik.touched.adress && Boolean(formik.errors.adress)}
                helperText={formik.touched.adress && formik.errors.adress}
                disabled={dis}
            />   
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
                <TextField
                    autoComplete="new-password"
                    fullWidth
                    id="city"
                    name="city"
                    label="Město*"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    sx={{ marginRight: 1 }}
                    disabled={dis}
                />
                <TextField
                    autoComplete="new-password"
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    label="PSČ*"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                    disabled={dis}
                />
            </Box>   
            <TextField                
                fullWidth
                autoComplete="new-password"
                id="password"
                name="password"
                type="password"
                label="Heslo*"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                disabled={dis}
            />   
            <TextField                
                fullWidth
                autoComplete="off"
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                label="Heslo znovu*"
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange}
                error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                disabled={dis}
            />             
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }}>
                Zaregistrovat
            </Button>
    </Box>
    </div>    
}