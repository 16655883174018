import { ISelect } from "../../../../types/overview/skobaTypes";
import SkobaTable, { ISkobaTableDataProps } from "../../../general/tables/SkobaTable";
import { useNavigate } from "react-router-dom";

export interface IFloorsTableProps extends ISkobaTableDataProps{
    parent?: ISelect;
}
const FloorsHeader = ["Jméno", "Plocha [m2]", "Budova", "Prostory", "Plocha prostorů [m2]", "Prvky"];

export default function FloorsTable(props : IFloorsTableProps){
    const navigate = useNavigate();

    return <SkobaTable
    data={props.data}
    header={FloorsHeader}
    rowDefinition={
        {
            columnDefinitions: [{
                property: "name",
                onClick: (object) => navigate("/overview/floors/" + object.id)
            },{
                property: "area"
            },{
                property: "buildingName",
                onClick: (object) => navigate("/overview/buildings/" + object.buildingId)
            },{
                property: "roomsCount"                
            },{
                property: "roomsArea"                
            },{
                property: "itemsCount"                
            }
            ],
            // buttons: (object) => [
            //     <FormModal
            //         size="small"                     
            //         title="Upravit"    
            //         buttonElement={<AiFillEdit/>}
            //         form={<FloorForm buttonText="Uložit změny" model={object} 
            //                 selectOptions={[props.parent === undefined ? 
            //                     {name : object.buildingName, id: object.building}
            //                 : props.parent]} 
            //                 selectable={false}
            //                 onSubmit={HierarchyService.updateFloor}/>}
            //     />,
            //     <DeleteModal 
            //         size="small" 
            //         itemName={object.name} 
            //         onConfirm={() => HierarchyService.deleteFloor(object)}/>
            // ]            
        }
    }/>
}