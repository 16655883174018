import { Button, Divider, Popover, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import AuthService from "../../../services/general/AuthService";
import { AdminLayout } from "../../general/layouts/Layouts";
import React from 'react';
import { getMainColor } from "../../overview/Functions";

export default function UserInfo() {
    const [user, setUser] = useState(AuthService.getCurrentUser());
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };            

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    if (user == null)        
        return <Navigate to="/signin" />

    function handleLogOut() : void {
        handleClose();
        AuthService.signout();
        setUser(null);
    }
    return <Box sx={{display: "flex", flexGrow:"0", gap:"20px", alignItems:"center"}}>
        <Box onClick={handleClick} sx={{
            display: "flex",
            direction: "row",
            alignItems: "center",
        }}        
        >
            {user.username === "Owner" ? 
                <Avatar src="/profile.jpg" sx={{ width: 56, height: 56 }}/>
                : <Avatar sx={{ bgcolor: getMainColor(), width: 56, height: 56}}>
                    {/* {user.username[0]} */}
                    </Avatar>
            }
            <Box flexGrow={1} pl={1} sx={{fontWeight:"bold" }}>
            {user.username}
            </Box>
        </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                sx={{
                    mt:2
                }}
            >
            <Box>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        m:2,
                        mb:1
                    }}>
                     <Avatar sx={{ bgcolor: getMainColor(), width: 56, height: 56  }}>{user.username[0]}</Avatar>
                     <Box sx={{                         
                        display: 'flex', 
                        flexDirection: 'column',
                        mx:1,
                    }}>
                        <Typography 
                            component="span"
                            variant="h6"
                            color="Black"
                            noWrap
                        >
                            {user.username}
                        </Typography>
                        <Typography 
                            component="span"
                            variant="subtitle2"
                            color="darkgray"
                            noWrap
                        >
                            {user.email}
                        </Typography>
                     </Box>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'column', m:1}}>
                    <AdminLayout>
                        <Button fullWidth variant="contained" component={Link} to="/admin" onClick={() => {handleClose()}}
                            sx={{
                                mb:1,
                                '&:hover': {
                                    color: 'white',
                                }
                            }}>
                            Administrace
                        </Button>                    
                    </AdminLayout>
                    {/* <Button fullWidth variant="contained" sx={{mb:1}}>
                        Změna hesla
                    </Button> */}
                    {/* <FormModalMUI
                        onOpen={handleClose}
                        buttonText="ZMĚNA HESLA"
                        title="Změnit heslo"    
                        form={<ChangePasswordForm 
                                onSubmit={AuthService.changePassword}
                        />}
                        />      */}
                    <Button fullWidth variant="contained" component={Link} to="/changePassword" onClick={() => {handleClose()}}
                            sx={{
                                mb:1,
                                '&:hover': {
                                    color: 'white',
                                }
                            }}>
                            Změnit heslo
                        </Button>    
                    <Button fullWidth variant="contained" onClick={handleLogOut}>
                        Odhlásit se
                    </Button>
                </Box>               
            </Box>
            </Popover>
    </Box>
}