import { AiFillEdit } from "react-icons/ai";
import ProcessService from "../../../../services/processes/ProcessService";
import ProcessForm from "../../forms/process/ProcessForm";
import { formatDatetime, getParentLocationId, getParentName, getParentRoute, getParentType } from "../../Functions";
import DeleteModal from "../../../general/modals/DeleteModal";
import DeleteProcessModal from "../../../general/modals/DeleteProcessModal";
import FormModal from "../../../general/modals/FormModal";
import SkobaTable, { ISkobaTableDataProps } from "../../../general/tables/SkobaTable";
import { useNavigate } from "react-router-dom";
import ProcessesTable from "./ProcessesTable";


const UnclosedProcessesTableHeader = ["Jméno", "Datum", "Kategorie", "Cena", "Předmet"];

export default function UnclosedProcessesTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    return <ProcessesTable
        data={props.data}
        buttons={(object) => {
            object.location = getParentLocationId(object);
            object.locationType = getParentType(object);
            return [
                <FormModal 
                    size="small" 
                    buttonElement={<AiFillEdit/>}
                    color="success"
                    title="Dokončit process"    
                    form={<ProcessForm buttonText="Dokončit" model={object} finishing={true}
                        onSubmit={ProcessService.finishProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                />,
                <FormModal 
                    size="small" 
                    buttonElement={<AiFillEdit/>}
                    title="Upravit process"    
                    form={<ProcessForm buttonText="Uložit změny" model={object} finishing={false}
                        onSubmit={ProcessService.updateProcess} 
                        onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                />,
                object.repeating ? 
                <DeleteProcessModal 
                    size="small" 
                    bodyText={object.name} 
                    onDeleteOne={() => ProcessService.deleteProcessRepeating(1, object.id, object.startDate)}
                    onDeleteAll={() => ProcessService.deleteProcessRepeating(3, object.id, object.startDate)}
                    onDeleteThisAndNext={() => ProcessService.deleteProcessRepeating(2, object.id, object.startDate)}/> 
                :
                <DeleteModal 
                    size="small" 
                    itemName={object.name}
                    onConfirm={() => ProcessService.deleteProcess(object)}/>
            ]        
        }}
    />
    return <SkobaTable
        data={props.data}
        header={UnclosedProcessesTableHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name",
                },{
                    property: "startDate",
                    getValue: (object) => formatDatetime(object.startDate)
                },{
                    property: "categoryName"
                },{
                    property: "cost"
                },{
                    property: "",
                    getValue: (object) => getParentName(object),
                    onClick: (object) => navigate(getParentRoute(object))
                }
                ],
                buttons: (object) => {
                    object.location = getParentLocationId(object);
                    object.locationType = getParentType(object);
                    return [
                        <FormModal 
                            size="small" 
                            buttonElement={<AiFillEdit/>}
                            color="success"
                            title="Dokončit process"    
                            form={<ProcessForm buttonText="Dokončit" model={object} finishing={true}
                                onSubmit={ProcessService.finishProcess} 
                                onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                        />,
                        <FormModal 
                            size="small" 
                            buttonElement={<AiFillEdit/>}
                            title="Upravit process"    
                            form={<ProcessForm buttonText="Uložit změny" model={object} finishing={false}
                                onSubmit={ProcessService.updateProcess} 
                                onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                        />,
                        object.repeating ? 
                        <DeleteProcessModal 
                            size="small" 
                            bodyText={object.name} 
                            onDeleteOne={() => ProcessService.deleteProcessRepeating(1, object.id, object.startDate)}
                            onDeleteAll={() => ProcessService.deleteProcessRepeating(3, object.id, object.startDate)}
                            onDeleteThisAndNext={() => ProcessService.deleteProcessRepeating(2, object.id, object.startDate)}/> 
                        :
                        <DeleteModal 
                            size="small" 
                            itemName={object.name}
                            onConfirm={() => ProcessService.deleteProcess(object)}/>
                    ]        
                }    
            }
        }
    />
}