import { IWFSResponse } from "../../types/overview/skobaTypes";
import ApiService from "../general/ApiService";

const getWFS = (url: string) : Promise<IWFSResponse> => {
    return ApiService.apiGet("/WFS/" + url);
};
const GeodataService = {
    getWFS,
};

export default GeodataService;