import * as React from "react";
import type { SVGProps } from "react";
const SvgRevize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M116.629 63.857H260.1v228.054H116.629zM137.143 90.02h102.442M137.143 112.29h102.442M137.143 133.738h102.442M137.143 90.02h102.442M137.143 154.588h102.442M137.143 176.036h102.442M137.143 200h102.442M137.143 222.27h102.442M137.143 243.718h102.442M137.143 200h102.442M137.143 264.568h102.442"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M58.879 164.182V28.089h57.835q21.816 0 31.702 3.667t15.828 13.043 5.942 21.444q0 15.318-9.005 25.297t-26.922 12.579q8.913 5.199 14.714 11.418 5.802 6.22 15.643 22.094l16.617 26.551H148.37l-19.866-29.614q-10.584-15.874-14.482-20.006-3.9-4.13-8.262-5.662-4.364-1.532-13.832-1.532h-5.57v56.814zm27.479-78.537h20.33q19.773 0 24.694-1.671 4.92-1.67 7.705-5.755t2.785-10.212q0-6.87-3.667-11.094t-10.351-5.337q-3.342-.465-20.052-.465H86.358z"
      style={{
        fill: "#3b85b8",
        fillRule: "nonzero",
      }}
    />
  </svg>
);
export default SvgRevize;
