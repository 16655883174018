import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import React from 'react';

 const SignInValidationSchema = Yup.object().shape({
    email: Yup.string()
        .max(100, 'Too Long!')
        .email('Email není ve správném formátu')
        .required('Email nemůže být prázdný'),
    password: Yup.string()        
        .required()
});

export interface ISignInProps{
    onSubmit: (values : any) => Promise<void>;
    disabled?: boolean;
}

export default function SignInForm(props : ISignInProps) {
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: SignInValidationSchema,
        onSubmit: props.onSubmit,
    });    
    const dis = props.disabled ? props.disabled : false;
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            <TextField                
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={dis}

            />   
            <TextField                
                fullWidth
                id="password"
                name="password"
                type="password"
                label="Heslo"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                disabled={dis}

            />             
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={dis}>
                Příhlásit
            </Button>
    </Box>
    </div>    
}
