import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../../../services/overview/EstateService";
import { SideWindowType } from "../OverviewSplitManager";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import FormModal from "../../../general/modals/FormModal";
import { SkobaEntityType, ISelectBetter, CategoryType } from "../../../../types/overview/skobaTypes";
import SingleTextFilter from "../../filters/SingleTextFilter";
import { Grid, InputLabel, MenuItem, RadioGroup, FormControlLabel, Radio, FormControl, Select, FormLabel, Box, Stack } from "@mui/material";
import { IItemCategory } from "../../../../types/overview/itemTypes";
import UnclosedProcessesTable from "../../tables/processes/UnclosedProcessesTable";
import ClosedProcessesTable from "../../tables/processes/ClosedProcessesTable";
import UpcomingProcessesTable from "../../tables/processes/UpcomingProcessesTable";
import ProcessService from "../../../../services/processes/ProcessService";
import ProcessForm from "../../forms/process/ProcessForm";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import TabLayout from "../../../general/layouts/TabLayout";
import ProcessesSummary from "../../summary/ProcessesSummary";
import CategoryService from "../../../../services/categories/CategoryService";


export interface IProcessesHierarchyProps{
    getData : () => Promise<any[]>;
    select?: ISelectBetter;
}

export default function ProcessesHierarchy(props: IProcessesHierarchyProps){    
    const { changeSideWindow, openSideWindow, openHierarchy, chartObj, controlMap, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<number>(0);
    const [categories, setCategories] = useState<IItemCategory[]>([]);
    const [timeFilter, setTimeFilter] = useState<number>(1);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [error, setError] = useState<any>(null);
    const [errorFilters, setErrorFilters] = useState<any>(null);
    const today = new Date(Date.now())

    useEffect(() => {
        loadData();
        getChartData();
        openSideWindow();
        changeSideWindow(SideWindowType.ChartManager)
    }, []) 
    useEffect(() => {
        if(chartData.length > 0)
            chartObj?.changeData(chartData, 1);
    }, [chartObj, chartData]) 

    useEffect(() => {
        getProcesses()
    }, [timeFilter])
    function getChartData(){
        EstateService.getHierarchyData("Chart/donut")
        .then((data) => {
            chartObj?.changeData(data, 1);
        })
    }
    function loadData(){
        const categoriesPromise = CategoryService.getCategoriesByType(CategoryType.Process);
        const processesPromise = props.select === undefined ? ProcessService.getToDate(timeFilter) : ProcessService.getToDateOfParent(timeFilter, props.select.type, props.select.id);
        const chartPromise = EstateService.getHierarchyData("Chart/donut");
        Promise.all([categoriesPromise, processesPromise, chartPromise])
        .then(
            ([data1, data2, data3]) => {
                setLoaded(true);
                setLoadedData(true);
                setCategories(data1);
                setData(data2);
                setChartData(data3)
            },
            (error) => {
                setLoaded(true);
                setLoadedData(true);
                setError(error);
                setErrorFilters(error);
            }
        )
    }
    function getProcesses(){
        setLoadedData(false);
        const promise = props.select === undefined ? ProcessService.getToDate(timeFilter) : ProcessService.getToDateOfParent(timeFilter, props.select.type, props.select.id);
        promise.then(
            (result) => {
                setLoadedData(true);
                setData(result);
            },
            (error) => {
                setLoadedData(true);
            setError(error);
            }
        );
    }
    function onFilterChange(data: any[]){
        setFilteredData(data);
    }
    function getFilters() {
        return <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="main-item-category-filter">Kategorie</InputLabel>
                    <Select 
                        variant="filled"   
                        fullWidth
                        labelId="main-item-category-filter"
                        label="Kategorie" 
                        value={categoryFilter.toString()}
                        defaultValue={""}
                        onChange={(event) => {
                            setCategoryFilter(parseInt(event.target.value));
                        }}
                    >
                        <MenuItem value={"0"}>Všechny kategorie</MenuItem>
                        {categories.map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                    </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={6}>
                <FormControl>
                    <FormLabel id="planned-process-time-filter-label">Časový úsek</FormLabel>
                        <RadioGroup
                            row
                            value={timeFilter}
                            onChange={(event) => {
                                setTimeFilter(Number((event.target as HTMLInputElement).value));
                            }}
                            name="planned-process-time-filter"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Dnes" />
                            <FormControlLabel value="2" control={<Radio />} label="Týden" />
                            <FormControlLabel value="3" control={<Radio />} label="Měsíc" />                                    
                            <FormControlLabel value="4" control={<Radio />} label="Rok" />                                    
                        </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    }
    
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={error} loading={!loaded}>
            <Box sx={{p:0, height:"100%", '& > *': { mb: 2 }}}>
                <Grid container spacing={2} rowSpacing={2}>
                        <Grid item xs={12}>                    
                            <SingleTextFilter 
                                data={data} 
                                onChange={onFilterChange} 
                                filterProperty="name"
                                labelText="Hledat dle názvu procesu"/>
                        </Grid>
                        <Grid item xs={12}>
                            {getFilters()}                    
                        </Grid>
                </Grid>
                <TabLayout titles={["Planované processy", "Neuzavřené procesy", "Uzavřené processy"]}>
                    <Box sx={{'& > *': { mb: 2 }}}>
                        <UpcomingProcessesTable data={filteredData.filter((item) => new Date(item.startDate) > today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))} />
                <ProcessesSummary data={data.filter((item) => new Date(item.startDate) > today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))}/>
                    </Box>
                    <Box sx={{'& > *': { mb: 2 }}}>
                        <UnclosedProcessesTable data={filteredData.filter((item) => new Date(item.startDate) <= today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))} />
                        <ProcessesSummary data={data.filter((item) => new Date(item.startDate) <= today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))}/>
                    </Box>
                    <Box sx={{'& > *': { mb: 2 }}}>
                        <ClosedProcessesTable data={filteredData.filter((item) => item.finished && (categoryFilter === 0 || item.category === categoryFilter))} />
                        <ProcessesSummary data={data.filter((item) => item.finished && (categoryFilter === 0 || item.category === categoryFilter))}/>
                    </Box>
                </TabLayout>
                {props.select !== undefined && 
                    <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{pt:1}}
                    >
                        <FormModal 
                            buttonText="Vytvořit proces"
                            title="Vytvořit nový proces"    
                            form={<ProcessForm buttonText="Vytvořit" model={null} finishing={false}                        
                                selectOptions={props.select}
                                onSubmit={ProcessService.createProcess} 
                                onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                        />    
                    </Stack>
                }
            </Box>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }