import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';

const RRuleValidationSchema = Yup.object({
    frequency: Yup
        .string()
        .required('Frekvence nemůže být prázdný')
        .matches(/^(DAILY|WEEKLY|MONTHLY|YEARLY)$/),
    interval: Yup
        .number()
        .positive('Musí být kladné číslo'),
    until: Yup
        .date()
        .nullable(),
    endOption: Yup.number()
    .min(0, 'Konec musí být jednou ze 3 možností')
    .max(2, 'Konec musí být jednou ze 3 možností'),
    byMonth : Yup
        .number()
        .min(1, 'Měsíc v roce musí být mezi 1-12')
        .max(12, 'Měsíc v roce musí být mezi 1-12'),
    byMonthDay : Yup
        .number()
        .min(1, 'Den v měsící musí být mezi 1-31')
        .max(31, 'Den v měsící musí být mezi 1-31'),
    bySetPos : Yup.number(),
    byDayArray : Yup.array(),
    byDay : Yup
        .string(),
        // .matches(
        //     /^(MO|TU|WE|TH|FR|SA|SU)$/,
        //     'Den musí být z PO, ÚT, ST, ČT, PÁ, SO, NE'
        //   )
        //   .when('frequency', {
        //     is: (frequency : string) => frequency == 'WEEKLY',
        //     then: (schema) => Yup.string().required(),
        //     otherwise: (schema) => Yup.string(),
        //   }),
    monthlyRadio: Yup.string().matches(/^(0|1)$/),
    yearlyRadio: Yup.string().matches(/^(0|1)$/),
    count : Yup.number().positive().min(1),
});

const dayNumberOptions = Array.from({ length: 31 }, (_, i) => i + 1);
const onTheFirstOptions = ["První", "Druhé", "Třetí", "Čtvrté", "Poslední"];
const onTheSecondOptions = [
    ["Pondělí", "MO"],
    ["Úterý", "TU"],
    ["Středa", "WE"],
    ["Čtvrtek", "TH"],
    ["Pátek", "FR"],
    ["Sobota", "SA"],
    ["Neděle", "SU"],
    ["Pracovní den", "MO,TU,WE,TH,FR"],
    ["Víkend", "SA,SU"],
    ["Den", "MO,TU,WE,TH,FR,SA,SU"],
];
const dayShortOptions = [
    ["PO", "MO"],
    ["ÚT", "TU"],
    ["ST", "WE"],
    ["ČT", "TH"],
    ["PÁ", "FR"],
    ["SO", "SA"],
    ["NE", "SU"]
];
const endOptions = ["Nikdy", "Po", "Do"]
const monthOptions = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"]

export interface IRRuleFormProps{
    rrule : string | null | undefined;
}

export interface IRRuleFormMethods {
    getRRule: () => string;
}

export const RRuleForm = forwardRef<IRRuleFormMethods, IRRuleFormProps>((props, ref) =>{
    useImperativeHandle(ref, () => ({
        getRRule
    }));
    useEffect(() => {
        if(props.rrule){
            const parts = props.rrule.split(";");
            let part = parts.shift();
            part = part?.replace("FREQ=", "");
            switch(part){
                case "DAILY":{                    
                    formik.setFieldValue("frequency", "DAILY");
                    break;
                }
                case "WEEKLY":{
                    formik.setFieldValue("frequency", "WEEKLY");
                    part = parts.shift();
                    if(part?.includes("BYDAY=")){
                        part = part?.replace("BYDAY=", "");
                        formik.setFieldValue("byDayArray", part?.split(",") ?? []);
                    }
                    break;
                }
                case "MONTHLY":{
                    formik.setFieldValue("frequency", "MONTHLY");
                    part = parts.shift();
                    if(part?.includes("BYMONTHDAY=")){
                        formik.values.monthlyRadio = "0";
                        part = part?.replace("BYMONTHDAY=", "");
                        formik.setFieldValue("byMonthDay", Number(part))
                    }
                    else{
                        formik.values.monthlyRadio = "1";
                        part = part?.replace("BYSETPOS=", "");
                        formik.setFieldValue("bySetPos", Number(part))
                        part = parts.shift();
                        part = part?.replace("BYDAY=", "");
                        formik.setFieldValue("byDay", part ?? "")
                    }
                    break;
                }
                case "YEARLY":{
                formik.setFieldValue("frequency", "YEARLY");
                    part = parts.shift();
                    if(part?.includes("BYMONTH=")){
                        formik.values.yearlyRadio = "0";
                        part = part?.replace("BYMONTH=", "");
                        formik.setFieldValue("byMonth", Number(part))
                        part = parts.shift();
                        part?.replace("BYMONTHDAY=", "");
                    }
                    else{
                        formik.values.yearlyRadio = "1";
                        part = part?.replace("BYSETPOS=", "");
                        formik.setFieldValue("bySetPos", Number(part))
                        part = parts.shift();
                        part = part?.replace("BYDAY=", "");
                        formik.setFieldValue("byDay", part ?? "")
                        part = parts.shift();
                        part = part?.replace("BYMONTH=", "");
                        formik.setFieldValue("byMonth", Number(part))
                    }
                    break;
                }
                default:{
                    break;
                }
            }
            part = parts.shift();
            part = part?.replace("INTERVAL=", "");
            formik.setFieldValue("interval", Number(part))
            part = parts.shift();
            formik.setFieldValue("endOption", 0)
            if(part !== undefined){
                if(part.includes("COUNT=")){                    
                    part = part?.replace("COUNT=", "");                    
                    formik.setFieldValue("endOption", 1)
                    formik.setFieldValue("count", Number(part))
                }
                else{                        
                    part = part?.replace("UNTIL=", "");          
                    formik.setFieldValue("endOption", 2)
                    formik.setFieldValue("until", new Date(part))
                }
            }
        }
    }, [])
    function getRRule() : string{
        const values = formik.values; 
        let rruleString = `FREQ=${values.frequency}`;
            if(values.frequency === "WEEKLY"){
                rruleString += ";BYDAY=" + values.byDayArray.join(",");
            }
            else if(values.frequency === "MONTHLY"){
                if(formik.values.monthlyRadio === "0"){
                    rruleString += `;BYMONTHDAY=${values.byMonthDay}`;
                }
                else{
                    rruleString += `;BYSETPOS=${values.bySetPos};BYDAY=${values.byDay}`;
                }   
            }
            else if(values.frequency === "YEARLY"){
                if(formik.values.yearlyRadio === "0"){
                    rruleString += `;BYMONTH=${values.byMonth};BYMONTHDAY=${values.byMonthDay}`;
                }
                else{
                    rruleString += `;BYSETPOS=${values.bySetPos};BYDAY=${values.byDay};BYMONTH=${values.byMonth}`;
                }
            }
            rruleString += `;INTERVAL=${values.interval}`;
            if(values.endOption === 1){
                rruleString += `;COUNT=${values.count}`;
            }
            else if(values.endOption === 2){
                rruleString += `;UNTIL=${dayjs(values.until).format("YYYYMMDDTHHmmss[Z]")}`;
            }
        return rruleString
    }
    const formik = useFormik({
        initialValues: {
            frequency: "YEARLY",
            interval: 1,
            monthlyRadio : "0",
            yearlyRadio : "0",
            byMonthDay: 1,
            bySetPos: 1,
            byDay: "MO",
            byMonth: 1,
            byDayArray: [],
            until: new Date(Date.now()),
            endOption: 0,
            count: 1,
        },
        validationSchema: RRuleValidationSchema,
        onSubmit: () => {}
    });

    function getEndSelect() : JSX.Element{
        if(formik.values.endOption === 1){
            return <FormControl fullWidth>
                <TextField
                fullWidth
                id="count"
                name="count"
                label="Počet opakování"
                type="number"                        
                value={formik.values.count}
                onChange={formik.handleChange}
                error={formik.touched.count && Boolean(formik.errors.count)}
                helperText={formik.touched.count && formik.errors.count}
            />
            </FormControl>
        }
        else if(formik.values.endOption === 2){
            return <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        value={dayjs(formik.values.until)} 
                        label="Datum" 
                        onChange={(value) => formik.setFieldValue("until", value, true)}
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                error: formik.touched.until && Boolean(formik.errors.until),
                                // helperText: formik.touched.until && formik.errors.until
                            }
                        }}
                        format="DD/MM/YYYY"
                    />
                </LocalizationProvider>            
            </FormControl>
        }
        else return <div></div>;
    }
    function getEndOption() : JSX.Element{
        return <Grid container spacing={1}>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="end-label">Konec</InputLabel>
                    <Select
                        fullWidth
                        labelId="end-label"
                        id="end"
                        name="endOption"
                        label="Konec"
                        value={formik.values.endOption}
                        onChange={formik.handleChange}
                        error={formik.touched.endOption && Boolean(formik.errors.endOption)}
                    >
                        {endOptions.map((end, index) => (
                        <MenuItem key={index} value={index}>
                            {end}
                        </MenuItem>
                    ))}    
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                {getEndSelect()}
            </Grid>
        </Grid>
    }
    function getYearlyOption() : JSX.Element{
        return <React.Fragment>
        <FormControl>
            <RadioGroup
            row
            name="yearlyRadio"
            onChange={formik.handleChange}
            value={formik.values.yearlyRadio}
            >
                <FormControlLabel value="0" control={<Radio />} label="Dne" />
                <FormControlLabel value="1" control={<Radio />} label="Specifického dne" />
            </RadioGroup>
        </FormControl>
        {formik.values.yearlyRadio === "0" ?
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Select
                fullWidth
                name="byMonth"
                value={formik.values.byMonth}
                onChange={formik.handleChange}
                error={formik.touched.byMonth && Boolean(formik.errors.byMonth)}
                >
                    {monthOptions.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                            {month}
                        </MenuItem>
                    ))}                
                </Select>
            </Grid>
            <Grid item xs={6}>
                <Select
                fullWidth
                name="byMonthDay"
                value={formik.values.byMonthDay}
                onChange={formik.handleChange}
                error={formik.touched.byMonthDay && Boolean(formik.errors.byMonthDay)}
                >
                    {dayNumberOptions.map((day, index) => (
                        <MenuItem key={index} value={index + 1}>
                            {day}
                        </MenuItem>
                    ))}                
                </Select>
            </Grid>
        </Grid>           
        : 
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Select
                    fullWidth
                    name="bySetPos"
                    value={formik.values.bySetPos}
                    onChange={formik.handleChange}
                    error={formik.touched.bySetPos && Boolean(formik.errors.bySetPos)}
                    >
                        {onTheFirstOptions.map((option, index) => (
                            <MenuItem key={index} value={index + 1}>
                                {option}
                            </MenuItem>
                        ))}                
                </Select>
            </Grid>
            <Grid item xs={4}>
                <Select
                    fullWidth
                    name="byDay"
                    value={formik.values.byDay}
                    onChange={formik.handleChange}
                    error={formik.touched.byDay && Boolean(formik.errors.byDay)}
                    >
                        {onTheSecondOptions.map((option, index) => (
                            <MenuItem key={index} value={option[1]}>
                                {option[0]}
                            </MenuItem>
                        ))}                
                </Select>
            </Grid>
            <Grid item xs={4}>
                <Select
                fullWidth
                name="byMonth"
                value={formik.values.byMonth}
                onChange={formik.handleChange}
                error={formik.touched.byMonth && Boolean(formik.errors.byMonth)}
                >
                    {monthOptions.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                            {month}
                        </MenuItem>
                    ))}                
                </Select>
            </Grid>
        </Grid>
    }
    </React.Fragment>
    }
    function getMonthlyOption() : JSX.Element{        
        return <React.Fragment>
            <FormControl>
                <RadioGroup
                row
                name="monthlyRadio"
                onChange={formik.handleChange}
                value={formik.values.monthlyRadio}                
                >
                    <FormControlLabel value="0" control={<Radio />} label="Dne" />
                    <FormControlLabel value="1" control={<Radio />} label="Specifického dne" />
                </RadioGroup>
            </FormControl>
            {formik.values.monthlyRadio === "0" ?
                <Select
                fullWidth
                name="byMonthDay"
                value={formik.values.byMonthDay}
                onChange={formik.handleChange}
                error={formik.touched.byMonthDay && Boolean(formik.errors.byMonthDay)}
                >
                    {dayNumberOptions.map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}
                        </MenuItem>
                    ))}                
                </Select>
            : 
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Select
                        fullWidth
                        name="bySetPos"
                        value={formik.values.bySetPos}
                        onChange={formik.handleChange}
                        error={formik.touched.bySetPos && Boolean(formik.errors.bySetPos)}
                        >
                            {onTheFirstOptions.map((option, index) => (
                                <MenuItem key={index} value={index + 1}>
                                    {option}
                                </MenuItem>
                            ))}                
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        fullWidth
                        name="byDay"
                        value={formik.values.byDay}
                        onChange={formik.handleChange}
                        error={formik.touched.byDay && Boolean(formik.errors.byDay)}
                        >
                            {onTheSecondOptions.map((option, index) => (
                                <MenuItem key={index} value={option[1]}>
                                    {option[0]}
                                </MenuItem>
                            ))}                
                    </Select>
                </Grid>
            </Grid>
        }
        </React.Fragment>
    }   
    function getWeeklyOption() : JSX.Element{
        return <React.Fragment>
            <FormControl component="fieldset">
                <FormLabel component="legend">Výběr dnů</FormLabel>
                    <FormGroup aria-label="position" row>
                    {dayShortOptions.map((day, index) => (
                        <FormControlLabel
                            key={index}
                            control={<Checkbox />}
                            name="byDayArray"
                            label={day[0]}
                            value={day[1]}
                            labelPlacement="top"
                            onChange={formik.handleChange}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                        />
                    ))}                   
                </FormGroup>
            </FormControl>
        </React.Fragment>
    }
    function getDailyOption() : JSX.Element{
        return <React.Fragment>
        </React.Fragment>
    }
    function getOption() : JSX.Element{
        switch(formik.values.frequency){
            case "DAILY":
                return getDailyOption(); 
            case "WEEKLY":
                return getWeeklyOption();
            case "MONTHLY":
                return getMonthlyOption();
            case "YEARLY":
                return getYearlyOption();
            default: <div></div>
        }
        return <React.Fragment>
            
        </React.Fragment>
    }
    function getAdornment() : string{
        switch(formik.values.frequency){
            case "DAILY":
                return formik.values.interval < 2 ? "den" : "dny"; 
            case "WEEKLY":
                return formik.values.interval < 2 ? "týden" :"týdny";
            case "MONTHLY":
                return formik.values.interval < 2 ? "měsíc" :"měsíce";
            case "YEARLY":
                return formik.values.interval < 2 ? "rok" :"roky";
        }
        return ""
    }
    return <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <InputLabel id="frequency-label">Opakuje se</InputLabel>
                        <Select
                            fullWidth
                            labelId="frequency-label"
                            id="frequency"
                            name="frequency"
                            label="Opakuje se"
                            value={formik.values.frequency}
                            onChange={formik.handleChange}
                            error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                        >
                            <MenuItem value="DAILY">Denně</MenuItem>
                            <MenuItem value="WEEKLY">Týdně</MenuItem>
                            <MenuItem value="MONTHLY">Měsíčně</MenuItem>
                            <MenuItem value="YEARLY">Ročně</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="interval"
                        name="interval"
                        label={formik.values.interval < 2 ? "Každý" : "Každé"}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{getAdornment()}</InputAdornment>,
                        }}
                        value={formik.values.interval}
                        onChange={formik.handleChange}
                        error={formik.touched.interval && Boolean(formik.errors.interval)}
                        helperText={formik.touched.interval && formik.errors.interval}
                        sx={{ marginRight: 1 }}
                    />
                </Grid>
            </Grid>              
            <Divider />
            {getOption()}   
            <Divider />
            {getEndOption()}
            
    </Box>
})
RRuleForm.displayName = 'RRuleForm';
