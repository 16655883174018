// import "./Overview.css"
import OverviewSplitManager from "../content/OverviewSplitManager";
import SidebarLayout from "../../general/layouts/SidebarLayout";
import OverviewSidebar from "../content/OverviewSidebar";
import { SkobaColors } from "../../../types/colors/SkobaTheme";

export default function OverviewSidebarLayout(){    
    return <SidebarLayout    
            level={1}
            sideBarContent={<OverviewSidebar/>}
            contentBackground={SkobaColors.Gray3}
        >
        <OverviewSplitManager refreshProp={false}/>
    </SidebarLayout>
    
}