import { ISignInResponse } from "../../types/general/authTypes";
  
const getLocalRefreshToken = () : string => {
    const userJson = localStorage.getItem("user")
    if(userJson){
        const user = JSON.parse(userJson);
        return user?.refreshToken;
    }
    return "";
};

const getLocalAccessToken = () : string => {
    const userJson = localStorage.getItem("user")
    if(userJson){
        const user = JSON.parse(userJson);
        return user?.accessToken;
    }
    return "";
};

const updateLocalAccessToken = (token : string) : void => {
    const userJson = localStorage.getItem("user")
    if(userJson){
        const user = JSON.parse(userJson);
        user.accessToken = token;
        localStorage.setItem("user", JSON.stringify(user));
    }
};
const updateLocalRefreshToken = (token : string) : void => {
    const userJson = localStorage.getItem("user")
    if(userJson){
        const user = JSON.parse(userJson);
        user.refreshToken = token;
        localStorage.setItem("user", JSON.stringify(user));
    }
};

const getUser  = () : ISignInResponse | null=> {
    const userJson = localStorage.getItem("user")
    if(userJson){
        return JSON.parse(userJson);            
    }
    return null;
};

const setUser = (user : ISignInResponse) : void => {
    localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
    localStorage.removeItem("user");
};

const TokenService = {
getLocalRefreshToken,
getLocalAccessToken,
updateLocalAccessToken,
updateLocalRefreshToken,
getUser,
setUser,
removeUser,
};

export default TokenService;