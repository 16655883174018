import * as React from "react";
import type { SVGProps } from "react";
const SvgPrvky = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M115.802 52.107h-44.4v242.508h177.196V52.107h-44.4"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M204.198 37.546h-22.787C176.915 30.43 170.11 26.009 160 25.442c-10.406-.62-17.874 3.836-23.134 12.104h-21.064v29.122h88.396z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="m93.224 100.265 7.503 9.816 15.075-25.217"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M128.68 101.173h101.072"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="m93.224 141.348 7.503 9.816 15.075-25.217"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M128.68 142.256h101.072"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="m93.224 183.242 7.503 9.816 15.075-25.216"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M128.68 184.15h101.072"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="m93.224 225.32 7.503 9.816 15.075-25.217"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M128.68 226.228h101.072"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="m93.224 100.265 7.503 9.816 15.075-25.217"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M128.68 101.173h101.072"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="m93.224 268.846 7.503 9.816 15.075-25.217"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M128.68 269.754h101.072"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
  </svg>
);
export default SvgPrvky;
