import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface ISearchTextFilterProps{
    onChange: (data : any[]) => void;
    labelText: string;
    data: any[];
    filterProperty: string;
}

export default function SingleTextFilter(props : ISearchTextFilterProps){    
    const [searchText, setSearchText] = useState("");
    useEffect(() => {
        props.onChange(
            props.data.filter((item) => item[props.filterProperty].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        ));
    }, [searchText, props.data]) 

    return <Grid container spacing={2} rowSpacing={2}>
    <Grid item xs={12}>
        <TextField    
            variant="filled"             
            fullWidth
            id="name"
            name="name"
            label={props.labelText}
            value={searchText} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(event.target.value);
            }}
        />
    </Grid>            
</Grid>
}