import SkobaTable, { ISkobaTableDataProps } from "../../general/tables/SkobaTable";
import { formatDatetime } from "../../overview/Functions";


const PaymentsTableHeader = ["Datum platby",
    "Zaplacené období od", 
    "Zaplacené období do", 
    "Částka", 
    "Faktura ke stažení"
];

export default function PaymentsTable(props : ISkobaTableDataProps){
    return <SkobaTable
    data={props.data}
    header={PaymentsTableHeader}
    rowDefinition={
        {
            columnDefinitions: [{
                property: "dateOfPayment",
                getValue: (object) => formatDatetime(object.dateOfPayment)
            },{
                property: "start",
                getValue: (object) => formatDatetime(object.start)
            },{
                property: "end",
                getValue: (object) => formatDatetime(object.end)
            },{
                property: "amount"                
            },{
                property: "id",
                onClick: (object) => {}
            }
            ]                 
        }
    }
/>
}