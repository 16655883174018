import { createContext } from "react";
import { ISideWindowControl } from "../../../types/overview/skobaTypes";

// export const RefreshDataContext2 = createContext(() => {});
export const SideWindowContext = createContext<ISideWindowControl>({
    changeSideWindow: () => {},
    openSideWindow: () => {},
    closeSideWindow: () => {},
    closeHierarchy: () => {},
    openHierarchy: () => {},
});