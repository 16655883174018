import * as React from "react";
import type { SVGProps } from "react";
const SvgPatra = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M78.725 89h162.549v51H78.725z"
      style={{
        fill: "#4d4d4d",
      }}
    />
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M79.563 25.794h162.549V282.75H79.563z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "6.98px",
      }}
    />
    <path
      d="M51.075 282.75h223.498"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "7.04px",
      }}
    />
  </svg>
);
export default SvgPatra;
