import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowType } from "../../../components/overview/content/OverviewSplitManager";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import EstateService from "../../../services/overview/EstateService";
import FloorsHierarchy from "../../../components/overview/content/multiple/FloorsHierarchy";
import RoomsHierarchy from "../../../components/overview/content/multiple/RoomsHierarchy";
import FilesHierarchy from "../../../components/overview/content/multiple/FilesHierarchy";
import ProcessesHierarchy from "../../../components/overview/content/multiple/ProcessesHierarchy";
import SensorsHierarchy from "../../../components/overview/content/multiple/SensorsHierarchy";
import BuildingInfo from "../../../components/overview/itemInfo/hierarchy/BuildingInfo";
import { Feature } from "ol";
import { IVectorLayer, IVectorData } from "../../../components/general/maps/ControlMap";
import { getBuildingStyle } from "../../../components/general/maps/ControlMapStyles";
import { Point } from "ol/geom";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";
import { IBuildingDetailed } from "../../../types/overview/estateTypes";
import HierarchySinglePageNew from "../../../components/general/content/HierarchySinglePageNew";
import LoadingWrapper from "../../../components/general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../components/general/contexts/RefreshDataContext";
import ItemsHierarchy from "../../../components/overview/content/multiple/ItemsHierarchy";
import ItemsServiceNew from "../../../services/items/ItemsService";


export default function BuildingPage(){    
    const { changeSideWindow, openSideWindow, openHierarchy, controlMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IBuildingDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        openSideWindow();
        changeSideWindow(SideWindowType.ControlMap);
    }, [])
    useEffect(() => {
        zoomAndShow()
    }, [data])
    function loadData(){
        EstateService.getHierarchyData("Buildings/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function zoomAndShow() : void{        
        if(data && data.xDef && data.yDef){
            const vectorLayers = [] as IVectorLayer[];
            const features = [] as IVectorData[];
            features.push({
                feature: new Feature({
                    geometry: new Point([data.xDef as number,data.yDef as number]),
                    type: "Building",
                    name: data.name,
                    id: data.id

                }),
                onClick: () => navigate("/overview/buildings/" + data.id),
                onLeave: () => {console.log("leave")},
                onEnter: () => {console.log("enter")},
            } as IVectorData )      
            vectorLayers.push({layers: features, style: getBuildingStyle()});
            controlMap?.setVectorDataAndZoom(vectorLayers, {center: [data.xDef, data.yDef], zoom: 14});
            // controlMap?.zoomOnPoint({center: [data.xDef, data.yDef], zoom: 14})
        }
    }
    //TODO: replace all static urls
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}                
                id={data.id} 
                type={SkobaEntityType.Building} 
                tabTitles={["Informace", "Patra", "Prostory", "Čidla", "Prvky", "Činnosti", "Soubory"]}  >
                    <BuildingInfo data={data!} onMount={() => {
                            changeSideWindow(SideWindowType.ControlMap);
                            openSideWindow();
                            zoomAndShow();
                        }}
                        onRefresh={() => {
                            zoomAndShow();
                        }}
                        refreshProps={[data, controlMap]}
                    />
                    
                    <FloorsHierarchy 
                        showLocalMap
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getHierarchyData("Floors/allOfParent?type=1&id=" + data.id)}/>
                    <RoomsHierarchy
                        showLocalMap
                        buildingOptions={{id: data.id, name: data.name}}
                        getData={async () => EstateService.getHierarchyData("Rooms/allOfParent?type=1&id=" + data.id)}/>
                    <SensorsHierarchy 
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getHierarchyData("Sensors/allOfParent?type=1&id=" + data.id)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Building}}
                        getData={async () => ItemsServiceNew.getAllItemsOfParent(data.id, SkobaEntityType.Building)}/>
                    <ProcessesHierarchy 
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Building}} 
                        getData={() => EstateService.getHierarchyData("Processes")} />
                    <FilesHierarchy getData={() => EstateService.getHierarchyData("File/allOfParent?type=1&id=" + data.id)}
                        parent={{name: data.name, id: data.id, type: 1}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>    
  }