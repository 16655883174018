import { AiFillEdit } from "react-icons/ai";
import EstateService from "../../../services/overview/EstateService";
import DeleteModal from "../../general/modals/DeleteModal";
import FormModal from "../../general/modals/FormModal";
import CategoryForm from "../forms/CategoryForm";
import { CategoryType, SkobaEntityType } from "../../../types/overview/skobaTypes";
import SkobaTable, { ISkobaTableDataProps } from "../../general/tables/SkobaTable";
import CategoryService from "../../../services/categories/CategoryService";

export interface IGenericCategoryTableProps extends ISkobaTableDataProps{
    updateText: string
    type: CategoryType
}

const GenericCategoryHeader = ["Název"];

export default function GenericCategoryTable(props : IGenericCategoryTableProps ){
    return <SkobaTable
        data={props.data}
        header={GenericCategoryHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name"
                }
                ],
                buttons: (object) => [
                    <FormModal
                        size="small"
                        title="Upravit kategorii"
                        buttonElement={<AiFillEdit/>}
                        form={<CategoryForm 
                            buttonText="Uložit změny" 
                            model={object} 
                            type={props.type} 
                            onSubmit={CategoryService.updateCategory}/>}
                    /> ,
                    <DeleteModal itemName={object.name} 
                    onConfirm={() => CategoryService.deleteCategory(object)}/>
                ]                
            }
        }
    />
}