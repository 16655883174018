import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import  "./SignIn.css"
import AuthService from '../../services/general/AuthService';
import { IResetPassword } from '../../types/general/authTypes';
import { NavLink, useSearchParams } from 'react-router-dom';
import ResetPasswordForm from '../../components/accountManagement/forms/ResetPasswordForm';

/*
Template from https://github.com/mui/material-ui/blob/v5.11.9/docs/data/material/getting-started/templates/sign-in/SignIn.tsx
*/

export default function ResetPasswordPage() {
  const [submit, setSubmit] = React.useState(false);    
  const [error, setError] = React.useState(false);
  const [out, setOut] = React.useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')?.replaceAll(" ", "+");
  const email = searchParams.get('email');
  const handleSubmit = async (data : IResetPassword) => {
    setSubmit(true);
    setError(false);
    await AuthService.resetPassword(data)
      .then((res) =>{
        if(res === false){
          setError(true);
          setOut("");
        }
        else{          
          setError(false);
          setOut("Změna hesla proběhla v pořádku.");
        }
        setSubmit(false);
  }).catch(err => {    
    setError(true);
  })};
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > :not(style)': { mt: 1},
        }}
      >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
      Změnit heslo
      </Typography>      
      <ResetPasswordForm token={token ? token : undefined} email={email ? email : undefined} onSubmit={handleSubmit} disabled={submit}/> 
        <NavLink to="/signin">Vrátit se zpět</NavLink>  
      {error && 
        <Typography component="i" color="red">
          Při komunikaci s přihlašovacím serverem se stala chyba.
          Opakujte akci později.
        </Typography>                  
      }
      {out !== "" && 
        <Typography component="i" color="green">
          {out}
        </Typography>                  
      }
      </Box>

    </Container>
);
}