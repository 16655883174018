import { SvgIcon, SvgIconProps } from '@mui/material';
import SvgBudovy from '../../assets/icons/Budovy';
import SvgArealy from '../../assets/icons/Arealy';
import SvgCidla from '../../assets/icons/Cidla';
import SvgMistnost from '../../assets/icons/Mistnost';
import SvgPatra from '../../assets/icons/Patra';
import SvgPlatby from '../../assets/icons/Platby';
import SvgPrvky from '../../assets/icons/Prvky';
import SvgRevize from '../../assets/icons/Revize';
import SvgSmazat from '../../assets/icons/Smazat';
import SvgSoubory from '../../assets/icons/Soubory';
import SvgUpravit from '../../assets/icons/Upravit';
import SvgUzivatele from '../../assets/icons/Uzivatele';
import SvgLogo from '../../assets/icons/Logo';
import SvgPrehled2 from '../../assets/icons/Prehled2';
import SvgPosunMapy from '../../assets/icons/PosunMapy';

export function SkobaBuildingIcon(props: SvgIconProps){
    return <SvgIcon {...props} {...props} component={SvgBudovy} inheritViewBox />;
}
export function SkobaLogo(props: SvgIconProps){
    return <SvgIcon {...props} {...props} component={SvgLogo} inheritViewBox />;
}
export function SkobaPremiseIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgArealy} inheritViewBox />;
}
export function SkobaSensorIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgCidla} inheritViewBox />;
}
export function SkobaRoomIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgMistnost} inheritViewBox />;
}
export function SkobaFloorIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgPatra} inheritViewBox />;
}
export function SkobaPaymentIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgPlatby} inheritViewBox />;
}
export function SkobaOverviewIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgPrehled2} inheritViewBox />;
}
export function SkobaItemsIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgPrvky} inheritViewBox />;
}
export function SkobaProcessesIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgRevize} inheritViewBox />;
}
export function SkobaDeleteIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgSmazat} inheritViewBox />;
}
export function SkobaFilesIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgSoubory} inheritViewBox />;
}
export function SkobaEditIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgUpravit} inheritViewBox />;
}
export function SkobaUserIcon(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgUzivatele} inheritViewBox />;
}
export function SkobaPosunMapy(props: SvgIconProps){
    return <SvgIcon {...props} component={SvgPosunMapy} inheritViewBox />;
}