import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import { SideWindowType } from "../../../components/overview/content/OverviewSplitManager";
import FilesHierarchy from "../../../components/overview/content/multiple/FilesHierarchy";
import ProcessesHierarchy from "../../../components/overview/content/multiple/ProcessesHierarchy";
import SensorsHierarchy from "../../../components/overview/content/multiple/SensorsHierarchy";
import RoomInfo from "../../../components/overview/itemInfo/hierarchy/RoomInfo";
import EstateService from "../../../services/overview/EstateService";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";
import { IRoomDetailed } from "../../../types/overview/estateTypes";
import HierarchySinglePageNew from "../../../components/general/content/HierarchySinglePageNew";
import LoadingWrapper from "../../../components/general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../components/general/contexts/RefreshDataContext";
import { prepareWFSLink } from "../../../components/general/maps/MapFunctions";
import ItemsServiceNew from "../../../services/items/ItemsService";
import ItemsHierarchy from "../../../components/overview/content/multiple/ItemsHierarchy";

export default function RoomPage(){
    const { changeSideWindow, openSideWindow, openHierarchy, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IRoomDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        openSideWindow();
        changeSideWindow(SideWindowType.LocalMap);
    }, [id])
    useEffect(() => {
        setMapVectorData();
    }, [data, localMap])
    useEffect(() =>{
        zoomToLayer()
    }, [data, localMap?.state.wfsLayers])
    function loadData(){
        EstateService.getHierarchyData("Rooms/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(data && localMap){
            localMap?.setActiveConnectors([{id: data.id, type: SkobaEntityType.Room}])
            localMap?.setWfsLayers(prepareWFSLink(data.id, SkobaEntityType.Room));
        }       
    }
    function zoomToLayer(){
        if(localMap && data){
            // localMap?.zoomToLayer(getWFSNameFloor(data.id))
        }
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew 
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Room} 
                tabTitles={["Informace", "Čidla", "Prvky", "Činnosti", "Soubory"]} >
                    <RoomInfo data={data} onMount={() => {
                            changeSideWindow(SideWindowType.LocalMap);
                            openSideWindow();
                            setMapVectorData();
                        }}
                        onRefresh={() => {
                            zoomToLayer();
                        }}
                        refreshProps={[data, localMap]}/>
                    <SensorsHierarchy 
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getHierarchyData("Sensors/allOfParent?type=3&id=" + data.id)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Room}}
                        getData={async () => ItemsServiceNew.getAllItemsOfParent(data.id, SkobaEntityType.Room)}/>
                    <ProcessesHierarchy getData={() => EstateService.getHierarchyData("Processes")} 
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Room}} />
                    <FilesHierarchy getData={() => EstateService.getHierarchyData("File/allOfParent?type=3&id=" + data.id)}
                        parent={{name: data.name, id: data.id, type: 3}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }