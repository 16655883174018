import { Geometry, Point } from "ol/geom";
import { Projection } from "ol/proj";
import proj4 from "proj4";
import React, { ReactNode } from "react";
import { RFeature, RLayerCluster, RLayerVector, RMap, ROSM,  ROverlay,  RPopup } from "rlayers";
import { RView } from "rlayers/RMap";
import "./Map.css"
import {get as getProjection} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import { Feature, MapBrowserEvent } from "ol";
import { RFill, RStroke, RStyle, RText } from "rlayers/style";
import $ from 'jquery'
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Paper } from "@mui/material";
import "ol/ol.css";
import CloseIcon from '@mui/icons-material/Close';
import { getClusterStyle } from "./ControlMapStyles";

proj4.defs("EPSG:5514",
"+proj=krovak +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +units=m +no_defs +type=crs");
register(proj4);
const proj5514 = getProjection('EPSG:5514') as Projection;
const proj5514Extent = [-935719.38, -1244158.89, -418597.43, -911041.21];
proj5514.setExtent(proj5514Extent);
proj5514.setWorldExtent(proj5514Extent);

export interface IControlMapState{
    view : RView;
    map : React.RefObject<RMap>;
    imageRef : React.RefObject<HTMLCanvasElement>;
    vectorLayers : IVectorLayer[];
    projection : Projection;
    cursorPosition: number[];
    current: Feature<Geometry> | null;
    sideMenuMode: number;
}
export interface IVectorData{
    feature: Feature<Geometry>;   
    onEnter: () => void;
    onLeave: () => void;
    onClick: () => void;
}
export interface IVectorLayer{
    style: JSX.Element;
    layers: IVectorData[];
}
const krovakOrigin = [-671679.09, -1082290.85]

class ControlMap extends React.Component<object, IControlMapState>{
    constructor(props : object){
        super(props);
        this.state = ({
            sideMenuMode: -1,
            view: { center: krovakOrigin, zoom: 3 },
            map : React.createRef() as React.RefObject<RMap>,
            imageRef : React.createRef() as React.RefObject<HTMLCanvasElement>,
            vectorLayers : [],
            projection : proj5514,
            cursorPosition : [0,0],
            current: null,
        })
    }
    popup = React.createRef() as React.RefObject<RPopup>
    center() : void{
        this.setState({
            view: { center: krovakOrigin, zoom: 3 }
        })
    }
    refreshMap() : void{
        if(this.state.map !== null){
            this.state.map.current?.ol.updateSize();
        }
    }
    zoomOnPoint(newCenter : RView) : void{
        this.setState({
            view : newCenter
        })
    }    
    setVectorData(data : IVectorLayer[]){
        this.setState({
            vectorLayers : data
        })
    }
    setVectorDataAndZoom(data : IVectorLayer[], newCenter : RView){
        this.setState({
            vectorLayers : data,
            view : newCenter
        })
    }
    handleMapClick(e: MapBrowserEvent<UIEvent>){
        if(this.state.sideMenuMode === 0){
            this.setState({
                sideMenuMode: -1
            })
            this.openKNLink(e.map.getCoordinateFromPixel(e.pixel));
        }
        else if(this.state.sideMenuMode === 1){
            if (this.popup !== null && this.popup.current !== null){
                this.popup.current.show()
                this.setState({
                    cursorPosition: e.map.getCoordinateFromPixel(e.pixel)
                })
            }
        }
    }
    openKNLink(coordinates: number[]) : void{
        const link = `https://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&x=${coordinates[0].toFixed(0)}&y=${coordinates[1].toFixed(0)}`;
        window.open(link,"_blank");
    }
    handleSideMenuClick(mode: number){
        if(mode === this.state.sideMenuMode){
            this.setState({
                sideMenuMode: -1
            })
        }
        else{
            this.setState({
                sideMenuMode: mode
            })
        }
    }    
    getVectorLayersCluster() : ReactNode{
        //https://mmomtchev.github.io/rlayers/#/cluster       
        return <RLayerCluster
            onClick={(e) => {
                const features = e.target.get("features") as Feature[]
                if(features.length === 1){
                    this.state.vectorLayers.map(x=>x.layers).flat(1)
                    .find(x => x.feature.get("type") === features[0].get("type") && x.feature.get("name") === features[0].get("name"))?.onClick()
                }
            }}
            onPointerEnter={(e) => {
                const features = e.target.get("features") as Feature[]
                if(features.length === 1){
                    $("#row-" + features[0].get("id") + "-"+ features[0].get("name").replace(/ /g, '').replace(".","_")).toggleClass("selected-table-row");
                    this.setState({
                        current: features[0]
                    });
                    this.state.vectorLayers.map(x=>x.layers).flat(1)
                    .find(x => x.feature.get("type") === features[0].get("type") && x.feature.get("name") === features[0].get("name"))?.onEnter()
                }             
            }}
            onPointerLeave={(e) => {
                const features = e.target.get("features") as Feature[]
                if(features.length === 1){
                    $("#row-" + features[0].get("id") + "-"+ features[0].get("name").replace(/ /g, '').replace(".","_")).removeClass("selected-table-row");
                    this.state.current === features[0] && this.setState({current: null});
                    this.state.vectorLayers.map(x=>x.layers).flat(1)
                    .find(x => x.feature.get("type") === features[0].get("type") && x.feature.get("name") === features[0].get("name"))?.onLeave()
                }
            }}>            
            {this.state.vectorLayers.map(x => x.layers.map(y => y.feature)).flat(1).map((z,i) => {
                return (<RFeature 
                    key={i} 
                    feature={z}></RFeature>)
            })}        
            {getClusterStyle()}
      </RLayerCluster>
    }
    render(): React.ReactNode {
        this.refreshMap();
        return (
        <React.Fragment>
            <div className="map-container">      
                <RMap
                    ref={this.state.map}                    
                    className="interactive-map"
                    initial={this.state.view}
                    view={[this.state.view, (view) => this.setState({view: view})]}
                    projection={this.state.projection}
                    // extent={proj5514Extent}
                    // minZoom={3} 
                    onClick={(e) => this.handleMapClick(e)}
                    >
                    <ROSM key={"osm-map-grayscale"}/>
                    {this.getVectorLayersCluster()}
                    <RLayerVector zIndex={10}>
                        {this.state.current ? (
                            <div>
                            <RFeature geometry={this.state.current.getGeometry()}>
                                <RStyle>
                                    <RStroke color="transparent" width={0} />
                                    <RFill color="transparent"  />
                                </RStyle>
                                <ROverlay autoPosition={true}>
                                    <div className="control-map-item-overlay" >
                                        <div>
                                            {/* Type: <strong>{this.state.current.get("type")}</strong> */}
                                            Typ: <strong>{this.state.current.get("type") === "Premise" ? "Areál" : "Budova"}</strong>
                                        </div>
                                        <div>
                                            {/* Name: <strong>{this.state.current.get("name")}</strong> */}
                                            Jméno: <strong>{this.state.current.get("name")}</strong>
                                        </div>
                                    </div>                                    
                                </ROverlay>
                            </RFeature>
                            </div>
                        ) : null}
                    </RLayerVector>
                    <RLayerVector>
                        <RFeature geometry={new Point([this.state.cursorPosition[0], this.state.cursorPosition[1]])}>
                            <RStyle>
                                <RText text="x" scale={2}>
                                    <RFill color="white"/>
                                    <RStroke width={1} color="cyan"/>
                                </RText>
                                {/* <RCircle radius={5}>
                                    
                                </RCircle> */}
                            </RStyle>
                            <RPopup ref={this.popup} trigger={"click"} >
                                <Card sx={{                                    
                                    transform: "translate(-50%, 10px)",
                                }}>
                                    <CardHeader  
                                        sx={{
                                            backgroundColor: "#f2f2f2",
                                        
                                        }}                                 
                                        action={
                                        <IconButton onClick={() => {
                                                if (this.popup !== null && this.popup.current !== null){
                                                    this.popup.current.hide();
                                                    this.setState({
                                                        cursorPosition: [0,0]
                                                    })
                                                }
                                            }}>
                                            <CloseIcon  />
                                        </IconButton>
                                        }
                                        title="Poloha bodu"
                                    />
                                    <CardContent>
                                            <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <strong>Y:</strong>
                                            </Grid>
                                            <Grid item xs={10}>
                                                {(-1*this.state.cursorPosition[0]).toFixed(3)}
                                            </Grid>
                                            <Grid item xs={2}>
                                                <strong>X:</strong>
                                            </Grid>
                                            <Grid item xs={10}>
                                                {(-1*this.state.cursorPosition[1]).toFixed(3)}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </RPopup>
                        </RFeature>
                    </RLayerVector>
                </RMap>
                <Box sx={{
                    p: 0,
                    position: "absolute",
                    top: "30%",
                    right: "0",
                    transform: "translate(0, -50%)",
                    zIndex: 100,
                    backgroundColor: "white",
                    }}>                        
                        <Paper elevation={3} sx={{
                                paddingTop: 1,
                                paddingBottom: 1,
                                paddingLeft: 1,
                                display: 'flex',
                                flexDirection: 'column',  
                                gap: "5px",
                                backgroundColor: "#f2f2f2"
                            }}>
                            <Button size="small" 
                                onClick={() => this.handleSideMenuClick(0)}
                                variant={this.state.sideMenuMode == 0 ? "contained" : "outlined"}
                            >
                                KN
                            </Button>
                            <Button 
                                onClick={() => this.handleSideMenuClick(1)}
                                variant={this.state.sideMenuMode == 1 ? "contained" : "outlined"}
                                size="small">
                                XY
                            </Button>
                        </Paper>
                </Box>
            </div>
            <canvas width={100} height={100} id="chartCanvas" style={{display: "none"}}>
            </canvas>
        </React.Fragment>
        )
    }
}
export default ControlMap