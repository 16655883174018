import { Feature } from "ol";
import { Geometry } from "ol/geom";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { PieChart } from "react-minimal-pie-chart";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import { RStyle, RCircle, RFill, RStroke, RIcon } from "rlayers/style";


export function getPremiseStyle(): JSX.Element {
    return (<RStyle>
        <RCircle radius={15}>
            <RFill color="orange" />
            <RStroke width={1} color="black" />
        </RCircle>
    </RStyle>);
}
export function getBuildingStyle(): JSX.Element {
    return (<RStyle>
        <RCircle radius={10}>
            <RFill color="blue" />
            <RStroke width={1} color="black" />
        </RCircle>
    </RStyle>);
}

export function generatePieChart(features: Feature<Geometry>[]): JSX.Element{
    const buildings = features.filter(x => x.get("type") === "Building")
    const premises = features.filter(x => x.get("type") === "Premise")
    const pieData: Data<any> = []; 
    if(buildings.length !== 0)
    pieData.push({value: buildings.length, color: 'blue', title : buildings.length})
    if(premises.length !== 0)
    pieData.push({value: premises.length, color: 'orange', title : premises.length })
    return <PieChart className={"pieCCCCC"} data={pieData} viewBoxSize={[80,80]} center={[50,50]} radius={20} label={({ dataEntry }) => dataEntry.title}/>
}

export function getClusterStyle() : JSX.Element{
    return <RStyle
    render={(feature : Feature<Geometry>) => {
        const size = feature.get("features").length;                
        if (size > 1) {
            const html = $($.parseHTML(ReactDOMServer.renderToString(generatePieChart(feature.get("features")))));
            const c = document.getElementById("chartCanvas") as HTMLCanvasElement;
            const ctx = c.getContext("2d");
            const width = c.width;
            const height = c.height;
            ctx!.beginPath();
            ctx!.arc(width/2, height/2, 20, 0, 2 * Math.PI);
            ctx!.lineWidth = 1;
            ctx!.strokeStyle = 'black';
            ctx!.stroke();

            html.children("path").each((i, e) => {
                const p = new Path2D(e.getAttribute("d") as string);
                ctx!.fillStyle = e.getAttribute("fill") as string;
                ctx!.lineWidth = Number(e.getAttribute("stroke-width") as string);
                ctx!.strokeStyle = e.getAttribute("stroke") as string;
                ctx!.stroke(p)
            })
            if(html.children("text").length === 1){                            
                const text = html.children("text")[0].textContent as string
                ctx!.fillStyle = "#FFFFFF"
                ctx!.textAlign = "center"
                ctx!.textBaseline = "middle"
                ctx!.font = "1.2em Segoe UI"                            
                ctx!.fillText(text, width/2, height/2);
            }
            else{
                html.children("text").each((i, e) => {
                    const x = Number(e.getAttribute("dx") as string)
                    const y = Number(e.getAttribute("dy") as string)
                    const text = e.textContent as string
                    ctx!.fillStyle = "#FFFFFF"
                    ctx!.textAlign = "center"
                    ctx!.textBaseline = "middle"
                    ctx!.font = "1.2em Segoe UI"                            
                    ctx!.fillText(text, width/2 - (width * x / 100), height/2 - (height * y / 100));
                    
                })
            }
            return (
            <React.Fragment>
                <RIcon img={document.getElementById("chartCanvas") as HTMLCanvasElement} imgSize={[100,100]} /> 
            </React.Fragment>
            );
        }
        // We have a single feature cluster
        if(feature.get("features")[0].get("type") === "Premise"){
            return (<React.Fragment>
                        <RCircle radius={15}>
                            <RFill color="orange"/>
                            <RStroke width={1} color="black"/>
                        </RCircle>
                </React.Fragment>
            )
        }
        else{
            return (
                <React.Fragment>
                    <RCircle radius={10}>
                        <RFill color="blue"/>
                        <RStroke width={1} color="black"/>
                    </RCircle>
                </React.Fragment>
            );
        }
        
    }}
/>           
}