import { IProcess, IProcessCreate } from "../../types/overview/processTypes";
import { SkobaEntityType } from "../../types/overview/skobaTypes";
import ApiService from "../general/ApiService";

const deleteProcess = (item: IProcess) : Promise<void> => {
    return ApiService.apiDelete("/Processes/" + item.id);
};
const deleteProcessRepeating = (type : number, id : number, date : string) : Promise<void> => {
    return ApiService.apiDelete("/Processes/" + type + "/" + id + "/" + date);
};
const updateProcess = (item: IProcessCreate) : Promise<void> => {
    return ApiService.apiPut("/Processes", item);
}
const updateRepeatingProcess = (type: number, item: IProcessCreate) : Promise<void> => {
    return ApiService.apiPut("/Processes/" + type, item);
}
const createProcess = (item: IProcessCreate) : Promise<IProcess> => {
    return ApiService.apiPost("/Processes", item);
}
const finishProcess = (item: IProcess) : Promise<void> => {
    return ApiService.apiPut("/Processes/Finish", item);
}
const getToDate = (type: number) : Promise<IProcess[]> => {
    return ApiService.apiGet("/Processes/toDate/" + type);
}
const getToDateOfParent = (type: number, parentType: SkobaEntityType, parentId: number) : Promise<IProcess[]> => {
    return ApiService.apiGet("/Processes/" + parentType + "/" + parentId  + "/toDate/" + type);
}
const ProcessService = {
    deleteProcess,
    createProcess,
    deleteProcessRepeating,
    updateRepeatingProcess,
    updateProcess,
    finishProcess,
    getToDate,
    getToDateOfParent
};

export default ProcessService;