import Split from "react-split";
import "./OverviewSplitManager.css"
import React, { ReactElement, useEffect, useState } from "react";
import ControlMap from "../../general/maps/ControlMap";
import LocalMap from "../../general/maps/LocalMap";
import $ from 'jquery'
import ChartManager from "../../general/charts/ChartManager";
import { ISideWindowControl } from "../../../types/overview/skobaTypes";
import Matterport from "../../general/maps/Matterport";
import ViewSwitcher from "../../general/maps/ViewSwitcher";
import { Outlet, useNavigate } from "react-router-dom";
import { SideWindowContext } from "../../general/contexts/SideWindowContext";
import { Box, Container } from "@mui/material";
import { SkobaColors } from "../../../types/colors/SkobaTheme";

export enum SideWindowType{
    ControlMap = 0,
    LocalMap = 1,
    ChartManager = 2,
    Matterport = 3
}

export interface IOverviewSplitManagerProps{
    refreshProp: boolean;
}

export default function OverviewSplitManager(props: IOverviewSplitManagerProps){
    // const controlMapRef = useRef<IControlMapMethods>(null);
    const controlMapRef = React.createRef<ControlMap>() as React.RefObject<ControlMap>;
    const localMapRef = React.createRef<LocalMap>() as React.RefObject<LocalMap>;
    const chartRef = React.createRef<ChartManager>() as React.RefObject<ChartManager>;
    const matterportRef = React.createRef<Matterport>() as React.RefObject<Matterport>;

    const[controlMapObj, setControlMapObj] = useState<ControlMap>();
    const[localMapObj, setLocalMapObj] = useState<LocalMap>();
    const[chartObj, setChartObj] = useState<ChartManager>();
    const[matterportObj, setMatterportObj] = useState<Matterport>();

    const[sideWindow, setSideWindow] = useState(0);    
    const[resizing, setResizing] = useState(false);
    const[size, setSize] = useState([50,50]);
    const[prevSize, setPrevSize] = useState([50,50]);
    const[hierarchyVisible, sethierarchyVisible] = useState(true);
    const[sideWindowVisible, setSideWindowVisible] = useState(true);
    
    const[switcherVisible, setSwitcherVisible] = useState(false);
    const[switcherEnabled, setSwitcherEnabled] = useState(false);

    const navigate = useNavigate();

    useEffect(() =>{
        if(controlMapRef.current !== null)
            setControlMapObj(controlMapRef.current)
    }, [controlMapRef])

    useEffect(() =>{
        if(localMapRef.current !== null)
        setLocalMapObj(localMapRef.current)
    }, [localMapRef])

    useEffect(() =>{
        if(chartRef.current !== null)
        setChartObj(chartRef.current)
    }, [chartRef])

    useEffect(() =>{
        if(matterportRef.current !== null)
        setMatterportObj(matterportRef.current)
    }, [matterportRef])

    useEffect(() =>{
        setResizing(false);
        if(controlMapObj){
            sethierarchyVisible(true);
            controlMapObj?.center();
        }
    }, [props.refreshProp])

    function changeSideWindow(type: SideWindowType){
        setSideWindow(type);
        setSideWindowVisible(true);
    }
    function openHierarchy(){
        if(!hierarchyVisible){
            const newSize = size[0] === 0 ? [50,50] : prevSize;            
            setSize(newSize)
            setSideWindowVisible(true);
            sethierarchyVisible(true);
            $(".gutter").css("display", "inherit")
            $(".ow-sm-mainwindow").css("width", "calc(" + newSize[0] + "% - 5px)")
            $(".ow-sm-sidewindow").css("width", "calc(" + newSize[1] + "% - 5px)")
        }
    }
    function closeHierarchy(){
        if(hierarchyVisible){
            setPrevSize(size)
            setSize([0,100]);
            setSideWindow(0);
            sethierarchyVisible(false);
            setSideWindowVisible(true);
            controlMapObj?.center();
            $(".gutter").css("display", "none")
            $(".ow-sm-mainwindow").css("width", "calc(0% - 5px)");
            $(".ow-sm-sidewindow").css("width", "calc(100% - 5px)");
        }
    }
    function openSideWindow(){
        if(!sideWindowVisible){
            const newSize = size[1] === 0 ? [50,50] : prevSize; 
            setSize(newSize)
            setSideWindowVisible(true);
            $(".gutter").css("display", "inherit")
            $(".ow-sm-mainwindow").css("width", "calc(" + newSize[0] + "% - 5px)")
            $(".ow-sm-sidewindow").css("width", "calc(" + newSize[1] + "% - 5px)")
        }
    }
    function closeSideWindow(){
        if(sideWindowVisible){
            setPrevSize(size)
            setSideWindowVisible(false);
            setSize([100,0]);
            $(".gutter").css("display", "none")
            $(".ow-sm-mainwindow").css("width", "calc(100% - 5px)");
            $(".ow-sm-sidewindow").css("width", "calc(0% - 5px)");
        }
    }
    function getMap() : ReactElement{
        switch(sideWindow){
            case SideWindowType.ControlMap:
                return <React.Fragment>
                    <ControlMap ref={controlMapRef}/>
                </React.Fragment>
            case SideWindowType.LocalMap:
                return  <React.Fragment>
                    {switcherVisible &&                        
                        <ViewSwitcher has3D={switcherEnabled} onClick={(type) => changeSideWindow(type)}/>
                    }
                    <LocalMap ref={localMapRef}/>
                </React.Fragment>
            case SideWindowType.ChartManager:
                return <React.Fragment>
                        <ChartManager ref={chartRef}/>
                </React.Fragment>
            case SideWindowType.Matterport:
                return  <React.Fragment>
                    {switcherVisible &&                        
                        <ViewSwitcher has3D={switcherEnabled} onClick={(type) => changeSideWindow(type)}/>
                    }
                    <Matterport ref={matterportRef}/>
                </React.Fragment>
            default:
                return <div></div>
        }
    }
    function getSideWindowControl() : ISideWindowControl{
        return {
            changeSideWindow: (type: SideWindowType) => changeSideWindow(type),
            openSideWindow: () => openSideWindow(),
            closeSideWindow: () => closeSideWindow(),
            closeHierarchy: () => closeHierarchy(),
            openHierarchy: () => openHierarchy(),
            chartObj: chartObj,
            controlMap: controlMapObj,    
            localMap: localMapObj,
        }
    }
    function getView() : JSX.Element{
        return <Split 
                style={{ 
                    height: "100%",
                    display: "flex", 
                    flexDirection: "row", 
                    background: "white",
                }}
                id={size.toString()}
                onDrag={(size) => setSize(size)} 
                onDragStart={() => setResizing(true)}
                sizes={[50, 50]} 
                minSize={0}
                gutter={(index: number, direction: "horizontal" | "vertical") => {
                    const gutter = document.createElement('div');
                    const svgElement = document.createElement('img');
                    svgElement.src = "/posun_mapy.svg";
                    gutter.appendChild(svgElement);
                    gutter.className = `gutter gutter-${direction}`
                    return gutter
                }}
                >
                    <Box className="ow-sm-mainwindow" sx={{ width: '100%', height: '100%', overflow: "hidden", 
                        mr:"30px", boxShadow: "5px 5px 10px -5px rgba(0,0,0,0.7);", background: SkobaColors.Gray3}}>
                        <Outlet/>
                    </Box>
                    <Box className="ow-sm-sidewindow" sx={{ 
                        position: "relative", 
                        width: '100%', 
                        height: '100%', 
                        overflow: "hidden", 
                        display:sideWindowVisible ? "inherit" : "none"}}>
                        {getMap()}
                    </Box>
        </Split>
    }
    return <React.Fragment>
        <SideWindowContext.Provider value={getSideWindowControl()}>
            <Container 
            maxWidth={false}
            disableGutters={true}
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                background:"white"
            }}
            >
                {getView()}
            </Container>
        </SideWindowContext.Provider>
    </React.Fragment>
}