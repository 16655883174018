import { Box } from "@mui/material";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";
import STitle from "../text/STitle";
import HierarchyHistory from "../../overview/content/HierarchyHistory";

export interface IHierarchyHeaderProps{
    title: string;
    id: number;
    type: SkobaEntityType;
}

export default function HierarchyHeader(props: IHierarchyHeaderProps){
    return <Box>
        <HierarchyHistory item={{id: props.id, type: props.type}}/>
        <STitle text={props.title} variant="h5"/>
    </Box>

}