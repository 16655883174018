import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import HierarchySingle from "../content/HierarchySingle";
import STitle from "../text/STitle";

export interface IHierarchyPageProps{
    title: string;
    tabTitles: string[];
    onMount? : () => void;
    onReceivedData? : (data : any[]) => void;
    getData : () => Promise<any[]>;
    reloadData : () => void;
    refreshItself: boolean;
    onRefresh : () => void;
    refreshProps: any[];
}

export default function HierarchySinglePage(props: PropsWithChildren<IHierarchyPageProps>){    
    return <React.Fragment>        
        <Box sx={{p:2, height:"100%", '& > *': { mb: 2 }}}>
            <STitle text={props.title}/>
            <HierarchySingle
                onRefresh={props.onRefresh}
                refreshProps={props.refreshProps}
                getData={props.getData}
                onMount={props.onMount}
                onReceivedData={props.onReceivedData}
                refreshItself={props.refreshItself}
                reloadData={props.reloadData}
                tabTitles={props.tabTitles}
                children={props.children}
            />                  
        </Box>            
    </React.Fragment>
  }