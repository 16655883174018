import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import React from 'react';

 const ResetPasswordValidationSchema = Yup.object().shape({
    token: Yup.string()        
        .required(),
    email: Yup.string()
        .email()        
        .required(),
    password: Yup.string()        
        .required(),
    passworddConfirm: Yup.string()
        .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match')
});

export interface ISignInProps{
    onSubmit: (values : any) => Promise<void>;
    disabled?: boolean;
    token?: string;
    email?: string;
}

export default function ResetPasswordForm(props : ISignInProps) {
    const formik = useFormik({
        initialValues: {
            token: props.token ? props.token : '',
            email: props.email ? props.email : '',
            password: '',
            passwordConfirm: ''
        },
        validationSchema: ResetPasswordValidationSchema,
        onSubmit: props.onSubmit,
    });    
    const dis = props.disabled ? props.disabled : false;
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            {!props.token && 
                <TextField                
                    fullWidth
                    autoComplete="new-password"
                    id="token"
                    name="token"
                    label="Token z emailu"
                    value={formik.values.token}
                    onChange={formik.handleChange}
                    error={formik.touched.token && Boolean(formik.errors.token)}
                    helperText={formik.touched.token && formik.errors.token}
                    disabled={dis}
                />
            }
            {!props.email && 
                <TextField                
                    fullWidth
                    autoComplete="new-password"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    disabled={dis}
                />
            }            
            <TextField                
                fullWidth
                autoComplete="new-password"
                id="password"
                name="password"
                type="password"
                label="Nové heslo"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                disabled={dis}
            />   
            <TextField                
                fullWidth
                autoComplete="new-password"
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                label="Nové heslo znovu"
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange}
                error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                disabled={dis}
            />             
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={dis}>
                Změnit heslo
            </Button>
    </Box>
    </div>    
}
