import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import { ISubscription } from "../../../types/admin/adminTypes";
import AdminService from "../../../services/admin/AdminService";
import { GError, Loading } from "../../general/layouts/Components";
import { RefreshDataContext } from "../../general/contexts/RefreshDataContext";
import TabLayout from "../../general/layouts/TabLayout";
import PaymentsTable from "../tables/PaymentsTable";
import STitle from "../../general/text/STitle";



export default function PaymentsAdmin() {
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<ISubscription[]>([]);
    // const [openPayments, setOpenPayments] = useState(false);
    
    useEffect(() => getData(), [])
    function getData(){
        AdminService.getSubscriptions()
        .then(
            (result) => {
                setLoaded(true);
                setData(result as ISubscription[]);
            },
            (error) => {
                setLoaded(true);
            setError(error);
            }
        )
    }
    if (error)
        return <GError error={error.message}/>
    else if (!loaded)
        return <Loading/>;
    else {
        return (<React.Fragment>
            <RefreshDataContext.Provider value={getData}>
                <Box sx={{p:2}}>                
                    <STitle text="Platby"/>
                    <TabLayout titles={["Platby"]}>
                        <PaymentsTable data={data}/>          
                    </TabLayout>
                </Box>                
            </RefreshDataContext.Provider>            
        </React.Fragment>
    )}
}