import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import  "./SignIn.css"
import { NavLink, Navigate } from 'react-router-dom';
import { useState } from 'react';
import AuthService from '../../services/general/AuthService'
import SignInForm from '../../components/accountManagement/forms/SignInForm';
import React from 'react';

export default function SignInPage() {
  const [user, setUser] = useState(AuthService.getCurrentUser());    
  const [submit, setSubmit] = useState(false);    
  const [error, setError] = useState(false);    
  const handleSubmit = async (data : any) => {
    setSubmit(true);
    setError(false);
    await AuthService.signin(data.email as string, data.password as string )
      .then((res) =>{
        if(res == null){
          setError(true);
        }
        else{          
          setError(false);
          setUser(res);
        }
        setSubmit(false);
  }).catch(err => {    
    setError(true);
  })
  };
  if(user != null)
    return <Navigate to="/overview" />
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > :not(style)': { mt: 1},
          }}
        >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            Přihlásit se
        </Typography>
        <SignInForm onSubmit={handleSubmit} disabled={submit}/>      
        <NavLink to="/signup">Nemáte účet? Vytvořte si ho</NavLink>  
        <NavLink to="/forgottenPassword">Zapomněli jste heslo? Resetujte si ho</NavLink>  
        <NavLink to="/resetPassword">Přišel vám email o změně hesla? Klikněte zde</NavLink>  
        {error && 
          <Typography component="i" color="red">
            Při komunikaci s přihlašovacím serverem se stala chyba.
            Opakujte akci později.
          </Typography>                  
        }
        </Box>
      </Container>
  );
}