import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { formatFileSize } from '../../Functions';

interface FileUploaderProps {
  onFileUpload: (file: File) => void;
  filename?: string;
  filesize?: number;
}

const FileUpload: React.FC<FileUploaderProps> = ({ onFileUpload, filename, filesize }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            setSelectedFile(selectedFile);
            onFileUpload(selectedFile);
        }
        },
        [onFileUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        {(filename && filesize && !selectedFile) &&(
          <div>
          <p>Jméno: {filename}</p>
          <p>Velikost: {formatFileSize(filesize * 1024)}</p>
        </div>
        )}
        {(!selectedFile && !filename) && (<div>Přetáhněte soubor, nebo kliknetě a soubor vyberte</div>)}
        {selectedFile && (
        <div>
          <p>Jméno: {selectedFile.name}</p>
          <p>Velikost: {formatFileSize(selectedFile.size)}</p>
        </div>
      )}
        </div>
    );
};

const dropzoneStyle: React.CSSProperties = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  textAlign: 'center',
  padding: '30px',
  cursor: 'pointer',
};

export default FileUpload;