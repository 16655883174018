import { IChartComptele } from "../../../types/overview/chartTypes";
import React from "react";
import DonutChart from "./DonutChart";
import { Box } from "@mui/material";

export interface IChartProps{
    data: any;
    dataKey: string;
    title: string
}
export interface IChartManagerMethods {
    changeData: (data : IChartComptele, chartType: number) => void;
}
export interface IChartManagerState{
    chartType: number;
    data: IChartComptele[];
}
export default class ChartManager extends React.Component<object, IChartManagerState>{
    constructor(props : object){
        super(props);
        this.state = ({
            chartType: -1,
            data: []
        })
    }
    getDonuts() : JSX.Element{
        return <Box
        sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <DonutChart data={this.state.data[0].values} dataKey="value" title="Počet pocesy pro tento rok"/>
            <DonutChart data={this.state.data[1].values} dataKey="value" title="Počet pocesy pro přístí rok"/>
            <DonutChart data={this.state.data[2].values} dataKey="value" title="Počet pocesy pro přístích 5 let"/>
    </Box>
    }

    getContent() : JSX.Element{
        switch(this.state.chartType){
            case 1:
                return <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {this.getDonuts()}
                </Box>
            
            default:
                return <div></div>
        }
    }
    changeType(type : number){
        this.setState({
            chartType: type,            
            data: this.state.data
        })
    }
    changeData(data : IChartComptele[], chartType: number){
        this.setState({
            chartType: chartType,
            data: data
        })
    }
    render(): React.ReactNode{
        return this.getContent();
    }
    
}
