import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import { CloseFormContext } from '../../general/contexts/CloseFormContext';

 const ChangePasswordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string()        
        .required(),
    newPassword: Yup.string()        
        .required(),
    newPasswordConfirm: Yup.string()
        .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match')
});

export interface ISignInProps{
    onSubmit: (values : any) => Promise<void>;    
    disabled?: boolean;
}

export default function ChangePasswordForm(props : ISignInProps) {    
    const handleClose = useContext(CloseFormContext);

    // const [error, setError] = React.useState("");
    const [submitting, setSubmit] = React.useState(false);
    // const [success, setSuccess] = React.useState(false);
    
    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: ''
        },
        validationSchema: ChangePasswordValidationSchema,
        onSubmit: props.onSubmit,
    });
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            <TextField                
                fullWidth
                id="oldPassword"
                name="oldPassword"
                type="password"
                label="Staré heslo"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                disabled={submitting}
            />   
            <TextField                
                fullWidth
                id="newPassword"
                autoComplete="new-password"
                name="newPassword"
                type="password"
                label="Nové heslo"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                disabled={submitting}
            />   
            <TextField                
                fullWidth
                id="newPasswordConfirm"
                autoComplete="new-password"
                name="newPasswordConfirm"
                type="password"
                label="Nové heslo znovu"
                value={formik.values.newPasswordConfirm}
                onChange={formik.handleChange}
                error={formik.touched.newPasswordConfirm && Boolean(formik.errors.newPasswordConfirm)}
                helperText={formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm}
                disabled={submitting}
            />             
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
                Změnit heslo
            </Button>
        </Box>
    </div>    
}
