import { useContext, useEffect, useState } from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup';
import { CategoryType, ISelect, ISelectHierarchical } from '../../../../types/overview/skobaTypes';
import { IRoomDetailed } from "../../../../types/overview/estateTypes";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import EstateService from '../../../../services/overview/EstateService';
import { GError, Loading } from '../../../general/layouts/Components';
import { CloseFormContext } from '../../../general/contexts/CloseFormContext';
import { RefreshDataContext } from '../../../general/contexts/RefreshDataContext';
import React from 'react';
import CategoryService from '../../../../services/categories/CategoryService';

 const RoomValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    number: Yup.string()
        .max(50, 'Too Long!')
        .required('Číslo nemůže být prázdný'),
    categoryId: Yup.number()
        .required('Typ prostoru nemůže být prázdná'),
        area: Yup.number()
        .nullable()
 });

 export interface IRoomFormProps{
    model: IRoomDetailed | null;
    buttonText : string;
    selectable: boolean;    
    premiseOption?: ISelect;
    buildingOptions?: ISelect;
    floorOptions?: ISelect; 
    onSubmit: (values : any) => Promise<any>;
}

export default function RoomForm(props : IRoomFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext); 
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState("");
    const [errorFetching, setErrorFetching] = useState<any>(null);
    const [submitting, setSubmit] = useState(false);    
    const [buildingParts, setBuildingParts] = useState<ISelect[]>([]);
    useEffect(() => {
        setLoaded(false);
        const buildingPartsPromise = CategoryService.getCategoriesByType(CategoryType.BuildingPart)
        Promise.all([buildingPartsPromise])
            .then(
                ([data1]) => {
                    setLoaded(true);
                    setBuildingParts(data1);
                },
                (error) => {
                    setLoaded(true);
                    setError(error);
                    setErrorFetching("Při načítání dat došlo k chybě, opakujte akci později");
                }
            )
    }, [])   
    const handleSubmit = async (data : any) => {
        if(props.premiseOption)
            data.premiseId = props.premiseOption.id;
        if(props.buildingOptions)
            data.buildingId = props.buildingOptions.id;
        if(props.floorOptions)
            data.floorId = props.floorOptions.id;
        await props.onSubmit(data)
        .then((res) =>{
            setSubmit(false);
            handleClose();
            handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
      })
    }; 
    const formik = useFormik({
        initialValues: props.model == null ? {
            name: '',
            number: '',
            floorId: props.floorOptions !== undefined ? props.floorOptions.id : null,
            premiseId: props.premiseOption !== undefined ? props.premiseOption.id : null,
            buildingId: props.buildingOptions !== undefined ? props.buildingOptions.id : null,
            categoryId: '',
            area: '',
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            number: props.model.number,
            floorId: props.model.floorId,
            buildingId: props.model.buildingId,
            premiseId: props.model.premiseId,
            categoryId: props.model.categoryId,
            area: props.model.area,
        },
        validationSchema: RoomValidationSchema,
        onSubmit: handleSubmit,
    });

    // function getFloorSelect() : JSX.Element{   
    //     return <FormControl fullWidth>
    //         <InputLabel id="floor-parent-label">Patro</InputLabel>
    //         <Select
    //             fullWidth
    //             labelId="floor-parent-label"
    //             id="floorId"
    //             name="floorId"
    //             label="Patro"
    //             value={formik.values.floorId}
    //             onChange={formik.handleChange}
    //             // disabled={!props.selectable || submitting}
    //             disabled={submitting}
    //             error={formik.touched.floorId && Boolean(formik.errors.floorId)}
    //         >
    //         {
    //             floors.map((v,k) =>{
    //                 return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
    //             })
    //         }
    //         </Select>
    //   </FormControl>
    // }
    // function getBuildingSelect() : JSX.Element{   
    //     return <FormControl fullWidth>
    //         <InputLabel id="building-parent-label">Budova</InputLabel>
    //         <Select
    //             fullWidth
    //             labelId="building-parent-label"
    //             id="buildingId"
    //             name="buildingId"
    //             label="Budova"
    //             value={formik.values.buildingId}
    //             onChange={formik.handleChange}
    //             disabled={submitting}
    //             error={formik.touched.buildingId && Boolean(formik.errors.buildingId)}
    //         >
    //         {props.buildingOptions === undefined ? 
    //         <MenuItem value={props.model?.buildingId?.toFixed()}>{props.model?.buildingName}</MenuItem> 
    //         : 
    //         <MenuItem value={props.buildingOptions.id}>{props.buildingOptions.name}</MenuItem> 
    //         }
    //         </Select>
    //   </FormControl>
    // }
    function getParentSelects() : JSX.Element{
        console.log(props)
        let pozice = ""
        if(props.premiseOption !== undefined)
            pozice = "areál"
        if(props.buildingOptions !== undefined)
            pozice = "budova"
        if(props.floorOptions !== undefined)
            pozice = "patro"
        return <FormControl fullWidth>
            <InputLabel id="parent-label">{"Lokace (" + pozice + ")"}</InputLabel>
            <Select
                fullWidth
                labelId="parent-label"
                id="floorId"
                name="floorId"
                label="parent-label"
                disabled={true}
                value="-1"
            >
                {props.premiseOption !== undefined && <MenuItem value="-1">{props.premiseOption.name}</MenuItem>}
                {props.buildingOptions !== undefined && <MenuItem value="-1">{props.buildingOptions.name}</MenuItem>}
                {props.floorOptions !== undefined && <MenuItem value="-1">{props.floorOptions.name}</MenuItem>}
            </Select>
      </FormControl>
    }
    function getBuildingPartSelect() : JSX.Element{   
        return <FormControl fullWidth>
            <InputLabel id="categoryId-parent-label">Typ prostoru</InputLabel>
            <Select
                fullWidth
                labelId="categoryId-parent-label"
                id="categoryId"
                name="categoryId"
                label="Typ prostoru"
                value={formik.values.categoryId}
                onChange={formik.handleChange}
                disabled={submitting}
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
            >            
            {
                buildingParts.map((v,k) =>{
                    return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                })
            }
            </Select>
      </FormControl>
    }
    if (errorFetching)
        return <GError error={error}/>
    else if (!loaded)
        return <Loading/>;
    else
        return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
        <TextField                
            fullWidth
            id="name"
            name="name"
            label="Jméno"
            value={formik.values.name}
            onChange={formik.handleChange}
            disabled={submitting}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
            fullWidth
            id="number"
            name="number"
            label="Číslo"
            value={formik.values.number}
            onChange={formik.handleChange}
            disabled={submitting}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
        />
        {/* {getBuildingSelect()}
        {getFloorSelect()} */}
        {getParentSelects()}
        {getBuildingPartSelect()}
        
        <TextField
                id="area"
                name="area"
                label="Plocha"
                type="number"
                value={formik.values.area}
                disabled={submitting}
                onChange={formik.handleChange}
                error={formik.touched.area && Boolean(formik.errors.area)}
                helperText={formik.touched.area && formik.errors.area}
                sx={{ marginRight: 1 }}
            />     
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
            {props.buttonText}
        </Button>
    </Box>
    </div>    
}