import { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {  SkobaEntityType, ISelect, ISelectBetter, selectTypesProcess, CategoryType } from '../../../../types/overview/skobaTypes';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import EstateService from '../../../../services/overview/EstateService';
import { CloseFormContext } from '../../../general/contexts/CloseFormContext';
import { RefreshDataContext } from '../../../general/contexts/RefreshDataContext';
import { csCZ, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { Portal } from '@mui/base';
import { IProcessDetailed } from '../../../../types/overview/processTypes';
import dayjs from 'dayjs';
import { IRRuleFormMethods, RRuleForm } from './RRuleForm';
import { appendParentToData } from '../../Functions';
import "dayjs/locale/cs";
import CategoryService from '../../../../services/categories/CategoryService';

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    cost: Yup.number(),
    startDate: Yup.date()
        .required('Datum nemůže být prázdné'),
    repeating: Yup.boolean(),
    categoryId: Yup.number()
        .required('Kategorie nemůže být prázdná'),
    finished: Yup.boolean(),
    repeatRule: Yup.string().nullable(),
    parentId: Yup.number().nullable(),
    locationType: Yup.number().required('Nemůže být prázdné'),
    location: Yup.number().required('Nemůže být prázdné'), 
    // exceptions: Yup.string()
        
 });

export interface IProcessFormProps{
    model: IProcessDetailed | null;
    buttonText : string;
    finishing : boolean;    
    selectOptions?: ISelectBetter;
    onSubmit: (values : any) => Promise<any>;
    onSubmitRepeating: (type: number, values : any) => Promise<any>;
}

export default function ProcessForm(props : IProcessFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext); 
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState("");
    const [submitting, setSubmit] = useState(false);    
    const [selectOptions, setSelectOptions] = useState<ISelectBetter[]>([]);
    const [categories, setCategories] = useState<ISelect[]>([]);
    const [submitType, setSubmitType] = useState(0);

    const rruleForm = React.useRef(null);
    const rruleFormRef = useRef<IRRuleFormMethods | null>(null);

    useEffect(() => {
        setLoaded(false);
        if(props.selectOptions){
            CategoryService.getCategoriesByType(CategoryType.Process)
            .then((data) => {
                    setLoaded(true);
                    setCategories(data);
                    setSelectOptions([props.selectOptions!]);
                },
                (error) => {
                    setLoaded(true);
                    setError(error);
                }
            )
        }
        else{
            const categoriesPromise = CategoryService.getCategoriesByType(CategoryType.Process)
            const selectsPromise = EstateService.getSelectForType(SkobaEntityType.Process)
            Promise.all([categoriesPromise, selectsPromise])
            .then(
                ([data1, data2]) => {
                    setLoaded(true);
                    setCategories(data1);
                    setSelectOptions(data2);
                },
                (error) => {
                    setLoaded(true);
                    setError(error);
                }
            )
        }        
    }, [])    
    const handleSubmit = async (data : any) => {
        data = appendParentToData(data);
        if(data === null) return;
        if(data?.repeating && rruleFormRef.current){
            data['repeatRule'] = rruleFormRef.current.getRRule();
        }
        if(submitType === 0){
            await props.onSubmit!(data)
            .then((res) =>{
                setSubmit(false);
                handleClose();
                handleRefreshData();
            }).catch(err => {
                setError(err.messages.join(",\n"));
            })
        }
        else{
            await props.onSubmitRepeating!(submitType, data)
            .then((res) =>{
                setSubmit(false);
                handleClose();
                handleRefreshData();
            }).catch(err => {
                setError(err.messages.join(",\n"));
            })
        }
    };
    const formik = useFormik({        
        initialValues: props.model === null ? {
            name: '',
            cost: '',
            startDate: new Date(Date.now()).setHours(12,0,0),
            endDate: '',
            categoryId: '',
            repeating: false,
            finished: false,
            repeatRule: '',
            parentId: '',
            locationType: props.selectOptions ? props.selectOptions.type : '',
            location: props.selectOptions ? props.selectOptions.id : '',
            // exceptions: ''
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            cost: props.model.cost,
            startDate: props.model.startDate,
            endDate: props.model.endDate,
            categoryId: props.model.categoryId,
            repeating: props.model.repeating,            
            finished: props.model.finished,
            repeatRule: props.model.repeatRule,
            parentId: props.model.parentId,            
            locationType: props.model.locationType,
            location: props.model.location,
            // exceptions: props.model.exceptions
        },
        validationSchema: ValidationSchema,
        onSubmit: handleSubmit,
    });

    function getTypeSelect() : JSX.Element{   
        return <FormControl fullWidth>
            <InputLabel id="category-label">Typ</InputLabel>
            <Select
                fullWidth
                labelId="category-label"
                id="categoryId"
                name="categoryId"
                label="Typ"
                value={formik.values.categoryId}
                onChange={formik.handleChange}
                disabled={submitting}
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
            >
            {
                categories.map((v,k) =>{
                    return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                })
            }
            </Select>
      </FormControl>
    }
    function getLocationSelects() : JSX.Element{   
        return <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="locationType-label">Typ lokace</InputLabel>
                    <Select                
                    labelId="locationType-label"
                    id="locationType"
                    name="locationType"
                    label="Typ lokace"
                    value={formik.values.locationType}
                    onChange={formik.handleChange}
                    disabled={submitting}
                    error={formik.touched.locationType && Boolean(formik.errors.locationType)}
                    >
                    {
                        props.selectOptions === undefined ?
                        selectTypesProcess.map((v,k) =>{
                            return <MenuItem key={k} value={v.type}>{v.name}</MenuItem>
                        })
                        :
                        selectTypesProcess.filter(x => x.type === props.selectOptions?.type).map((v,k) =>{
                            return <MenuItem key={k} value={v.type}>{v.name}</MenuItem>
                        })
                    }
                    </Select>
                </FormControl>                    
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth> 
                    <InputLabel id="location-label">Lokace</InputLabel>
                    <Select                
                    labelId="location-label"
                    id="location"
                    name="location"
                    label="Lokace"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    disabled={submitting}
                    error={formik.touched.location && Boolean(formik.errors.location)}
                    >
                    {
                        selectOptions.filter(x => x.type === formik.values.locationType).map((v,k) =>{
                            return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                        })
                    }
                    </Select>
                </FormControl>
            </Grid>
        </Grid> 
    }
    function renderRRuleFormPortal() {
        return (
            <Portal container={rruleForm.current}>
                <RRuleForm rrule={props?.model?.repeatRule} ref={rruleFormRef}/>
            </Portal>
        );
    }
    function getButtons() : JSX.Element{
        if(props.model !== null && props.model.repeating && !props.finishing){
            return <React.Fragment>
                <Grid container spacing={1} alignItems="top">
                    <Grid item xs={4}>
                        <Button color="primary" variant="contained" fullWidth 
                            type="submit" sx={{ height: "49px" }} disabled={submitting}
                            onClick={() => setSubmitType(1)}>
                            Pouze tento
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button color="primary" variant="contained" fullWidth 
                            type="submit" sx={{ height: "49px" }} disabled={submitting}
                            onClick={() => setSubmitType(2)}>
                            Tento a následující
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button color="primary" variant="contained" fullWidth 
                            type="submit" sx={{ height: "49px" }} disabled={submitting}
                            onClick={() => setSubmitType(3)}>
                            Všechny
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>            
        }
        else{
            return <Button color="primary" variant="contained" fullWidth type="submit" disabled={submitting}>
                {props.buttonText}
            </Button>
        }
    }
    // if (errorFetching)
    //     return <GError error={error}/>
    // else if (!loaded)
    //     return <Loading/>;
    // else
        return <Box
            component="form"
            sx={{
                '& > :not(style)': { mb: 1.5},
            }}
            onSubmit={formik.handleSubmit}
            >
            {renderRRuleFormPortal()}
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Jméno"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            disabled={submitting}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="cost"
                            name="cost"
                            label="Cena"
                            type="number"
                            value={formik.values.cost}
                            onChange={formik.handleChange}
                            disabled={submitting}
                            error={formik.touched.cost && Boolean(formik.errors.cost)}
                            helperText={formik.touched.cost && formik.errors.cost}
                        />
                    </Grid>
                </Grid>
            {getTypeSelect()}
            {getLocationSelects()}
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}
                adapterLocale='cs'
                localeText={
                    csCZ.components.MuiLocalizationProvider.defaultProps.localeText
                }>
                    <DatePicker 
                        value={dayjs(formik.values.startDate)} 
                        label="Datum" 
                        onChange={(value) => formik.setFieldValue("startDate", value, true)}
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                error: formik.touched.startDate && Boolean(formik.errors.startDate),
                                // helperText: formik.touched.until && formik.errors.until
                            }
                        }}
                        format="DD/MM/YYYY"
                    />
                </LocalizationProvider>            
            </FormControl>
            {!props.finishing &&
                <React.Fragment>
                    <FormControlLabel 
                    control={<Checkbox/>} 
                    label="Opakující" 
                    name="repeating"
                    // value={formik.values.repeating} 
                    checked={formik.values.repeating}
                    onChange={formik.handleChange}/>
                    {formik.values.repeating ? (
                        <div ref={rruleForm} />
                    ) : null} 
                </React.Fragment>   
            }                 
            {getButtons()}   
        </Box> 
}