import { IOrganization } from "../../../types/admin/adminTypes"
import PropertiesInfo from "../../general/tables/PropertiesInfo"

export interface IOrganizationInfoProps{
    data : IOrganization
}

export default function OrganizationInfo(props : IOrganizationInfoProps){    
    return <PropertiesInfo
        data={props.data}
        rowDefinition={[{
            name : "Jméno zákazníka", 
            property : "name",            
        },{
            name : "Adresa", 
            property : "adress",             
        },{
            name : "Město", 
            property : "city",                  
        },{
            name : "PSČ", 
            property : "postalCode",            
        },{
            name : "IČO", 
            property : "cin",            
        },{
            name : "Číslo zákazníka", 
            property : "id",            
        }]}
    />
}