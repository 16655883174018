import { data } from "jquery";
import EstateService from "../../../../services/overview/EstateService";
import PremiseForm from "../../forms/hierarchy/PremiseForm";
import DeleteModal from "../../../general/modals/DeleteModal";
import FormModal from "../../../general/modals/FormModal";
import { useEffect } from "react";
import PropertiesInfo from "../../../general/tables/PropertiesInfo";
import { useNavigate } from "react-router-dom";
import { ISkobaEntityNamed } from "../../../../types/overview/skobaTypes";
import { IPremise } from "../../../../types/overview/estateTypes";

export interface ISpecificItemInfoProps{
    data : ISkobaEntityNamed
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function PremiseInfo(props : ISpecificItemInfoProps){
    const navigate = useNavigate();
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, [props.refreshProps])
    return <PropertiesInfo
        data={props.data}
        rowDefinition={[{
            name : "Jméno", 
            property : "name",            
        },{
            name : "Kód", 
            property : "code",            
        },{
            name : "Adresa", 
            property : "adress",            
        },{
            name : "Plocha [m2]", 
            property : "area",            
        },{
            name : "Xdef [m]", 
            property : "xDef",            
        },{
            name : "Ydef [m]", 
            property : "yDef",            
        },{
            name : "Počet budov", 
            property : "buildingsCount",            
        },{
            name : "Počet prostorů", 
            property : "roomsCount",            
        },{
            name : "Plocha prostorů [m2]", 
            property : "roomsArea",            
        },{
            name : "Počet prvků", 
            property : "itemsCount",            
        }]}
        buttons={(object) => [
            <FormModal                       
            key={1}                      
            title="Upravit areál"    
            buttonElement={<span>Upravit</span>}
            form={<PremiseForm buttonText="Uložit změny" model={props.data as IPremise} onSubmit={EstateService.updatePremise}/>}
        /> ,
        <DeleteModal key={2} buttonText="Smazat" itemName={data.name} 
        onConfirm={() => EstateService.deletePremise(props.data.id).then(() => {navigate("/overview/premises")})}/>
        ]}
    />
}