import { Box, Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { SideWindowType } from '../../overview/content/OverviewSplitManager';

export interface IViewSwitcherProps{
    has3D: boolean;
    onClick: (type: number) => void;
}

export default function ViewSwitcher(props : IViewSwitcherProps){
    const [activeView, setActiveView] = useState(SideWindowType.LocalMap);
    function onClick(view: number){
        if(view !== activeView){
            setActiveView(view);
            props.onClick(view);
        }
    }
    return <Box sx={{
        p: 0,
        position: "absolute",
        top: "0",
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex: 100,
        }}>
            <ButtonGroup size="large" variant="contained">
                <Button 
                    onClick={() => onClick(SideWindowType.LocalMap)} 
                    variant={activeView === SideWindowType.LocalMap ? "contained" : "outlined"}>
                        2D
                    </Button>
                <Button 
                    disabled={!props.has3D} 
                    onClick={() => onClick(SideWindowType.Matterport)} 
                    variant={activeView === SideWindowType.Matterport ? "contained" : "outlined"}>
                        3D
                    </Button>
            </ButtonGroup>
    </Box>
    
}
