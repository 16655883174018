import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from "@mui/material";
import SHeader from "../text/SHeader";

export interface IItemInfoNewProps{
    data : any;
    rowDefinition: IItemInfoRowDefinition[];
    buttons? : (object : any) => JSX.Element[];
}

export interface IItemInfoRowDefinition{
    property : any;
    name: string;
    getValue? : (value : any) => any;
    onClick? : (object : any) => void;  
}
export default function PropertiesInfo(props : IItemInfoNewProps){
    const theme = useTheme();
    return <Box>
        <TableContainer sx={{borderRadius: "0", overflow: "auto", background:"#e6e6e6"}}>
            <Table sx={{ width:"auto"}} size="small">                
                <TableBody>                    
                    {props.rowDefinition.map((row, ix) => {
                        const value = row.getValue ? row.getValue(props.data) : props.data[row.property];
                        return <TableRow key={ix} sx={{'& td': {border: "1px solid #aeaeae; "}}}>
                            <TableCell sx={{ 
                                background:"#dcdcdc!important", 
                                color: 'black'}} 
                                align="left"><SHeader text={row.name}/></TableCell>
                            <TableCell align="center" sx={{p:"2px"}}></TableCell>
                            {(value === undefined || value === "" || value === null || Number.isNaN(value)) ? <TableCell align="center">-</TableCell>
                            : <TableCell align="center" onClick={() => row.onClick?.(props.data)} sx={row.onClick !== undefined ? {
                                cursor: "pointer",
                                color: theme.palette.primary.main + " !important"
                            } : {}}>
                                {value}
                            </TableCell>
                            }

                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        {props.buttons !== undefined && 
            <Stack direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{pt:3}}>
                {props.buttons(props.data).map((button, ix) => {
                    return <Box key={ix}>{button}</Box>;
                })}
            </Stack>
        }
    </Box>
}