import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import HierarchySinglePageNew from "../../../components/general/content/HierarchySinglePageNew";
import LoadingWrapper from "../../../components/general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../components/general/contexts/RefreshDataContext";
import ItemsServiceNew from "../../../services/items/ItemsService";
import { IItem, IItemDetailed, IItemProperty, IPropertyValue } from "../../../types/overview/itemTypes";
import ItemInfo from "../../../components/overview/itemInfo/other/ItemInfo";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";


export default function ItemPage(){    
    const { closeSideWindow, openHierarchy } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IItemDetailed>();
    const [properties, setProperties] = useState<IItemProperty[]>();
    const [values, setValues] = useState<IPropertyValue[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        closeSideWindow();
    }, [])
    function loadData(){
        const propertiesPromise = ItemsServiceNew.getItemProperties(id);
        const valuesPromise = ItemsServiceNew.getItemValues(id);
        const dataPromise = ItemsServiceNew.getItem(id);
        Promise.all([propertiesPromise, dataPromise, valuesPromise])
        .then(
            ([data1, data2, data3]) => {
                setLoading(false);
                setProperties(data1);
                setData(data2);
                setValues(data3);
            },
            (error) => {
                setLoading(false);
                setError(error);
                setError(error);
            }
        )
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Item} 
                tabTitles={["Informace"]} >
                    <ItemInfo data={data} properties={properties!} values={values!}
                    />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }