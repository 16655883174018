import { RStyle, RCircle, RFill, RStroke, RText } from "rlayers/style";
import { SkobaColors } from "../../../types/colors/SkobaTheme";
import React from "react";


const WALL_COLOR = "black";
const WALL_COLOR_ACTIVE = SkobaColors.Primary;

const ROOM_OUTLINE_COLOR = SkobaColors.Gray1;
const ROOM_FILL_COLOR = "transparent";

const ROOM_OUTLINE_COLOR_ACTIVE = SkobaColors.Gray1;
const ROOM_FILL_COLOR_ACTIVE = "rgb(59 133 184 / 40%)";

const ROOM_TEXT_OUTLINE_COLOR = SkobaColors.Primary;
const ROOM_TEXT_FILL_COLOR = SkobaColors.Primary;

const ROOM_TEXT_OUTLINE_COLOR_ACTIVE = "#CC2626";
const ROOM_TEXT_FILL_COLOR_ACTIVE = "#CC2626";

const POINT_OUTLINE_COLOR = "";
const POINT_FILL_COLOR = "";

export function getPolygonStyle(active: boolean = false) : JSX.Element{
    if(active)
        return (<React.Fragment>
            <RStroke color={ROOM_OUTLINE_COLOR_ACTIVE} width={3} />
            <RFill color={ROOM_FILL_COLOR_ACTIVE}/>
        </React.Fragment>)
    return (<React.Fragment>
            <RStroke color={ROOM_OUTLINE_COLOR} width={3} />
            <RFill color={ROOM_FILL_COLOR}/>
    </React.Fragment>)
}
export function getLineStyle(active: boolean = false) : JSX.Element{
    if(active)
        return (<React.Fragment>
                <RStroke color={WALL_COLOR_ACTIVE} width={1.5} />
        </React.Fragment>)
        
    return (<React.Fragment>
            <RStroke color={WALL_COLOR} width={1} />
    </React.Fragment>)
}
export function getPointStyle(active: boolean = false) : JSX.Element{
    if(active)
        return (
            <RCircle radius={5}>
                <RFill color="red" />
                <RStroke width={1} color="black"/>
            </RCircle>
        )
    return (
        <RCircle radius={5}>
            <RFill color="red"/>
            <RStroke width={1} color="black"/>
        </RCircle>
    )
}
export function getTextPointStyle(active: boolean = false, text?: string) : JSX.Element{
    if(text){
        if(active)
            return (<RText text={text} scale={2} offsetX={10}>
                        <RFill color={ROOM_TEXT_FILL_COLOR_ACTIVE}/>
                        <RStroke width={0.5} color={ROOM_TEXT_OUTLINE_COLOR_ACTIVE}/>
                    </RText>
            )
        return (<RText text={text} scale={1.5} offsetX={10}>
                    <RFill color={ROOM_TEXT_FILL_COLOR}/>
                    <RStroke width={0.5} color={ROOM_TEXT_OUTLINE_COLOR}/>
                </RText>
        )
    }
    return getPointStyle(active)
    
}
export function getGeometrytyle(active: boolean = false) : JSX.Element{
    return (<React.Fragment>
        <RStroke color="rgb(82, 82, 82)" width={2} />      
        <RFill color="rgba(0, 82, 102, 0.75)"  />
            <RCircle radius={5}>
                        <RFill color="red"/>
                    <RStroke width={1} color="black"/>
            </RCircle>
    </React.Fragment>)
}

export function getSensorStyle() : JSX.Element{
    return (<RStyle>
            <RCircle radius={7}>
                        <RFill color="yellow"/>
                    <RStroke width={1} color="black"/>
            </RCircle>
    </RStyle>)
}