import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import FilesHierarchy from "../../../components/overview/content/multiple/FilesHierarchy";
import EstateService from "../../../services/overview/EstateService";
import HierarchySinglePageNew from "../../../components/general/content/HierarchySinglePageNew";
import LoadingWrapper from "../../../components/general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../components/general/contexts/RefreshDataContext";
import { IProcessDetailed } from "../../../types/overview/processTypes";
import ProcessInfo from "../../../components/overview/itemInfo/other/ProcessInfo";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";

export default function ProcessPage(){
    const { closeSideWindow, openHierarchy, chartObj } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IProcessDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        closeSideWindow();
    }, [])
    function loadData(){
        EstateService.getHierarchyData("Processes/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew 
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Process} 
                tabTitles={["Informace", "Soubory"]} >   
                    <ProcessInfo data={data}/>                         
                    <FilesHierarchy getData={() => EstateService.getHierarchyData("File/allOfParent?type=3&id=" + data.id)}
                        parent={{name: data.name, id: data.id, type: 3}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }