import * as React from "react";
import type { SVGProps } from "react";
const SvgMistnost = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M260.346 43.166H59.654v233.668h159.673l-.637-101.882 41.656.263z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.98px",
      }}
    />
    <path
      d="M101.987 276.834v-51.223l51.473 51.474M122.705 229.838v68.256"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
  </svg>
);
export default SvgMistnost;
