import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import { ISensorValue } from "../../../types/overview/estateTypes";

export interface ISensorValuesChartProps{
    data : ISensorValue[];
}
function getSensorChartData(sensorValues: ISensorValue[]) : any[]{
    return sensorValues.map(x => {
        return {name : x.time, teplota: x.value}
    })
}
export default function SensorValuesChart(props: ISensorValuesChartProps){
    return <ResponsiveContainer width="100%" height={400}>
        <LineChart
            data={getSensorChartData(props.data)}
            margin={{
                top: 5,
                right: 10,
                left: 0,
                bottom: 5,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[16, 26]}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="teplota" stroke="#8884d8"  />
            </LineChart>
    </ResponsiveContainer>   
}