import { ICategory, ICreateCategory } from "../../types/categories/categoryTypes";
import { SkobaEntityType, ISelectBetter, CategoryType } from "../../types/overview/skobaTypes";
import { IBuilding, IBuildingCreate, IBuildingDetailed, IBuildingPart, IBuildingPartCreate, IFloor, IFloorCreate, IFloorDetailed, IHistory, IPremiseCreate, IRoom, IRoomCreate, IRoomDetailed, IPremise } from "../../types/overview/estateTypes";
import ApiService from "../general/ApiService";

const getHierarchyDataChildren = (type: string, id : number) => {
  return ApiService.apiGet("/" + type + "/" + id );
};
const getHierarchyData = (type: string) => {
  return ApiService.apiGet("/" + type);
};
const getPremises = () : Promise<IPremise[]>=> {
  return ApiService.apiGet("/Premises");
};
const getBuildings = () : Promise<IBuildingDetailed[]>=> {
  return ApiService.apiGet("/Buildings");
};
const getFloors = () : Promise<IFloorDetailed[]>=> {
  return ApiService.apiGet("/Floors");
};
const getRooms = () : Promise<IRoomDetailed[]>=> {
  return ApiService.apiGet("/Rooms");
};
const getHistory = (item: IHistory) => {
  return ApiService.apiPost("/History", item);
};
const createPremise = (item: IPremiseCreate) : Promise<IPremise> => {
  return ApiService.apiPost("/Premises", item);
};
const updatePremise = (item: IPremise) : Promise<void> => {
  return ApiService.apiPut("/Premises/" + item.id, item);
};
const deletePremise = (item: IPremise) : Promise<void> => {
  return ApiService.apiDelete("/Premises/" + item.id);
};
const createBuilding = (item: IBuildingCreate) : Promise<IBuilding> => {
  return ApiService.apiPost("/Buildings", item);
};
const updateBuilding = (item: IBuilding) : Promise<void> => {
  return ApiService.apiPut("/Buildings/" + item.id, item);
};
const deleteBuilding = (item: IBuilding) : Promise<void> => {
  return ApiService.apiDelete("/Buildings/" + item.id);
};
const createFloor = (item: IFloorCreate) : Promise<IFloor> => {
  return ApiService.apiPost("/Floors", item);
};
const updateFloor = (item: IFloor) : Promise<void> => {
  return ApiService.apiPut("/Floors/" + item.id, item);
};
const deleteFloor = (item: IFloor) : Promise<void> => {
  return ApiService.apiDelete("/Floors/" + item.id);
};

const createRoom = (item: IRoomCreate) : Promise<IRoom> => {
  return ApiService.apiPost("/Rooms", item);
};
const updateRoom = (item: IRoom) : Promise<void> => {
  return ApiService.apiPut("/Rooms/" + item.id, item);
};
const deleteRoom = (item: IRoom) : Promise<void> => {
  return ApiService.apiDelete("/Rooms/" + item.id);
};
const getSelect = (type: string) : Promise<ISelectBetter[]>=> {
  return ApiService.apiGet("/Select/" + type );
};
const getSelectForType = (type: SkobaEntityType) : Promise<ISelectBetter[]>=> {
  return ApiService.apiGet("/Select/forType/" + type );
};

const EstateService = {
    getHierarchyDataChildren,
    getHierarchyData,
    getHistory,
    createPremise,
    updatePremise,
    deletePremise,
    createBuilding,
    updateBuilding,
    deleteBuilding,
    createFloor,
    updateFloor,
    deleteFloor,
    createRoom,
    updateRoom,
    deleteRoom,
    getPremises,
    getBuildings,
    getFloors,
    getRooms,
    getSelect,
    getSelectForType,
};

export default EstateService;