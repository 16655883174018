import { useNavigate } from "react-router-dom";
import SkobaTable from "../../../general/tables/SkobaTable";

export interface ITableProps{
    data: any[];
}

const SensortHeader = ["Název čidla", "Výrobce", "Jednotky", "Prostor"];

export default function SensorsTable(props : ITableProps){
    const navigate = useNavigate();

    return <SkobaTable
        data={props.data}
        header={SensortHeader}
        rowDefinition={{
            columnDefinitions: [{
                property: "name",
                onClick: (object) => navigate("/overview/sensors/" + object.id)
            },
                {property: "manufactuer"},
                {property: "unit"},
                {property: "roomName",
                onClick: (object) => navigate("/overview/rooms/" + object.roomId)}                
            ],
            // buttons : (object) => [
            // <Button variant="text" size="small" sx={{py:"1px"}} startIcon={<DeleteForeverIcon  />}>
            //     Delete
            // </Button>,
            // <Button variant="text" size="small" sx={{py:"1px"}} startIcon={<DeleteForeverIcon  />}>
            //     Delete
            // </Button>
            // ]
        }}
    />    
}