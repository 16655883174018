import { Outlet } from 'react-router-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import { PropsWithChildren } from 'react';
import SidebarLayout from './SidebarLayout';

export interface ISidebarLayoutProps{
  sideBarContent : React.ReactElement;
  contentBackground? : string;
  level: number;

}

export default function PaddedContentSidebarLayout(props : PropsWithChildren<ISidebarLayoutProps>) : React.ReactElement {
  return <SidebarLayout    
            level={1}
            sideBarContent={props.sideBarContent}
            contentBackground={props.contentBackground ? props.contentBackground : "inherit"}
        >
          <Box sx={{
            height:"100%", 
            width:"100%",
            background: "white",
            }}>
              <Box sx={{
                mr:"30px", 
                background: props.contentBackground ? props.contentBackground : "inherit",
                boxShadow: "5px 5px 10px -5px rgba(0,0,0,0.7);",
                height:"100%",
              }}>
                <Outlet/>
              </Box>
          </Box>
    </SidebarLayout>
}