import { useContext, useEffect, useState } from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup';
import {  SkobaEntityType, ISelectBetter, selectTypesFile, CategoryType } from '../../../../types/overview/skobaTypes';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import EstateService from '../../../../services/overview/EstateService';
import { CloseFormContext } from '../../../general/contexts/CloseFormContext';
import { RefreshDataContext } from '../../../general/contexts/RefreshDataContext';
import { IFile } from '../../../../types/overview/fileTypes';
import { GError, Loading } from '../../../general/layouts/Components';
import FileUpload from './FileUpload';
import { getAppendedParentFormData } from '../../Functions';
import React from 'react';
import { ICategory } from '../../../../types/categories/categoryTypes';
import CategoryService from '../../../../services/categories/CategoryService';

 const ValidationSchema = Yup.object().shape({
    name: Yup.string()
    .max(50, 'Too Long!')
    .required('Jméno nemůže být prázdné'),
    categoryId: Yup.number()
    .required('Kategorie nemůže být prázdná'),
    description: Yup.string().nullable(),  
    locationType: Yup.number().required('Nemůže být prázdné'),
    location: Yup.number().required('Nemůže být prázdné'),
        
 });

export interface IFileFormProps{
    model: IFile | null;
    buttonText : string; 
    selectOptions?: ISelectBetter;
    onSubmit: (values : any) => Promise<any>;
}

export default function FileForm(props : IFileFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext); 
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState("");
    const [errorFetching, setErrorFetching] = useState<any>(null);
    const [submitting, setSubmit] = useState(false);    
    const [selectOptions, setSelectOptions] = useState<ISelectBetter[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);  

    useEffect(() => {
        setLoaded(false);
        if(props.selectOptions){
            CategoryService.getCategoriesByType(CategoryType.File)
            .then((data) => {
                    setLoaded(true);
                    setCategories(data);
                    setSelectOptions([props.selectOptions!]);
                },
                (error) => {
                    setLoaded(true);
                    setError(error);
                }
            )
        }
        else{
            const categoriesPromise = CategoryService.getCategoriesByType(CategoryType.File)
            const selectsPromise = EstateService.getSelectForType(SkobaEntityType.File)
            Promise.all([categoriesPromise, selectsPromise])
            .then(
                ([data1, data2]) => {
                    setLoaded(true);
                    setCategories(data1);
                    setSelectOptions(data2);
                },
                (error) => {
                    setLoaded(true);
                    setError(error);
                    setErrorFetching(error);
                }
            )
        }        
    }, [])

    const handleFileUpload = (file: File) => {
        // Handle the uploaded file here, e.g., send it to the server, update state, etc.
        setSelectedFile(file);
    };
    const handleSubmit = async (data : any) => {
        if(selectedFile === null && !props.model?.id) return;
        const formData = getAppendedParentFormData(data);
        if(formData === null) return;
        const file = selectedFile as File;

        if(props.model?.id)
            formData.append('id', props.model?.id);
        formData.append('data', file);
        formData.append('categoryId', data.categoryId);
        formData.append('name', data.name);
        formData.append('description', data.description);
        data = formData;   
        await props.onSubmit(data)
            .then((res) =>{
                setSubmit(false);
                handleClose();
                handleRefreshData();
            }).catch(err => {
                setError(err.messages.join(",\n"));
            })
    };
    const formik = useFormik({        
        initialValues: props.model === null ? {
            name: '',
            description: '',
            categoryId: 1,
            locationType: props.selectOptions ? props.selectOptions.type : '',
            location: props.selectOptions ? props.selectOptions.id : '',
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            description: props.model.description ? props.model.description : '',              
            categoryId: props.model.categoryId,
            locationType: props.model.locationType,
            location: props.model.location,
        },
        validationSchema: ValidationSchema,
        onSubmit: handleSubmit,
    });
    function getFileUpload(){
        return <FileUpload onFileUpload={handleFileUpload} filename={props?.model?.filename} filesize={props?.model?.size}/>
    }
    function getTypeSelect() : JSX.Element{   
        return <FormControl fullWidth>
        <InputLabel id="category-label">Typ</InputLabel>
            <Select
                fullWidth
                labelId="category-label"
                id="categoryId"
                name="categoryId"
                label="Typ"
                value={formik.values.categoryId}
                onChange={formik.handleChange}
                disabled={submitting}
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
            >
            {
                categories.map((v,k) =>{
                    return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                })
            }
            </Select>
    </FormControl>
    }
    function getLocationSelects() : JSX.Element{   
        return <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="locationType-label">Typ lokace</InputLabel>
                    <Select                
                    labelId="locationType-label"
                    id="locationType"
                    name="locationType"
                    label="Typ lokace"
                    value={formik.values.locationType}
                    onChange={formik.handleChange}
                    disabled={submitting}
                    error={formik.touched.locationType && Boolean(formik.errors.locationType)}
                    >
                    {
                        props.selectOptions === undefined ?
                        selectTypesFile.map((v,k) =>{
                            return <MenuItem key={k} value={v.type}>{v.name}</MenuItem>
                        })
                        :
                        selectTypesFile.filter(x => x.type === props.selectOptions?.type).map((v,k) =>{
                            return <MenuItem key={k} value={v.type}>{v.name}</MenuItem>
                        })
                    }
                    </Select>
                </FormControl>                    
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth> 
                    <InputLabel id="location-label">Lokace</InputLabel>
                    <Select                
                    labelId="location-label"
                    id="location"
                    name="location"
                    label="Lokace"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    disabled={submitting}
                    error={formik.touched.location && Boolean(formik.errors.location)}
                    >
                    {
                        selectOptions.filter(x => x.type === formik.values.locationType).map((v,k) =>{
                            return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                        })
                    }
                    </Select>
                </FormControl>
            </Grid>
        </Grid> 
    }
    function getButtons() : JSX.Element{
        return <Button color="primary" variant="contained" fullWidth type="submit" disabled={submitting}>
                {props.buttonText}
        </Button>
    }
    function getNameCategoryDescriptionInputs() : JSX.Element{
        return <Grid container spacing={1}>
        <Grid item xs={6}>
            <TextField
                fullWidth
                id="name"
                name="name"
                label="Jméno"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={submitting}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
        </Grid>
        <Grid item xs={6}>
            {getTypeSelect()}
        </Grid>                    
        <Grid item xs={12}>
            <TextField
                fullWidth
                id="description"
                name="description"
                label="Popis"
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled={submitting}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                multiline
                rows={4}
            />
        </Grid>
    </Grid>      
    }
    if (errorFetching)
        return <GError error={error}/>
    else if (!loaded)
        return <Loading/>;
    else
        return <Box
            component="form"
            sx={{
                '& > :not(style)': { mb: 1.5},
            }}
            onSubmit={formik.handleSubmit}
            >
            {getNameCategoryDescriptionInputs()}
            {getLocationSelects()}       
            {getFileUpload()}
            {getButtons()}   
        </Box> 
}