import React, { ReactElement, useEffect, useState } from "react";
import EstateService from "../../../services/overview/EstateService"
import { GError, Loading } from "../../general/layouts/Components";
import FormModal from "../../general/modals/FormModal";
import { RefreshDataContext } from "../../general/contexts/RefreshDataContext";
import {  Box, Stack } from "@mui/material";
import SearchTextFilter from "../../overview/filters/SearchText";
import { ICategory } from "../../../types/categories/categoryTypes";
import CategoryForm from "../forms/CategoryForm";
import { CategoryType, SkobaEntityType } from "../../../types/overview/skobaTypes";
import GenericCategoryTable from "../tables/GenericCategoryTable";
import STitle from "../../general/text/STitle";
import CategoryService from "../../../services/categories/CategoryService";


export interface IGenericCategoryText{
    title: string;
    createTitle: string;
    createText: string;
    updateText: string;

}

function getCategoryInfo(type: CategoryType) : IGenericCategoryText{
    switch(type){
        case CategoryType.BuildingPart:
            return {
                title: "Části budov",
                createTitle: "Vytvořit novou část budovy",
                createText: "Vytvořit část budovy",
                updateText: "Upravit část budovy",
            }
        case CategoryType.File:
            return {
                title: "Typy Souborů",
                createTitle: "Vytvořit nový typ souborů",
                createText: "Vytvořit typ souborů",
                updateText: "Upravit typ souborů",
            }
        case CategoryType.Process:
            return {
                title: "Typy Činností",
                createTitle: "Vytvořit nový typ činností",
                createText: "Vytvořit typ činností",
                updateText: "Upravit typ činností",
            }
        default:
            return {
                title: "Kategorie",
                createTitle: "Vytvořit novou kategorii",
                createText: "Vytvořit kategorii",
                updateText: "Upravit kategorii",
            }
    }
}


export interface IGenericCategoryProps{    
    type: CategoryType;
}

export default function GenericCategory(props: IGenericCategoryProps){
    const [loaded, setLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<ICategory[]>([]);
    const [texts, setTexts] = useState<IGenericCategoryText>(getCategoryInfo(props.type));
    const [searchName, setSearchName] = useState("");
    
    useEffect(() => {
        setTexts(getCategoryInfo(props.type));
        getData();
    }, [props])
    useEffect(() => getData(), [props])
    function getData(){
        CategoryService.getCategoriesByType(props.type)
        .then(
            (result) => {
                setLoaded(true);
                setData(result as ICategory[]);                
            },
            (error) => {       
                setLoaded(true);
                    setError(error);
            }
        )
    } 
    function getFilteredData() : ICategory[]{
        return data.filter((item) => item.name.toLocaleLowerCase().includes(searchName.toLocaleLowerCase()))
    }
    function getModifyButtons() : ReactElement{
    return <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{pt:1}}
        >
            <FormModal 
                buttonText={texts.createText}
                title={texts.createTitle}  
                form={<CategoryForm model={null} type={props.type} buttonText="Vytvořit" onSubmit={CategoryService.createCategory}/>}
            />      
    </Stack>
    }
    if (error)
    return <GError error={error.message}/>
    else if (!loaded)
        return <Loading/>;
    else {
        return (<React.Fragment>
             <Box sx={{p:2, '& > *': { mb: 2 }}}>
                <STitle text={texts.title}/>
                <RefreshDataContext.Provider value={getData}>
                    <Box sx={{p:0, '& > *': { mb: 2 }}}>
                        <SearchTextFilter
                            onChange={setSearchName}/>
                        <GenericCategoryTable data={data} type={props.type} updateText={texts.updateText}/>   
                        {getModifyButtons()}    
                    </Box>
                </RefreshDataContext.Provider>       
            </Box>                
        </React.Fragment>
    )}
  }