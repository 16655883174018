import { useContext, useEffect, useState } from "react";
import { IDefinition, IDefinitionDetailed, IPropertyValueCreate, IItemType, IItemDetailed, IPropertyValue } from "../../../../types/overview/itemTypes";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import ItemsServiceNew from "../../../../services/items/ItemsService";
import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ItemPropertyForm from "./ItemPropertyForm";
import { CloseFormContext } from "../../../general/contexts/CloseFormContext";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import { ISelectBetter, SkobaEntityType } from "../../../../types/overview/skobaTypes";


const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    typeId: Yup.number()
        .required('Typ nemůže být prázdný'),
    parentTypeId: Yup.number()
        .required('Podtyp nemůže být prázdný'),
    definitionId: Yup.number()
        .required('Druh nemůže být prázdný')
        
});
export interface IItemFormProps{
    buttonText : string;
    model?: IItemDetailed;
    location?: ISelectBetter;
    onSubmit: (values : any) => Promise<any>;
}
type PropertiesValues = {
    [key: string]: string;
  };

export default function ItemForm(props: IItemFormProps){
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext); 
    const [types, setTypes] = useState<IItemType[]>([]);
    const [definitions, setDefinitions] = useState<IDefinitionDetailed[]>([]);
    const [submitting, setSubmit] = React.useState(false);
    const [propertiesValues, setPropertiesValues] = useState<PropertiesValues>({})
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState("");
    
    useEffect(() => {
        setLoaded(false);
        const typesPromise = ItemsServiceNew.getTypes();
        const definitionsPromise = ItemsServiceNew.getDefinitions();
        if(props.model){
            let properties = {}
            props.model.propertyValues.forEach(x => {
                properties = {...properties, [x.propertyId] : x.value}
            })
            setPropertiesValues(properties)
        }
        Promise.all([typesPromise, definitionsPromise])
        .then(
            ([data1, data2]) => {
                setLoaded(true);
                setTypes(data1);
                data2.forEach(x => x.properties.sort((a,b) => a.dataType >= b.dataType ? -1 : 1))
                setDefinitions(data2);
            },
            (error) => {
                setLoaded(true);
            }
        )
    }, [])   

    const handleSubmit = async (data : any) => {
        if(props.model !== undefined){
            const propertyValues = [] as IPropertyValue[]
            const definition = definitions.find(x => x.id?.toString() == formik.values.definitionId)
            definition?.properties.forEach(x => {
                propertyValues.push({
                    propertyId : x.id,
                    itemId : props.model!.id, 
                    value : propertiesValues[x.id] ? propertiesValues[x.id] : ""
                })
            })
            data.propertyValues = propertyValues;

        }
        else{
            const propertyValues = [] as IPropertyValueCreate[]
            const definition = definitions.find(x => x.id?.toString() == formik.values.definitionId)
            definition?.properties.forEach(x => {
                propertyValues.push({propertyId : x.id, value : propertiesValues[x.id] ? propertiesValues[x.id] : ""})
            })
            data.properties = propertyValues;
            data.locationId = props.location?.id;
            data.locationType = props.location?.type;
        }
        data.locationId = props.location?.id;
        data.locationType = props.location?.type;
        await props.onSubmit(data)
            .then((res) =>{
                setSubmit(false);
                handleClose();
                handleRefreshData();
        }).catch(err => {
            setError(err.messages.join(",\n"));
        })
    };
    const formik = useFormik({        
        initialValues: props.model === undefined ? {
            name: '',
            typeId: '',
            parentTypeId: '',
            definitionId: '',
            propertyValues: [],
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            typeId: 0,
            parentTypeId: 0,
            definitionId: props.model.definitionId,
            propertyValues: props.model.propertyValues,
        },
        validationSchema: ValidationSchema,
        onSubmit: handleSubmit,
    });
 

    function onPropertyChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        setPropertiesValues({
            ...propertiesValues,
            [e.target.id] : e.target.value
        })
    }
    function getTypeTypesSection(){
        return <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="parent-type">Kategorie</InputLabel>
                    <Select                     
                        variant="filled"   
                        labelId="parent-type"
                        label="Kategorie" 
                        id="parentTypeId"
                        name="parentTypeId"
                        fullWidth
                        value={formik.values.parentTypeId}
                        disabled={submitting}
                        onChange={(event) => {
                            formik.values.typeId = ''
                            formik.values.definitionId = ''
                            formik.handleChange(event)
                            console.log(formik.values)
                        }}
                        error={formik.touched.parentTypeId && Boolean(formik.errors.parentTypeId)}
                    >
                        {types.filter(x => x.parentTypeId === null).map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                    </Select>
                </FormControl> 
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="type">Typ</InputLabel>
                    <Select                     
                        variant="filled"   
                        labelId="type"
                        label="Typ"
                        id="typeId"
                        name="typeId"
                        fullWidth
                        value={formik.values.typeId}
                        onChange={(event) => {
                            formik.values.definitionId = ''
                            formik.handleChange(event)
                        }}
                        disabled={submitting || formik.values.parentTypeId === ''}
                        error={formik.touched.typeId && Boolean(formik.errors.typeId)}
                    >
                        {types.sort((a,b) => {return a.name <= b.name ? -1 : 1})
                            .filter(x => x.parentTypeId?.toString() === formik.values.parentTypeId)
                            .map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                    </Select>
                </FormControl> 
            </Grid>
        </Grid>
    }
    function getDefinitions(){
        return <Grid container spacing={1}>
             <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="definition">Kategorie</InputLabel>
                    <Select                     
                        variant="filled"   
                        labelId="definition"
                        label="Druh prvku" 
                        id="definitionId"
                        name="definitionId"
                        fullWidth
                        value={formik.values.definitionId}
                        onChange={(event) => {
                            formik.handleChange(event)
                            setPropertiesValues({})
                        }}
                        disabled={submitting || formik.values.typeId === ''}
                        error={formik.touched.definitionId && Boolean(formik.errors.definitionId)}
                    >
                        {definitions.filter(x => x.itemTypeId?.toString() === formik.values.typeId).map((x, index) => <MenuItem key={index} value={x.id.toString()}>{x.name}</MenuItem>)}
                    </Select>
                </FormControl> 
            </Grid>
        </Grid>
    }
    function getPropertiesSection(){
        const definition = definitions.find(x => x.id?.toString() == formik.values.definitionId)
        if(definition){
            return <Grid container spacing={1}>
                {definition.properties
                    .filter(x => x.show !== 0)                    
                    .map((x, k) => <ItemPropertyForm key={k} property={x} value={propertiesValues[x.id]} onChange={onPropertyChange}/>)}
            </Grid>
        }
        else
            return <React.Fragment/>

    }
    function getSelects(){
        if(props.model !== undefined)
            return <React.Fragment/>
        return <React.Fragment>
            {getTypeTypesSection()}
            {getDefinitions()}
        </React.Fragment>
    }
    return <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            <TextField                
                fullWidth
                id="name"
                name="name"
                label="Jméno"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                disabled={submitting}
            />
            {getSelects()}
            {getPropertiesSection()}
            <Button 
                color="primary" 
                variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
                {props.buttonText}
            </Button>
            {error !== "" && 
                <Typography component="i" color="red">
                Při zpracovávání vašeho požadavku došlo k chybě: {error}
                </Typography>                  
            }
    </Box>
}