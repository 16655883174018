import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowType } from "../../../components/overview/content/OverviewSplitManager";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import BuildingsHierarchy from "../../../components/overview/content/multiple/BuildingsHierarchy";
import EstateService from "../../../services/overview/EstateService";
import { IVectorData, IVectorLayer } from "../../../components/general/maps/ControlMap";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { getPremiseStyle } from "../../../components/general/maps/ControlMapStyles";
import PremiseInfo from "../../../components/overview/itemInfo/hierarchy/PremiseInfo";
import ProcessesHierarchy from "../../../components/overview/content/multiple/ProcessesHierarchy";
import FilesHierarchy from "../../../components/overview/content/multiple/FilesHierarchy";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";
import { IPremiseDetailed } from "../../../types/overview/estateTypes";
import HierarchySinglePageNew from "../../../components/general/content/HierarchySinglePageNew";
import LoadingWrapper from "../../../components/general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../components/general/contexts/RefreshDataContext";
import RoomsHierarchy from "../../../components/overview/content/multiple/RoomsHierarchy";
import ItemsHierarchy from "../../../components/overview/content/multiple/ItemsHierarchy";
import ItemsServiceNew from "../../../services/items/ItemsService";


export default function PremisePage(){    
    const { changeSideWindow, openSideWindow, openHierarchy, controlMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IPremiseDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
        openSideWindow();
        changeSideWindow(SideWindowType.ControlMap)
    }, [])

    useEffect(() => {
        zoomAndShow()
    }, [data])
    function loadData(){
        EstateService.getHierarchyData("Premises/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function zoomAndShow() : void{        
        if(data && data.xDef && data.yDef){
            const vectorLayers = [] as IVectorLayer[];
            const features = [] as IVectorData[];
            features.push({
                feature: new Feature({
                    geometry: new Point([data.xDef as number,data.yDef as number]),
                    type: "Premise",
                    name: data.name,
                    id: data.id

                }),
                onClick: () => navigate("/overview/premises/" + data.id),
                onLeave: () => {console.log("leave")},
                onEnter: () => {console.log("enter")},
            } as IVectorData )      
            vectorLayers.push({layers: features, style: getPremiseStyle()});
            controlMap?.setVectorData(vectorLayers);
            controlMap?.zoomOnPoint({center: [data.xDef, data.yDef], zoom: 14})
        }
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Premise} 
                tabTitles={["Informace", "Budovy", "Prostory", "Prvky", "Činnosti", "Soubory"]} >
                    <PremiseInfo data={data!} onMount={() => {
                            changeSideWindow(SideWindowType.ControlMap);
                            openSideWindow();
                            zoomAndShow();
                        }}
                        onRefresh={() => {
                            changeSideWindow(SideWindowType.ControlMap);
                            openSideWindow();
                            zoomAndShow();
                        }}
                        refreshProps={[data, controlMap]}
                    />
                    <BuildingsHierarchy 
                        keepZoom
                        parentId={data!.id} 
                        getData={() => EstateService.getHierarchyData("Buildings/allOfParent?type=0&id=" + data!.id)}/>
                    <RoomsHierarchy
                        showLocalMap
                        premiseOption={{id: data.id, name: data.name}}
                        getData={async () => EstateService.getHierarchyData("Rooms/allOfParent?type=0&id=" + data.id)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Premise}}
                        getData={async () => ItemsServiceNew.getAllItemsOfParent(data.id, SkobaEntityType.Premise)}/>
                    <ProcessesHierarchy
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Premise}}  
                        getData={() => EstateService.getHierarchyData("Processes")}/>
                    <FilesHierarchy getData={() => EstateService.getHierarchyData("File/allOfParent?type=0&id=" + data!.id)}
                        parent={{name: data!.name, id: data!.id, type: 1}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }