import * as React from "react";
import type { SVGProps } from "react";
const SvgPrehled2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M181.867 109.341V36.404H38.396v228.054h143.471v-34.099"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M181.867 226.648V109.341"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "12.5px",
      }}
    />
    <path
      d="M58.91 84.838h102.443M58.91 106.285h102.443M58.91 62.567h102.443M58.91 127.136h62.539"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M121.449 127.136h39.904"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "12.5px",
      }}
    />
    <path
      d="M58.91 148.583h49.712"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M108.622 148.583h52.731"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "12.5px",
      }}
    />
    <path
      d="M58.91 194.817h52.816"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M111.726 194.817h49.627"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "12.5px",
      }}
    />
    <path
      d="M58.91 216.265h69.924"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M128.834 216.265h32.519"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "12.5px",
      }}
    />
    <path
      d="M58.91 172.547h47.185"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M106.095 172.547h55.258"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "12.5px",
      }}
    />
    <path
      d="M58.91 237.115h102.443"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <circle
      cx={165.552}
      cy={167.966}
      r={62.394}
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "16.67px",
      }}
    />
    <path
      d="m215.089 217.08 66.515 66.516"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "20.83px",
      }}
    />
  </svg>
);
export default SvgPrehled2;
