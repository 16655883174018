import { Typography } from "@mui/material";

export interface ISTitleProps{
    text : string;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export default function STitle(props: ISTitleProps){
    return <Typography
            variant={props.variant ? props.variant : "h4"}
            color="inherit"
            >
           {props.text.toUpperCase()}
    </Typography>
}