import { getParentName, getParentRoute } from "../../Functions";
import { useNavigate } from "react-router-dom";
import PropertiesInfo, { IItemInfoRowDefinition } from "../../../general/tables/PropertiesInfo";
import { IItem, IItemDetailed, IItemProperty, IPropertyValue } from "../../../../types/overview/itemTypes";
import FormModal from "../../../general/modals/FormModal";
import { data } from "jquery";
import EstateService from "../../../../services/overview/EstateService";
import DeleteModal from "../../../general/modals/DeleteModal";
import FloorForm from "../../forms/hierarchy/FloorForm";
import ItemForm from "../../forms/items/ItemForm";
import ItemsServiceNew from "../../../../services/items/ItemsService";
import { useEffect } from "react";

export interface IItemInfoInfoProps{
    data : IItemDetailed
    values : IPropertyValue[]
    properties : IItemProperty[]
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function ItemInfo(props : IItemInfoInfoProps){    
    const navigate = useNavigate();
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, props.refreshProps)
    function getRowDefinition(){
        const rowDefinition = [{
            name : "Jméno", 
            property : "name",            
        },{
            name : "Typ", 
            property: "definitionTypeName"
        },{
            name : "Podtyp", 
            property: "definitionTypeParentTypeName"
        },{
            name : "Položka", 
            property: "",
            getValue: (object) => getParentName(object),
            onClick: (object) => navigate(getParentRoute(object))
        }] as IItemInfoRowDefinition[];
        props.properties.forEach((property) => {
            rowDefinition.push({
                name : property.name,
                property: property.id,
                getValue: (object) => {
                    const value = props.values.find((value) => value.propertyId === property.id);
                    return value ? value.value : "";
                }
            })
        })
        return rowDefinition;
    }
    return <PropertiesInfo
        data={props.data}
        rowDefinition={getRowDefinition()}
        buttons={(object) => [
            <FormModal  
            key={1}                        
            title="Upravit"    
            buttonElement={<span>Upravit</span>}
            form={<ItemForm buttonText="Uložit změny" model={props.data}  
                    onSubmit={ItemsServiceNew.updateItem}
                />}
            />,
            <DeleteModal key={2} buttonText="Smazat" itemName={props.data.name} 
                onConfirm={() => ItemsServiceNew.deleteItem(props.data).then(() => {navigate("/overview/items")})}/>
        ]}
    />
}