import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import React from 'react';

 const ForgottenPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
        .max(100, 'Too Long!')
        .email('Email není ve správném formátu')
        .required('Email nemůže být prázdný')
});

export interface ISignInProps{
    onSubmit: (values : any) => Promise<void>;
    disabled?: boolean;
}

export default function ForgottenPasswordForm(props : ISignInProps) {
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgottenPasswordValidationSchema,
        onSubmit: props.onSubmit,
    });    
    const dis = props.disabled ? props.disabled : false;
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            <TextField                
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={dis}

            />
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={dis}>
                Zažádát o resetování hesla
            </Button>
    </Box>
    </div>    
}
