import React from "react";
import EstateService from "../../../services/overview/EstateService";
import FilesHierarchy from "../../../components/overview/content/multiple/FilesHierarchy";
import HierarchyMultipleFilterPageNew from "../../../components/general/pages/HierarchyMultipleFilterPageNew";

export default function FilesPage(){
    return <HierarchyMultipleFilterPageNew title="Soubory">
        <FilesHierarchy getData={() => EstateService.getHierarchyData("File")}/>
    </HierarchyMultipleFilterPageNew>
    
  }