import { Box, Checkbox, FormControlLabel, Grid, Slider, TextField, Typography } from "@mui/material";
import { IItemProperty, IItemPropertyShort } from "../../../../types/overview/itemTypes";
import { forwardRef, useImperativeHandle } from "react";

export interface IItemPropertyFormProps{
    property: IItemPropertyShort
    value: any
    onChange: (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}
export interface IItemPropertyFormMethods {
    getValue: () => [string, any];
}

// export const ItemPropertyForm = forwardRef<IItemPropertyFormMethods, IItemPropertyFormProps>((props, ref) =>{
//     useImperativeHandle(ref, () => ({
//         getValue
//     }));
    
//     function getValue(): [string, any] {
//         return ["any", "any"]
//     }
//     function getField(){
//         switch(props.property.dataType){
//             case  "Celé číslo":
//             case "Poměr (procenta)":
//             case "Fyzikální veličina (s jednotkou)":
//                 return <TextField                
//                     fullWidth
//                     id={props.property.name}
//                     name={props.property.name}
//                     label={props.property.name}
//                     size="small"
//                     type="number"
//                 />
//             case "Textová hodnota (výčet: CZ_CrossSectionType)":
//             case "Textová hodnota":
//             case "Textová hodnota (výčet: CZ_RetainingWallType)":
//                 return <TextField                
//                     fullWidth
//                     id={props.property.name}
//                     name={props.property.name}
//                     label={props.property.name}
//                     size="small"
//                 />
//             case "IfcBoolean":
//                 return <FormControlLabel
//                     value={props.property.name}
//                     control={<Checkbox />}
//                     label={props.property.name}
//                     labelPlacement="top"
//                 />
//             default:
//                 return <div></div>
//         }
//     }
//     return <Grid item xs={props.property.dataType === "IfcBoolean" ? 2 : 6}>                      
//         {getField()}
//     </Grid>
// })

export default function ItemPropertyForm(props: IItemPropertyFormProps){
    function getField(){
        switch(props.property.dataType){
            case  "Celé číslo":
            case "Poměr (procenta)":
            case "Fyzikální veličina (s jednotkou)":
                return <TextField                
                    fullWidth
                    id={props.property.id.toString()}
                    name={props.property.name}
                    label={props.property.name}
                    onChange={props.onChange}
                    value={props.value}
                    size="small"
                    type="number"
                />
            case "Textová hodnota (výčet: CZ_CrossSectionType)":
            case "Textová hodnota":
            case "Textová hodnota (výčet: CZ_RetainingWallType)":
                return <TextField                
                    fullWidth
                    id={props.property.id.toString()}
                    name={props.property.name}
                    label={props.property.name}
                    onChange={props.onChange}
                    value={props.value}
                    size="small"
                />
            case "IfcBoolean":
                return <FormControlLabel
                    value={props.property.name}
                    control={<Checkbox                     
                        onChange={props.onChange}
                        value={props.value}
                        id={props.property.id.toString()}
                    />}
                    label={props.property.name}
                    labelPlacement="top"
                />
            default:
                return <div></div>
        }
    }
    return <Grid item xs={props.property.dataType === "IfcBoolean" ? 2 : 6}>                      
        {getField()}
    </Grid>
}