import React, { PropsWithChildren } from "react";
import { GError, Loading } from "../layouts/Components";

export interface ILoadingWrapperProps{
    error : boolean;
    loading : boolean;
    errorObject : any;

}

export default function LoadingWrapper(props : PropsWithChildren<ILoadingWrapperProps>){
    if (props.error)
        return <GError error={props.errorObject}/>
    else if (props.loading)
        return <Loading/>;
    else
        return <React.Fragment>{props.children}</React.Fragment>
}