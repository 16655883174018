import { ICategory, ICreateCategory } from "../../types/categories/categoryTypes";
import { IRoom } from "../../types/overview/estateTypes";
import { CategoryType } from "../../types/overview/skobaTypes";
import ApiService from "../general/ApiService";

const getCategoriesByType = (type: CategoryType) : Promise<ICategory[]>=> {
    return ApiService.apiGet("/Category/byType/" + type );
  };
  
  const createCategory = (item: ICreateCategory) : Promise<ICategory> => {
    return ApiService.apiPost("/Category", item);
  };
  const updateCategory  = (item: ICategory) : Promise<void> => {
    return ApiService.apiPut("/Category/" + item.id, item);
  };
  const deleteCategory  = (item: ICategory) : Promise<void> => {
    return ApiService.apiDelete("/Category/" + item.id);
  };

  const CategoryService = {
    getCategoriesByType,
    createCategory,
    updateCategory,
    deleteCategory
};

export default CategoryService;