import React, { useRef, useState } from "react";
import { RMap, ROSM, RControl, RLayerVector, RFeature, RPopup } from "rlayers";
import "./AdamStarter.css"
import {Projection, get as getProjection} from 'ol/proj';
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { Point } from "ol/geom";

proj4.defs("EPSG:5514",
"+proj=krovak +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +units=m +no_defs +type=crs");
register(proj4);
const proj5514 = getProjection('EPSG:5514') as Projection;
const proj5514Extent = [-935719.38, -1244158.89, -418597.43, -911041.21];
proj5514.setExtent(proj5514Extent);
proj5514.setWorldExtent(proj5514Extent);
const krovakOrigin = [-671679.09, -1082290.85]


export default function AdamStarter(){    

    const[geoButtonStates,setGeoButtonStates] = useState<{ [key:string]: boolean}>(
        {
            linkKN_button: false,
            coordinates_button: false,
            distance_button: false,
        });

 
    const popup = useRef<RPopup>(null)
//    const[capturedCoordinates, setCapturedCoordinates] = useState(false);
    const[cursorPosition, setCursorPosition] = useState<number[]>([0,0]);
//   const[buttonPressed, setButtonPressed] = useState(false);

   

    const handleButtonClick = (buttonId:string) => {

        const updatedGeoButtonStates: { [key:string]: boolean } = {};
        for (const button in geoButtonStates) {
            if (button === buttonId) {
                if(geoButtonStates[buttonId]){
                    updatedGeoButtonStates[button] = false;
                } else{
                    updatedGeoButtonStates[button] = true;    
                }            
            } else {
                updatedGeoButtonStates[button] = false;
            }
        }
        setGeoButtonStates(updatedGeoButtonStates)
    }
   
   
   
   const handleMapClick = (e: any) => {
        const coordinates = e.map.getCoordinateFromPixel(e.pixel);

        if(geoButtonStates['linkKN_button']){
            openKNLink(coordinates);
//            setCapturedCoordinates(false);
            setGeoButtonStates(prevStates => ({... prevStates, linkKN_button: false}));
        }

        if(geoButtonStates['coordinates_button']){

            if (popup !== null && popup.current !== null){
                popup.current.show()
                setCursorPosition([coordinates[0],coordinates[1]]);
//                setCapturedCoordinates(false);
//              setGeoButtonStates(prevStates => ({... prevStates, coordinates_button: false}));
        }
        }
    }

    const openKNLink = (coordinates: number[]) => {
        const link = `https://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&x=${coordinates[0].toFixed(0)}&y=${coordinates[1].toFixed(0)}`;
        window.open(link,"_blank");
   }


    return  <React.Fragment>
        {<div className="map-container">      
            <RMap
                className="interactive-map"
                initial={{center: krovakOrigin, zoom: 3 }}
                projection={proj5514}
                extent={proj5514Extent}
                onClick={handleMapClick}
                >
                <RControl.RCustom
                    className="button-container">
                    <button className={geoButtonStates['linkKN_button'] ? "pressed" : "" } onClick={() => {handleButtonClick('linkKN_button');}}>
                                   KN
                    </button>
                    <button className={geoButtonStates['coordinates_button'] ? "pressed" : "" } onClick={() => {handleButtonClick('coordinates_button');}}>
                                   XY
                    </button>
                    <button className={geoButtonStates['distance_button'] ? "pressed" : "" } onClick={() => {handleButtonClick('distance_button');}}>
                                   D
                    </button>
                </RControl.RCustom>
                <ROSM/>
                <RControl.RScaleLine />

                <RLayerVector>
                    <RFeature geometry={new Point([cursorPosition[0], cursorPosition[1]])}>
                        <RPopup ref={popup} trigger={"click"} className="example-overlay">
                            <div className="card">
                                <p className="card-header">
                                     <strong>Poloha bodu</strong> 
                                     <div 
                                        className={"button-krizek"} 
                                        onClick={() => {if (popup !== null && popup.current !== null){popup.current.hide(); setCursorPosition([0,0])}}}>
                                        X            
                                     </div>
                                </p>
                                <p className="card-body text-center"><strong>Y = {(-1*cursorPosition[0]).toFixed(3)}<br></br>X = {(-1*cursorPosition[1]).toFixed(3)}</strong></p>
                                </div>
                        </RPopup>
                    </RFeature>
                </RLayerVector>
            </RMap>
            <div className="map-info">
                <canvas width={100} height={100} id="chartCanvas" style={{display: "none"}}>
                </canvas>
                <div className="map-info-text">                    
                    Test: <strong>{cursorPosition[0].toFixed(3)} {cursorPosition[1].toFixed(3)}</strong>
                </div>
            </div>
        </div> }
    </React.Fragment>
} 