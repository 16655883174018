import React, { useEffect, useState } from "react";
import { IHistory, IHistoryDetailed } from "../../../types/overview/estateTypes";
import  "./HierarchyHistory.css"
import { GError, Loading } from "../../general/layouts/Components";
import EstateService from "../../../services/overview/EstateService";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";

export interface IHierarchyHistoryProps{    
    item: IHistory
}

export default function HierarchyHistory(props: IHierarchyHistoryProps){
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<IHistoryDetailed[]>([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        EstateService.getHistory(props.item)
        .then(
            (result) => {
                setLoaded(true);
                setData(result as IHistoryDetailed[]);
            },
            (error) => {
                setLoaded(true);
            setError(error);
            }
        )
    }, [props.item])
    function getLink(id: number, type: SkobaEntityType){
        var link = "/overview/";
        switch(type){
            case SkobaEntityType.Premise:
                link += "premises/";
                break;
            case SkobaEntityType.Building:
                link += "buildings/";
                break;
            case SkobaEntityType.Floor:
                link += "floors/";
                break;
            case SkobaEntityType.Room:
                link += "rooms/";
                break;
            case SkobaEntityType.Item:
                link += "items/";
                break;
            case SkobaEntityType.Process:
                link += "processes/";
                break;
            case SkobaEntityType.File:
                link += "files/";
                break;
        }
        return link + id;
    }
    if (error)
        return <GError error={error.message}/>
    else if (!loaded)
        return <Loading/>;
    return <div className="HierarchyHistory">
        {
            data.pop() &&
            data.map((v,k) => {
                return (<React.Fragment key={k}>
                    {k <= data.length - 2 ?
                        <React.Fragment>
                            <Chip label={v.name} onClick={() => navigate(getLink(v.id, v.type))} />                            
                            <span className="HistoryDivider"></span>
                        </React.Fragment>                        
                        :
                        <Chip label={v.name} onClick={() => navigate(getLink(v.id, v.type))} />    
                    }                    
                </React.Fragment>)
            })
        }
    </div>          
}