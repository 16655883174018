import { PropsWithChildren, useEffect, useState } from "react";
import { GError, Loading } from "../layouts/Components";
import React from "react";
import { Box } from "@mui/material";
import { RefreshDataContext } from "../contexts/RefreshDataContext";
import TabLayout from "../layouts/TabLayout";

export interface IHierarchySingleProps{
    onMount? : () => void;
    onReceivedData? : (data : any[]) => void;
    getData : () => Promise<any[]>;
    reloadData : () => void;
    refreshItself: boolean;
    tabTitles: string[];
    onRefresh : () => void;
    refreshProps: any[];
}

export default function HierarchySingle(props : PropsWithChildren<IHierarchySingleProps>) {
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
        if(props.onMount)
            props.onMount();
        getData();
    }, [])    
    useEffect(() => {
        props.onRefresh();
        if(data && props.onReceivedData){
            props.onReceivedData(data);
        }  
    }, props.refreshProps)
    useEffect(() => {
        getData();
    }, [props.getData])
    useEffect(() => {
        if(data && props.onReceivedData){
            props.onReceivedData(data);
        }        
    }, [data])
    function getData(){
        props.getData().then(
            (result) => {
                setLoaded(true);
                setData(result);
            },
            (error) => {
                setLoaded(true);
            setError(error);
            }
        )
    }
    function ReloadData(){
        getData();
    }    
    if (error)
        return <GError error={error}/>
    else if (!loaded)
        return <Loading/>;
    else {
        return (<RefreshDataContext.Provider value={props.refreshItself ? ReloadData : props.reloadData}>
                <React.Fragment>
                    <Box id="gegegege" sx={{}}> 
                        <TabLayout titles={props.tabTitles}>
                            {props.children}
                        </TabLayout>                  
                    </Box>            
                </React.Fragment>
        </RefreshDataContext.Provider>
    )}
}