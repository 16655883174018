import React, { useContext } from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup';
import {  ICategory } from '../../../types/categories/categoryTypes';
import { CloseFormContext } from '../../general/contexts/CloseFormContext';
import { Box, Button, TextField, Typography } from '@mui/material';
import { RefreshDataContext } from '../../general/contexts/RefreshDataContext';
import { CategoryType } from '../../../types/overview/skobaTypes';

 const CategoryValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),    
 });

 export interface ICategoryFormProps{
    model: ICategory | null;
    buttonText : string;
    type: CategoryType;
    onSubmit: (values : any) => Promise<any>;
}

export default function CategoryForm(props : ICategoryFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext);
  
    const [error, setError] = React.useState("");
    const [submitting, setSubmit] = React.useState(false);
    const handleSubmit = async (data : any) => {
        await props.onSubmit(data)
        .then((res) =>{
            setSubmit(false);
            handleClose();
            handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
      })
    };
    const formik = useFormik({
        initialValues: props.model === null ? {
            name: '',
            number: '',
            type: props.type
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            type: props.model.type,
        },
        validationSchema: CategoryValidationSchema,
        onSubmit: handleSubmit,
    });    
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
        <TextField                
            fullWidth
            id="name"
            name="name"
            label="Název"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={submitting}
        />
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
            {props.buttonText}
        </Button>
        {error !== "" && 
            <Typography component="i" color="red">
            Při zpracovávání vašeho požadavku došlo k chybě: {error}
            </Typography>                  
        }
    </Box>
    </div>    
}