import { IDefinition, IItem, IPropertyValue, IItemProperty, IItemType, IDefinitionDetailed, IItemCreate, IItemDetailed } from "../../types/overview/itemTypes";
import { SkobaEntityType } from "../../types/overview/skobaTypes";
import ApiService from "../general/ApiService";

const getDefinitions = () : Promise<IDefinitionDetailed[]>=> {
    return ApiService.apiGet("/Definitions");
};
const getDefinition = (id : any) : Promise<IDefinition>=> {
    return ApiService.apiGet("/Definitions/" + id);
};

const getDefinitionProperties = (id : any) : Promise<IItemProperty[]>=> {
    return ApiService.apiGet("/Definitions/" + id + "/getProperties");
};
const getDefinitionPropertiesNonShared = (id : any) : Promise<IItemProperty[]>=> {
    return ApiService.apiGet("/Definitions/" + id + "/getPropertiesNonShared");
};

const getItems = () : Promise<IItem[]>=> {
    return ApiService.apiGet("/Items");
};
const getAllItemsOfParent = (parentId: number, parentType: SkobaEntityType) : Promise<IItem[]>=> {
    return ApiService.apiGet("/Items/allOfParent?type=" + parentType +"&id=" + parentId);
};
const getItem = (id : any) : Promise<IItemDetailed> => {
    return ApiService.apiGet("/Items/" + id);
};
const createItem = (item: IItemCreate) : Promise<IItem> => {
    return ApiService.apiPost("/Items", item);
};
const updateItem = (item: IItemDetailed) : Promise<void> => {
    return ApiService.apiPut("/Items/" + item.id, item);
};
const deleteItem = (item: IItem) : Promise<void> => {
    return ApiService.apiDelete("/Items/" + item.id);
};
const getItemValues = (id : any) : Promise<IPropertyValue[]>=> {
    return ApiService.apiGet("/Items/" + id + "/values");
};

const getItemProperties = (id : any) : Promise<IItemProperty[]>=> {
    return ApiService.apiGet("/Items/" + id + "/properties");
};


const getTypes = () : Promise<IItemType[]>=> {
    return ApiService.apiGet("/ItemTypes/All");
};


const ItemsServiceNew = {
    getDefinitions,
    getDefinition,
    getDefinitionProperties,
    getItems,
    getAllItemsOfParent,
    getItem,
    createItem,
    updateItem,
    deleteItem,
    getItemValues,
    getItemProperties,
    getTypes,
    getDefinitionPropertiesNonShared
};

export default ItemsServiceNew;