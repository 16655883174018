import { Box } from "@mui/material";
import PropertiesInfo from "../../general/tables/PropertiesInfo"
import FormModal from "../../general/modals/FormModal";
import AdminService from "../../../services/admin/AdminService";
import UserForm from "../forms/UserForm";

export interface IUsersInfoProps{
    data : IUserStatistics
}
export interface IUserStatistics{
    count: number;
    owners: number;
    admins: number;
    editors: number;
    users: number;
}

export default function UsersInfo(props : IUsersInfoProps){    
    return <Box sx={{'& > *': { mb: 2 }}}>
            <PropertiesInfo
            data={props.data}
            rowDefinition={[{
                name : "Celkový počet uživatelů", 
                property : "count",            
            },{
                name : "Počet vlastníků", 
                property : "owners",             
            },{
                name : "Počet administrátorů", 
                property : "admins",                  
            },{
                name : "Počet editorů", 
                property : "editors",            
            },{
                name : "Počet běžných uživatelů", 
                property : "users",            
            }]}
            />
            <FormModal                
                title="Vytvořit nového uživatele"    
                buttonText="Vytvořit uživatele"
                form={<UserForm buttonText={"Uložit změny"} onSubmit={AdminService.createUser}/>}
            /> 
    </Box>
}