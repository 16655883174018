import * as React from "react";
import type { SVGProps } from "react";
const SvgPlatby = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M292.335 115.567c0-24.523-19.909-44.433-44.433-44.433H72.098c-24.524 0-44.433 19.91-44.433 44.433v88.866c0 24.523 19.909 44.433 44.433 44.433h175.804c24.524 0 44.433-19.91 44.433-44.433z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <path
      d="M27.665 104.832h264.671v34.859H27.665z"
      style={{
        fill: "#333",
      }}
    />
    <path
      d="M277.961 170.016c0-5.528-4.488-10.016-10.016-10.016h-31.018c-5.528 0-10.016 4.488-10.016 10.016v20.031c0 5.528 4.488 10.016 10.016 10.016h31.018c5.528 0 10.016-4.488 10.016-10.016zM44.26 191.428h128.138M74.752 211.459h97.646"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
  </svg>
);
export default SvgPlatby;
