import React from "react";

export interface IMatterportState{
}

class Matterport extends React.Component<object, IMatterportState>{
    constructor(props : object){
        super(props);
        this.state = ({            
        })
    }
    
    render(): React.ReactNode {   
        return (
            <React.Fragment>
                <iframe width='100%' height='99%' src='https://my.matterport.com/show/?m=BAtc9swshzG' allow='xr-spatial-tracking'></iframe>
        </React.Fragment>
        )
    }
}
export default Matterport