import { ISensorDetailed } from "../../../../types/overview/estateTypes";
import { useNavigate } from "react-router-dom";
import PropertiesInfo from "../../../general/tables/PropertiesInfo";

export interface ISpecificItemInfoProps{
    data : ISensorDetailed
}

export default function SensorInfo(props : ISpecificItemInfoProps){    
    const navigate = useNavigate();
    
    return <PropertiesInfo
        data={props.data}
        rowDefinition={[{
            name : "Jméno", 
            property : "name",            
        },{
            name : "Identifikátor", 
            property : "identifier",            
        },{
            name : "Identifikátor senzoru", 
            property : "sensorIdentifier",            
        },{
            name : "Jednotky", 
            property : "unit",            
        },{
            name : "Výrobce", 
            property : "manufactuer",            
        },{
            name : "xDef", 
            property : "xDef",            
        },{
            name : "yDef", 
            property : "yDef",            
        },{
            name : "Budova", 
            property : "buildingName",
            onClick: (object) => navigate("/overview/buildings/" + object.buildingId)                
        },{
            name : "Prostor", 
            property : "roomName",
            onClick: (object) => navigate("/overview/rooms/" + object.roomId)
        }]}        
    /> 
}