import SkobaTable, { ISkobaTableDataProps } from "../../../general/tables/SkobaTable";
import { useNavigate } from "react-router-dom";

const BuildingsHeader = ["Jméno", "Kód", "Adresa", "Plocha [m2]", "Obestavěný prostor [m3]", "Katastrální území", "Areál", "Prostory", "Plocha prostorů [m2]", "Prvky"];

export default function BuildingsTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={BuildingsHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name",
                    onClick: (object) => navigate("/overview/buildings/" + object.id)
                },{
                    property: "code"
                },{
                    property: "adress"                
                },{
                    property: "area"                
                },{
                    property: "enclosedArea"                
                },{
                    property: "cadastralTerritory"                
                },{
                    property: "premiseName",
                    onClick: (object) => navigate("/overview/premises/" + object.premiseId)
                },{
                    property: "roomsCount"                
                },{
                    property: "roomsArea"                
                },{
                    property: "itemsCount"                
                }
                ],
                // buttons: (object) => [
                //     <FormModal 
                //         size="small"
                //         buttonText="Upravit"
                //         buttonElement={<AiFillEdit/>}
                //         title="Upravit budovu"    
                //         form={<BuildingForm parent={null} 
                //             buttonText="Uložit změny" 
                //             model={object} 
                //             selectable={true} 
                //             onSubmit={HierarchyService.updateBuilding}/>}
                //     />,
                //     <DeleteModal 
                //         size="small" 
                //         itemName={object.name} 
                //         onConfirm={() => HierarchyService.deleteBuilding(object)}/>
                // ]                
            }
        }
    />
}