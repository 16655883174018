import { ReactElement, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CloseFormContext } from '../contexts/CloseFormContext';
import { AdminLayout, EditorLayout } from '../layouts/Layouts';
import { Box, Button } from '@mui/material';
import React from 'react';

export interface IFormModalProps {    
  form: ReactElement ;
  buttonText?: string;
  title: string;
  buttonElement? : JSX.Element;
  color? : 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant? : 'text' | 'outlined' | 'contained';
  size? : 'small' | 'medium' | 'large';
  needsAdmin? : boolean;
}


export default function FormModal(props: IFormModalProps) {
  const [show, setShow] = useState(false);

  function handleClose(){
    setShow(false);
  }
  function handleShow(){
    setShow(true)
  }
  
  function getForm() : JSX.Element{
    return <React.Fragment>
      <Button color={props.color ? props.color : "primary"} 
          variant={props.variant ? props.variant : "contained"}  
          onClick={handleShow}
          size={props.size ? props.size : "medium"}>
            {props.buttonElement == null ? props.buttonText : props.buttonElement}
          </Button>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <CloseFormContext.Provider value={handleClose}>
              <Modal.Body>
                {props.form}
              </Modal.Body>
            </CloseFormContext.Provider>
          </Modal>
    </React.Fragment>
  }
  if(props.needsAdmin == true){
    return <AdminLayout>
      {getForm()}
    </AdminLayout>
  }
  else{
    return <EditorLayout>
      {getForm()}
    </EditorLayout>
  }

}