import { AiFillEdit } from "react-icons/ai";
import DeleteModal from "../../../general/modals/DeleteModal";
import FormModal from "../../../general/modals/FormModal";
import { downloadFile, formatFileSize, getParentLocationId, getParentName, getParentRoute, getParentType } from "../../Functions";
import FilesService from "../../../../services/overview/FileService";
import FileForm from "../../forms/file/FileForm";
import { useNavigate } from "react-router-dom";
import SkobaTable, { ISkobaTableDataProps } from "../../../general/tables/SkobaTable";

const FilesHeader = ["Jméno", "Typ", "Popis", "Velikost", "Soubor", "Položka"];

export default function FilesTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={FilesHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                    property: "name",
                    // onClick: (object) => navigate("/overview/files/" + object.id)
                },{
                    property: "category"
                },{
                    property: "description"
                },{
                    property: "size",
                    getValue: (object) => formatFileSize(Number(object.size) * 1024)
                },{
                    property: "filename",
                    onClick: (object) => FilesService.downloadFile(object.id).then(downloadFile)
                },{
                    property: "",
                    getValue: (object) => getParentName(object),
                    onClick: (object) => navigate(getParentRoute(object))
                }
                ],
                buttons: (object) => {
                    object.location = getParentLocationId(object);
                    object.locationType = getParentType(object);
                    return [
                    <FormModal 
                        buttonElement={<AiFillEdit/>}
                        title="Upravit soubor"      
                        size="small"                                           
                        form={<FileForm buttonText="Uložit změny" model={object}
                            onSubmit={FilesService.updateFile}
                        />}
                    />,
                    <DeleteModal 
                        size="small" 
                        itemName={object.name} 
                        onConfirm={() => FilesService.deleteFile(object)}/>
                ]}
                
            }
        }
    />
}