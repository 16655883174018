import * as React from "react";
import type { SVGProps } from "react";
const SvgBudovy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M79.563 106.794h162.549V282.75H79.563z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "6.98px",
      }}
    />
    <path
      d="M99.672 126.904h38.543v33.515H99.672zM118.944 126.904v33.515M176.758 126.904h38.543v33.515h-38.543zM196.029 126.904v33.515M99.672 175.501h38.543v33.515H99.672zM118.944 175.501v33.515M176.758 175.501h38.543v33.515h-38.543zM196.029 175.501v33.515M138.215 227.45h43.57v55.3h-43.57zM160 227.45v55.3M99.672 267.668h23.461v8.379H99.672zM200.218 267.668h23.461v8.379h-23.461z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "3.49px",
      }}
    />
    <path
      d="m160 37.25 118.98 68.706H41.02zM51.075 282.75h214.498"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "6.98px",
      }}
    />
  </svg>
);
export default SvgBudovy;
