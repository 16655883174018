import HierarchyMultipleFilterPageNew from "../../../components/general/pages/HierarchyMultipleFilterPageNew";
import ItemsHierarchy from "../../../components/overview/content/multiple/ItemsHierarchy";
import ItemsServiceNew from "../../../services/items/ItemsService";
import EstateService from "../../../services/overview/EstateService";

export default function ItemsPage(){    
    return <HierarchyMultipleFilterPageNew title="Prvky">
        <ItemsHierarchy 
        getData={ItemsServiceNew.getItems}       
        />
    </HierarchyMultipleFilterPageNew>
  }