import Sidebar, { LargeIconSize } from "../../general/content/Sidebar"
import STitle from "../../general/text/STitle"
import { useLocation } from "react-router-dom"
import { SkobaPremiseIcon, SkobaBuildingIcon, SkobaFloorIcon, SkobaRoomIcon, SkobaSensorIcon, SkobaItemsIcon, SkobaProcessesIcon, SkobaFilesIcon } from "../../icons/SkobaIcons"
import { SkobaColors } from "../../../types/colors/SkobaTheme"

const links = [{
        text: "Areály",
        to: "/overview/premises",
        icon : <SkobaPremiseIcon sx={{fontSize:LargeIconSize}}/>                
    },
    {
        text: "Budovy",
        to: "/overview/buildings",
        icon : <SkobaBuildingIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Patra",
        to: "/overview/floors",
        icon : <SkobaFloorIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Prostory",
        to: "/overview/rooms",
        icon : <SkobaRoomIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Čidla",
        to: "/overview/sensors",
        icon : <SkobaSensorIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Prvky",
        to: "/overview/items",
        icon : <SkobaItemsIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Činnosti",
        to: "/overview/processes",
        icon : <SkobaProcessesIcon sx={{fontSize:LargeIconSize}}/>
    },
    {
        text: "Soubory",
        to: "/overview/files",
        icon : <SkobaFilesIcon sx={{fontSize:LargeIconSize}}/>
    }
]

export default function OverviewSidebar(){
    const location = useLocation();
    return <Sidebar
    header={<STitle text="Funkce" />}
    items={links}
    getActive={(item) => location.pathname.includes(item.to)}
    color={SkobaColors.Gray2}
    toggle
/>
}