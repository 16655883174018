import { loadStripe } from '@stripe/stripe-js';

let stripePromise : any;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_test_51Mh8aQCMWV2jXqTnJ14iRp4DqyPrBEDjTnPHDroT4rMMmq9GvpERQYtGWVy4Cn8rwwOD80X9kbSjxZcoQ99FjiUe00XHFA6fhZ");
  }
  return stripePromise;
};

export default getStripe;