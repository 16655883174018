import React from "react";
import Sidebar, { ISidebarItem } from "./Sidebar";
import UserInfo from "../../accountManagement/content/UserInfo";
import { useLocation } from "react-router-dom";
import { SkobaLogo } from "../../icons/SkobaIcons";
import { SkobaColors } from "../../../types/colors/SkobaTheme";

const links = [
    {
      to : "/overview",
      text : "Přehled",
    },
    {
      to : "/categories",
      text : "Kategorie",
    },
    // {
    //   to : "/preview",
    //   text : "Náhled",
    // //   layout: DevLayout
    // },
    // {
    //   to : "/edit",
    //   text : "Editace",
    // //   layout: DevLayout
    // },
    // {
    //   to : "/buildings",
    //   text : "Budovy",
    // //   layout: DevLayout
    // },
    {
      to : "/admin",
      text : "Administrace",
    },
    // {
    //   to : "/adamStarter",
    //   text : "Adam",
    // }
] as ISidebarItem[]

export default function SkobaSidebar() {
    const location = useLocation();

    return <Sidebar
        header={<React.Fragment>
          <SkobaLogo sx={{height: "60px", width:"100%"}}/>
          <UserInfo/>
        </React.Fragment>}
        items={links}
        getActive={(item) => location.pathname.includes(item.to)}
        color={SkobaColors.Gray1}
    />
}