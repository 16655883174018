import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../../../services/overview/EstateService";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import { SkobaEntityType, ISelectBetter, CategoryType } from "../../../../types/overview/skobaTypes";
import { Grid, InputLabel, MenuItem, FormControl, Select, Box, Stack } from "@mui/material";
import { IItemCategory } from "../../../../types/overview/itemTypes";
import FilesTable from "../../tables/other/FilesTable";
import FilesService from "../../../../services/overview/FileService";
import FormModal from "../../../general/modals/FormModal";
import DualTextFilter from "../../filters/DualTextFilter";
import FileForm from "../../forms/file/FileForm";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import FilesSummary from "../../summary/FilesSummary";
import CategoryService from "../../../../services/categories/CategoryService";


export interface IFilesHierarchyProps{
    getData : () => Promise<any>;
    parent?: ISelectBetter;
}

export default function FilesHierarchy(props: IFilesHierarchyProps){    
    const { closeSideWindow, openHierarchy} = useContext(SideWindowContext);
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<number>(0);
    const [categories, setCategories] = useState<IItemCategory[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [error, setError] = useState<any>(null);
    const [errorFilters, setErrorFilters] = useState<any>(null);

    useEffect(() => {
        loadData();         
        openHierarchy();
        closeSideWindow();
    }, [])
    useEffect(() => {        
        setFilteredData(data.filter(x => categoryFilter === 0 || (x.category === categoryFilter)));
    }, [categoryFilter]) 

    function loadData(){
        const categoriesPromise = CategoryService.getCategoriesByType(CategoryType.File);
        const dataPromise = props.getData();
        Promise.all([categoriesPromise, dataPromise])
        .then(
            ([data1, data2]) => {
                setLoaded(true);
                setLoadedData(true);
                setCategories(data1);
                setData(data2);
                setFilteredData(data2);
            },
            (error) => {
                setLoaded(true);
                setLoadedData(true);
                setError(error);
                setErrorFilters(error);
            }
        )
    }
    function onFilterChange(data: any[]){
        setFilteredData(data);
    }
    function getFilters() {
        return <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="main-item-category-filter">Kategorie</InputLabel>
                    <Select                     
                        variant="filled"   
                        labelId="main-item-category-filter"
                        label="Kategorie" 
                        fullWidth
                        value={categoryFilter.toString()}
                        defaultValue={""}
                        onChange={(event) => {
                            setCategoryFilter(parseInt(event.target.value));
                        }}
                    >
                        <MenuItem value={"0"}>Všechny kategorie</MenuItem>
                        {categories.map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                    </Select>
                </FormControl> 
            </Grid>      
        </Grid>
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={error} loading={!loadedData}>
            <Grid container spacing={2} rowSpacing={2}>
                    <Grid item xs={12}>
                        <DualTextFilter
                            data={data} 
                            labelTextFirst="Hledat dle jména přílohy"
                            filterPropertyFirst="name"
                            labelTextSecond="Hledat dle jména souboru"
                            filterPropertySecond="filename"
                            onChange={onFilterChange} 

                        />
                    </Grid>
                    <Grid item xs={12}>
                        {getFilters()}                    
                    </Grid>
            </Grid>
            <Box sx={{py:2}}>
                <FilesTable data={data}/>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{pt:1}}
                    >
                        <FormModal 
                    buttonText="Vytvořit přílohu"
                    title="Vytvořit novou přílohu"    
                    form={<FileForm buttonText="Vytvořit" 
                        model={null}
                        selectOptions={props.parent}
                        onSubmit={FilesService.createFile}/>}
                    /> 
                </Stack>
            </Box>            
            <FilesSummary data={data}/>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }