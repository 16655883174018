import Sidebar, { LargeIconSize } from "../../general/content/Sidebar"
import STitle from "../../general/text/STitle"
import { useLocation } from "react-router-dom";
import { SkobaOverviewIcon, SkobaPaymentIcon, SkobaUserIcon } from "../../icons/SkobaIcons";

const links = [{
    text: "Přehled",
    to: "/admin/info",
    icon : <SkobaOverviewIcon sx={{fontSize:LargeIconSize}}/>
    },{
    text: "Uživatelé",
    to: "/admin/users",
    icon : <SkobaUserIcon sx={{fontSize:LargeIconSize}}/>
    },{
    text: "Platby",
    to: "/admin/payments",
    icon : <SkobaPaymentIcon sx={{fontSize:LargeIconSize}}/>
}]

export default function AdminSidebar(){    
    const location = useLocation();
    return <Sidebar
        header={<STitle text="Funkce" />}
        items={links}
        getActive={(item) => location.pathname.includes(item.to)}
        color="#d9d9d9"
        toggle
    />
}