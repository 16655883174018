import { Box } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useFetcher, useLoaderData, useLocation } from "react-router-dom";
import HierarchyFiltersMultiple from "../content/HierarchyFiltersMultiple";
import STitle from "../text/STitle";

export interface IHierarchyMultipleFilterPageProps{
    title: string;
    onMount : () => void;
    onRefresh : () => void;
    refreshProps: any[];
    onReceivedData : (data : any[]) => void;
    getChildrenTable : (data : any[]) => ReactElement;
    getFilters : (data : any[], onChange : (data: any[]) => void) => ReactElement;
    modifyButtons : ReactElement[];
    getSummary? : (data : any[]) => ReactElement;
}

export default function HierarchyMultipleFilterPage(props: IHierarchyMultipleFilterPageProps){    
    const [data, setData] = useState<any[]>(useLoaderData() as any[]);
    const fetcher = useFetcher();
    const location = useLocation();
    function reloadData(){
        fetcher.load(location.pathname);
    }
    useEffect(() => {
        if (fetcher.data) {
            setData(fetcher.data);
        }
    }, [fetcher.data]);
    return <React.Fragment>
        <Box sx={{p:2, height:"100%", '& > *': { mb: 2 }}}>
            <STitle text={props.title}/>
            <HierarchyFiltersMultiple
                onRefresh={props.onRefresh}
                refreshProps={props.refreshProps}
                getFilters={props.getFilters}
                getChildrenTable={props.getChildrenTable}
                getData={async () => data}
                modifyButtons={props.modifyButtons}
                onMount={props.onMount}
                onReceivedData={props.onReceivedData}
                reloadData={reloadData}
                refreshItself={false}
            />
            {props.getSummary ? props.getSummary(data) : <React.Fragment></React.Fragment>}
            </Box>
    </React.Fragment>
  }