import { ReactElement, useEffect, useState } from "react";
import { GError, Loading } from "../layouts/Components";
import React from "react";
import { Box, Stack } from "@mui/material";
import { RefreshDataContext } from "../contexts/RefreshDataContext";

export interface IHierarchyMultipleProps{
    onMount : () => void;
    onReceivedData : (data : any[]) => void;
    onRefresh : () => void;
    refreshProps: any[];
    getData : () => Promise<any[]>;
    reloadData : () => void;
    getChildrenTable : (data : any[]) => ReactElement;
    getFilters : (data : any[], onChange : (data: any[]) => void) => ReactElement;
    modifyButtons : ReactElement[];
    refreshItself: boolean;
}

export default function HierarchyFiltersMultiple(props : IHierarchyMultipleProps) {
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);

    useEffect(() => {
        props.onMount();
        getData();
    }, [])
    useEffect(() => {
        props.onRefresh();
        props.onReceivedData(data);
    }, props.refreshProps)
    useEffect(() => {
        getData();
    }, [props.getData]) 
    useEffect(() => {
        if(data){
            props.onReceivedData(data);
        }        
    }, [data])
    function getData(){
        props.getData().then(
            (result) => {
                setLoaded(true);
                setData(result);
            },
            (error) => {
                setLoaded(true);
            setError(error);
            }
        )
    }
    function FilterData(data : any[]){
        setFilteredData(data);
    }
    function ReloadData(){        
        getData();
    }
    function getModifyButtons() : ReactElement{
        return <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{pt:1}}
            >
                {props.modifyButtons.map((button, i) => 
                <React.Fragment key={i}>
                    {button}
                </React.Fragment>)}
        </Stack>
    }
    if (error)
        return <GError error={error}/>
    else if (!loaded)
        return <Loading/>;
    else {
        return (<RefreshDataContext.Provider value={props.refreshItself ? ReloadData : props.reloadData}>
            <Box sx={{p:0, '& > *': { mb: 2 }}}>
                {props.getFilters(data, FilterData)}
                {props.getChildrenTable(filteredData)}
                {getModifyButtons()}
            </Box>
        </RefreshDataContext.Provider>
    )}
}