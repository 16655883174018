import * as React from "react";
import type { SVGProps } from "react";
const SvgUzivatele = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "6.25px",
      }}
    />
    <circle
      cx={160}
      cy={100.109}
      r={59.891}
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "8.33px",
      }}
    />
    <path
      d="M160 176.429c22.08 0 42.649-20.28 59.891-8.014 30.2 21.487 50.188 58.737 50.188 101.056 0 66.479-49.325 120.451-110.079 120.451S49.921 335.95 49.921 269.471c0-42.319 19.988-79.569 50.188-101.056 17.242-12.266 37.811 8.014 59.891 8.014"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "8.33px",
      }}
    />
    <ellipse
      cx={104.103}
      cy={320}
      rx={7.762}
      ry={75.215}
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "8.33px",
      }}
    />
    <ellipse
      cx={219.891}
      cy={320}
      rx={7.762}
      ry={75.215}
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "8.33px",
      }}
    />
  </svg>
);
export default SvgUzivatele;
