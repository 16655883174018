import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function FailPage() {
    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            
          <Typography component="h1" variant="h5" color={"red"}>
            Platba se nepovedla
          </Typography>
          <NavLink to="/admin">Zpčt do aplikace</NavLink>  
          </Box>
        </Container>
    );
  }