import React, { useContext, useEffect, useState } from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup';
import { ISelect } from '../../../../types/overview/skobaTypes';
import { IBuilding } from "../../../../types/overview/estateTypes";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CloseFormContext } from '../../../general/contexts/CloseFormContext';
import { RefreshDataContext } from '../../../general/contexts/RefreshDataContext';
import EstateService from '../../../../services/overview/EstateService';
import { GError, Loading } from '../../../general/layouts/Components';
 
 const BuildingValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    code: Yup.string()
        .max(50, 'Too Long!')
        .required('Kód nemůže být prázdný'),
    adress: Yup.string()
        .max(50, 'Too Long!')
        .nullable(),
    enclosedArea: Yup.number().nullable(),
    area: Yup.number().nullable(),
    cadastralTerritory: Yup.string()
        .max(50, 'Too Long!')
        .nullable(),
    premiseId: Yup.number()
        .nullable(),
    xDef: Yup.number()
        .nullable(),
    yDef: Yup.number()
        .nullable()
        
 });

 export interface IBuildingFormProps{
    model: IBuilding | null;
    buttonText : string;
    selectable: boolean;
    parent: number | null;
    onSubmit: (values : any) => Promise<any>;
}

export default function BuildingForm(props : IBuildingFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext);    
    const [loaded, setLoaded] = useState(false);
    const [premises, setPremises] = useState<ISelect[]>([]);
    const [error, setError] = useState("");
    const [errorFetching, setErrorFetching] = useState<any>(null);
    const [submitting, setSubmit] = useState(false);
    useEffect(() => {
        setLoaded(false);
        EstateService.getPremises().then(x => {
            setPremises(x.map(x => {return {name: x.name, id: x.id}}))                
            setLoaded(true);
        }).catch(err => {
            setErrorFetching("Při načítání dat došlo k chybě, opakujte akci později");
        });               
        setLoaded(true);
    }, [])    
    const handleSubmit = async (data : any) => {
        await props.onSubmit(data)
        .then((res) =>{
            setSubmit(false);
            handleClose();
            handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
      })
    };
    const formik = useFormik({
        initialValues: props.model === null ? {
            name: '',
            code: '',
            adress: '',
            premiseId: props.selectable ? -1 : props.parent,
            xDef: '',
            yDef: '',
            enclosedArea: '',
            area: '',
            cadastralTerritory: ''
        } : 
        {            
            id: props.model.id,
            name: props.model.name,
            code: props.model.code,
            adress: props.model.adress,
            premiseId: props.model.premiseId,
            xDef: props.model.xDef,
            yDef: props.model.yDef,
            area: props.model.area,
            enclosedArea: props.model.enclosedArea,
            cadastralTerritory: props.model.cadastralTerritory
        },
        validationSchema: BuildingValidationSchema,
        onSubmit: (values => {
            if (values.premiseId === -1)
                values.premiseId = null;
            handleSubmit(values);
        })
    });
    function getParentSelect() : JSX.Element{
        return <FormControl fullWidth>
            <InputLabel id="premiseId-parent-label">Areál</InputLabel>
            <Select
                fullWidth
                labelId="premiseId-parent-label"
                id="premiseId"
                name="premiseId"
                label="Areál"
                value={formik.values.premiseId === null ? -1 : formik.values.premiseId}
                onChange={formik.handleChange}
                disabled={!props.selectable || submitting}
                error={formik.touched.premiseId && Boolean(formik.errors.premiseId)}
            >
            {props.selectable && 
                <MenuItem value={-1} selected>Samostatná budova</MenuItem>
            }
            {
                premises.map((v,k) =>{
                    return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                })
            }
            </Select>
      </FormControl>
    }
    if (errorFetching)
        return <GError error={error}/>
    else if (!loaded)
        return <Loading/>;
    else
        return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
        <TextField                
            fullWidth
            id="name"
            name="name"
            label="Jméno"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={submitting}
        />
        <TextField
            fullWidth
            id="code"
            name="code"
            label="Kód"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            disabled={submitting}
        />
        {getParentSelect()}
        <TextField
            fullWidth
            id="adress"
            name="adress"
            label="Adresa"
            value={formik.values.adress}
            onChange={formik.handleChange}
            error={formik.touched.adress && Boolean(formik.errors.adress)}
            helperText={formik.touched.adress && formik.errors.adress}
            disabled={submitting}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
                fullWidth
                id="area"
                name="area"
                label="Plocha"
                type="number"
                value={formik.values.area}
                onChange={formik.handleChange}
                error={formik.touched.area && Boolean(formik.errors.area)}
                helperText={formik.touched.area && formik.errors.area}
                sx={{ marginRight: 1 }}
                disabled={submitting}
            />
            <TextField
                fullWidth
                id="enclosedArea"
                name="enclosedArea"
                label="Obestavěný prostor"
                type="number"
                value={formik.values.enclosedArea}
                onChange={formik.handleChange}
                error={formik.touched.enclosedArea && Boolean(formik.errors.enclosedArea)}
                helperText={formik.touched.enclosedArea && formik.errors.enclosedArea}
                sx={{ marginRight: 1 }}
                disabled={submitting}
            />
        </Box>
        <TextField
            fullWidth
            id="cadastralTerritory"
            name="cadastralTerritory"
            label="Katastrální území"
            value={formik.values.cadastralTerritory}
            onChange={formik.handleChange}
            error={formik.touched.cadastralTerritory && Boolean(formik.errors.cadastralTerritory)}
            helperText={formik.touched.cadastralTerritory && formik.errors.cadastralTerritory}
            disabled={submitting}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            <TextField
                fullWidth
                id="xDef"
                name="xDef"
                label="xDef"
                type="number"
                value={formik.values.xDef}
                onChange={formik.handleChange}
                error={formik.touched.xDef && Boolean(formik.errors.xDef)}
                helperText={formik.touched.xDef && formik.errors.xDef}
                sx={{ marginRight: 1 }}
                disabled={submitting}
            />
            <TextField
                fullWidth
                id="yDef"
                name="yDef"
                label="yDef"
                type="number"
                value={formik.values.yDef}
                onChange={formik.handleChange}
                error={formik.touched.yDef && Boolean(formik.errors.yDef)}
                helperText={formik.touched.yDef && formik.errors.yDef}
                disabled={submitting}
            />
        </Box>      
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
            {props.buttonText}
        </Button>
        {error !== "" && 
            <Typography component="i" color="red">
            Při zpracovávání vašeho požadavku došlo k chybě: {error}
            </Typography>                  
        }
    </Box>
    </div>    
}