import { getParentName, getParentRoute } from "../../Functions";
import { useNavigate } from "react-router-dom";
import SkobaTable, { ISkobaTableDataProps } from "../../../general/tables/SkobaTable";

const ItemsHeader = ["Jméno", "Typ", "Podtyp", "Položka"];

export default function ItemsTable(props : ISkobaTableDataProps){
    const navigate = useNavigate();
    
    return <SkobaTable
        data={props.data}
        header={ItemsHeader}
        rowDefinition={
            {
                columnDefinitions: [{
                        property: "name",
                        onClick: (object) => navigate("/overview/items/" + object.id)
                    },{
                        property: "definitionItemTypeName"
                    },{
                        property: "definitionItemTypeParentTypeName"
                    },{
                        property: "",
                        getValue: (object) => getParentName(object),
                        onClick: (object) => navigate(getParentRoute(object))
                    }
                ],
                
            }
        }
    />
}