import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import SidebarLayout from './components/general/layouts/SidebarLayout';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, CssBaseline } from '@mui/material';
import SignInPage from './pages/AccountManagement/SignIn';
import { AdminLayout, ProtectedLayout, PublicLayout } from './components/general/layouts/Layouts';
import SignUpPage from './pages/AccountManagement/SignUp';
import ForgottenPasswordPage from './pages/AccountManagement/ForgottenPassword';
import ChangePasswordPage from './pages/AccountManagement/ChangePassword';
import ResetPasswordPage from './pages/AccountManagement/ResetPassword';
import FailPage from './pages/Payment/Fail';
import SuccessPage from './pages/Payment/Success';
import AdamStarter from './pages/adam/AdamStarter';
import PremisesPage from './pages/Overview/Multiple/PremisesPage';
import EstateService from './services/overview/EstateService';
import BuildingsPage from './pages/Overview/Multiple/BuildingsPage';
import OverviewSidebarLayout from './components/overview/layouts/OverviewSidebarLayout';
import OverviewPage from './pages/Overview/OverviewPage';
import PremisePage from './pages/Overview/Single/PremisePage';
import BuildingPage from './pages/Overview/Single/BuildingPage';
import FloorPage from './pages/Overview/Single/FloorPage';
import InfoPage from './pages/Admin/InfoPage';
import UsersPage from './pages/Admin/UsersPage';
import PaymentsPage from './pages/Admin/PaymentsPage';
import ProcessesPage from './pages/Overview/Multiple/ProcessesPage';
import FilesPage from './pages/Overview/Multiple/FilesPage';
import RoomsPage from './pages/Overview/Multiple/RoomsPage';
import FloorsPage from './pages/Overview/Multiple/FloorsPage';
import ItemsPage from './pages/Overview/Multiple/ItemsPage';
import SensorsPage from './pages/Overview/Multiple/SensorsPage';
import SensorPage from './pages/Overview/Single/SensorPage';
import RoomPage from './pages/Overview/Single/RoomPage';
import SkobaSidebar from './components/general/content/SkobaSidebar';
import AdminSidebar from './components/admin/content/AdminSidebar';
import CategoriesSidebar from './components/categories/content/CategoriesSidebar';
import BuildingPartsPage from './pages/Categories/BuildingPartsPage';
import FileCategoriesPage from './pages/Categories/FileCategoriesPage';
import ProcessCategoriesPage from './pages/Categories/ProcessCategoriesPage';
import PaddedContentSidebarLayout from './components/general/layouts/PaddedContentSidebarLayout';
import ItemPage from './pages/Overview/Single/ItemPage';
import ProcessPage from './pages/Overview/Single/ProcessPage';
import { csCZ as coreCsCZ } from '@mui/material/locale';
import { csCZ } from '@mui/x-date-pickers/locales';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function getPage(content : React.ReactElement) : React.ReactElement{
  return <Container 
    maxWidth={false}
    disableGutters={true}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {content}
  </Container>
}

function getPublicPage(content : React.ReactElement) : React.ReactElement{
  return <PublicLayout>
    {/* <React.StrictMode> */}
      {content}
    {/* </React.StrictMode>    */}
  </PublicLayout>
}
const theme = createTheme({
  typography: {
    "fontFamily": `"Heebo"`,
  },
  palette: {
    background: {
      default: "#ffffff",      
    },
    primary:{
      main: "#3b85b8",      
    },
    secondary:{
      main: "#4a4a4a",
    },
    error:{
      main: "#CC2626"
    }
  }},
  csCZ,
  coreCsCZ
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedLayout>
      <SidebarLayout
        level={0}
        sideBarContent={<SkobaSidebar/>}
      />
    </ProtectedLayout>,
    children: [
      {
        path: "overview",
        element: <OverviewSidebarLayout/>,
        children: [
          {
            path: "",
            loader: () => EstateService.getHierarchyData("Overview"),
            element: <OverviewPage/>
          },
          {
            path: "premises",
            element: <PremisesPage/>
          },
          {
            path: "premises/:id",
            element: <PremisePage/>,
          }, 
         
          {
            path: "buildings",
            element: <BuildingsPage/>
          },
          {
            path: "buildings/:id",
            element: <BuildingPage/>,
          },  
          {
            path: "floors",
            element: <FloorsPage/>
          }, 
          {
            path: "floors/:id",
            element: <FloorPage/>,
          },
          {
            path: "rooms",
            element: <RoomsPage/>
          },
          {
            path: "rooms/:id",
            element: <RoomPage/>,
          },    
          {
            path: "processes",
            element: <ProcessesPage/>
          },  
          {
            path: "processes/:id",
            element: <ProcessPage/>
          }, 
          {
            path: "files",
            element: <FilesPage/>
          }, 
          {
            path: "files/:id",
            // loader: ({params}) => HierarchyService.getHierarchyData("Files/" + params.id),
            element: <FilesPage/>
          },
          {
            path: "items",
            element: <ItemsPage/>
          },
          {
            path: "items/:id",
            element: <ItemPage/>
          },
          {
            path: "sensors",
            loader: () => EstateService.getHierarchyData("Sensors"),
            element: <SensorsPage/>
          },
          {
            path: "sensors/:id",
            loader: ({params}) => EstateService.getHierarchyData("Sensors/" + params.id),
            element: <SensorPage/>,
          }, 
        ]
      },
      {
        path: "categories",
        element: <PaddedContentSidebarLayout
          level={1}
          contentBackground='#e6e6e6'
          sideBarContent={<CategoriesSidebar/>}
        />,
        children: [
          {
            path: "buildingParts",
            element: <BuildingPartsPage/>
          },
          {
            path: "files",
            element: <FileCategoriesPage/>
          },
          {
            path: "processes",
            element: <ProcessCategoriesPage/>
          },
        ]
      },
      {
        path: "admin",
        element: <AdminLayout>
          <PaddedContentSidebarLayout
            level={1}
            contentBackground='#e6e6e6'
            sideBarContent={<AdminSidebar/>}
          />
        </AdminLayout>,
        children: [
          {
            path: "info",
            element: <InfoPage/>
          },
          {
            path: "users",
            element: <UsersPage/>
          },
          {
            path: "payments",
            element: <PaymentsPage/>
          },
        ]
      },
      {        
        path: "adamStarter",
        element: <AdamStarter/>,
      }
    ]
  },
  
  {
    path: "/signin",
    element: getPublicPage(getPage(<SignInPage/>))
  },
  {
    path: "/signup",
    element: getPublicPage(getPage(<SignUpPage/>))
  },
  {
    path: "/forgottenPassword",
    element: getPublicPage(getPage(<ForgottenPasswordPage/>))
  },
  {
    path: "/resetPassword",    
    element: getPublicPage(getPage(<ResetPasswordPage/>))
  },
  {
    path: "/changePassword",
    element: getPublicPage(getPage(<ProtectedLayout><ChangePasswordPage/></ProtectedLayout>))
  },
  {        
    path: "/payment/success",
    element: getPublicPage(getPage(<SuccessPage/>)),
  },
  {        
    path: "/payment/fail",
    element: getPublicPage(getPage(<FailPage/>)),
  }
]);


root.render(
  <ThemeProvider theme={theme} >
    <CssBaseline />
    {/* <StrictMode> */}
      <RouterProvider router={router} />
    {/* </StrictMode> */}
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
