import { IAPiResponse, IApiError } from "../../types/general/generalTypes";
import api from "./AxiosService";

const apiPost = (adress: string, item: any) : Promise<any> => {
    return api.instanceHierarchy.post(adress, item)
        .then((res) => {
        const response : IAPiResponse<any> = res.data;
        if (response.success) {
            return response.data;
        }
        else{
            throw response.error
        }
        })
        .catch((err) => {
        if(err.response.data === "")
            throw {code : err.response.status.toString(), message : [err.response.statusText]} as IApiError
        else{        
            throw err.response.data.error
        }
    });
};
const apiPostForm = (adress: string, item: any) : Promise<any> => {
    return api.instanceHierarchy.postForm(adress, item)
        .then((res) => {
        const response : IAPiResponse<any> = res.data;
        if (response.success) {
            return response.data;
        }
        else{
            throw response.error
        }
        })
        .catch((err) => {
        if(err.response.data === "")
            throw {code : err.response.status.toString(), message : [err.response.statusText]} as IApiError
        else{        
            throw err.response.data.error
        }
    });
};
const apiPutForm = (adress: string, item: any) : Promise<any> => {
    return api.instanceHierarchy.putForm(adress, item)
        .then((res) => {
        const response : IAPiResponse<any> = res.data;
        if (response.success) {
            return response.data;
        }
        else{
            throw response.error
        }
        })
        .catch((err) => {
        if(err.response.data === "")
            throw {code : err.response.status.toString(), message : [err.response.statusText]} as IApiError
        else{        
            throw err.response.data.error
        }
    });
};

const apiGet = (adress: string) : Promise<any> => {
    return api.instanceHierarchy.get(adress)
        .then((res) => {
        const response : IAPiResponse<any> = res.data;
        if (response.success) {
            return response.data;
        }
        else{
            throw response.error
        }
        })
        .catch((err) => {
        if(err.response.data === "")
            throw {code : err.response.status.toString(), message : [err.response.statusText]} as IApiError
        else{        
            throw err.response.data.error
        }
    });
};

const apiGetBlob = (adress: string) : Promise<any> => {
    return api.instanceHierarchy.get(adress, {
        responseType: 'blob',
    })
    .then((res) => {            
        if (res.status === 200) {
            return {filename: res.headers['gp-suggested-filename'], data: res.data, size: 0};
        }
        else{
            throw res.statusText
        }
    });
};




const apiPut = (adress: string, item: any) : Promise<void> => {
return api.instanceHierarchy.put(adress, item)
    .then((res) => {
    const response : IAPiResponse<void> = res.data;
    if (response.success) {
        return;
    }
    else{
        throw response.error
    }
    })
    .catch((err) => {
    if(err.response.data === "")
        throw {code : err.response.status.toString(), message : [err.response.statusText]} as IApiError
    else{        
        throw err.response.data.error
    }
    });
};
const apiDelete = (adress: string) : Promise<void> => {
return api.instanceHierarchy.delete(adress)
    .then((res) => {
    const response : IAPiResponse<void> = res.data;
    if (response.success) {
        return;
    }
    else{
        throw response.error
    }
    })
    .catch((err) => {
    if(err.response.data === "")
        throw {code : err.response.status.toString(), message : [err.response.statusText]} as IApiError
    else{        
        throw err.response.data.error
    }
    });
};

const ApiService = {
    apiGet,
    apiPost,
    apiPostForm,
    apiDelete,
    apiPut,
    apiPutForm,
    apiGetBlob 
};

export default ApiService;