import React, { useContext, useEffect, useState } from "react";
import HierarchySinglePage from "../../../components/general/pages/HierarchySinglePage";
import { useFetcher, useLoaderData, useLocation } from "react-router-dom";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import SensorValuesChart from "../../../components/general/charts/SensorValuesChart";
import SensorInfo from "../../../components/overview/itemInfo/hierarchy/SensorInfo";

export default function SensorPage(){    
    const { changeSideWindow, closeSideWindow, openHierarchy } = useContext(SideWindowContext);
    const [data, setData] = useState<any>(useLoaderData() as any);
    const fetcher = useFetcher();
    const location = useLocation();
    function reloadData(){
        fetcher.load(location.pathname);
    }
    useEffect(() => {
        if (fetcher.data) {
        setData(fetcher.data);
        }
    }, [fetcher.data]);
    useEffect(() => {
        openHierarchy();
        closeSideWindow();
    },[])
    return <HierarchySinglePage
            onRefresh={() => {}}
            refreshProps={[]}
            tabTitles={["Informace", "Graf"]} 
            title={data.name}
            getData={async () => data}
            refreshItself={false}
            reloadData={reloadData}
            onReceivedData={() => {}}
            onMount={() => {}}
        >
        <SensorInfo data={data}/>        
        <SensorValuesChart data={data.sensorValues}/>
    </HierarchySinglePage>
}