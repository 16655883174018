import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import ConfirmModal from "../../general/modals/ConfirmModal";
import FormModal from "../../general/modals/FormModal";
import SkobaTable, { ISkobaTableDataProps } from "../../general/tables/SkobaTable";
import UserForm from "../forms/UserForm";
import AdminService from "../../../services/admin/AdminService";


const UsersTableHeader = ["Jméno", "Přijmení", "Email"];

export default function UsersTable(props : ISkobaTableDataProps){
    return <SkobaTable
    data={props.data}
    header={UsersTableHeader}
    rowDefinition={
        {
            columnDefinitions: [{
                property: "name"
            },{
                property: "surname"
            },{
                property: "email"                
            }
            ],
            buttons: (object) => [
                <FormModal                         
                        title="Upravit uživatele"    
                        buttonElement={<AiFillEdit/>}
                        form={<UserForm model={object} buttonText={"Uložit změny"} onSubmit={AdminService.updateUser}/>}
                    /> ,
                <ConfirmModal                         
                    title="Smazat uživatele"  
                    color="error"  
                    openButtonInside={<BsFillTrashFill/>}
                    bodyText={`Opravdu chcete smazat uživatele ${object.name} ${object.surname}?`}
                    confirmButtonText="Smazat"
                    confirmColor="error"
                    onConfirm={() => AdminService.deleteUser(object.id)}
                /> 
            ]                
        }
    }
/>
}