import React, { ReactElement, useState } from "react";
import { Box, ListItem, ListItemButton, ListItemText, ListItemIcon, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { getMainColor } from "../../overview/Functions";
import SHeader from "../text/SHeader";

export const LargeIconSize = "40px"

interface ISidebarProps{
    header : ReactElement;
    color : string;
    items: ISidebarItem[];
    toggle?: boolean;
    getActive: (item : ISidebarItem) => boolean, 
}

export interface ISidebarItem{
    text: string,
    icon?: JSX.Element,
    layout?: ReactElement,
    to: string,
}
//redo sidebhar using this https://mui.com/material-ui/react-drawer/
export default function Sidebar(props : ISidebarProps): JSX.Element{
    const [active, setActive] = useState(true);
    const handleChange = () => {
      setActive((prev) => !prev);
    };
    return <Box sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      background: props.color,
      padding: "5px"
    }}>
      {props.toggle && <Switch checked={active} onChange={handleChange} />}
      {/* <Collapse orientation="horizontal" in={active} collapsedSize={100}>           */}
        <Box 
        display={active ? "initial" : "none"}
        >
          {props.header}
        </Box>
        <Box sx={{mt:"20px"}}>
          {props.items.map((v,k) =>{
              return <ListItem 
                key={k} 
                disablePadding 
                sx={{ 
                  display: 'block',
                  color: 'black',
                  fontWeight: 'bold',
                }}               
                component={Link} 
                to={v.to}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: active ? 'initial' : 'center',
                  px: 1,
                }}
              >
                {(v.icon && !active) && <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: active ? 1 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {v.icon}
                </ListItemIcon>}
                <ListItemText  primary={<SHeader bold text={v.text}/>} sx={{  display : active ? "inherit" : "none"}} />      
                {props.getActive(v) && <Box sx={{
                    position: "absolute",
                    left: "10px",
                    top: "80%",
                    transform: "translateY(-15%)",
                    width: "105%",
                    height: "4px",
                    backgroundColor: getMainColor(),              
                  }}/>
                }        
              </ListItemButton>
            </ListItem>
            })
          }
        </Box>        
      {/* </Collapse> */}
    </Box>
}