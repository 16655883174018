import { useLocation } from "react-router-dom";
import Sidebar from "../../general/content/Sidebar"
import STitle from "../../general/text/STitle"
import { BsBuilding } from "react-icons/bs";

const links = [{
    text: "Typy prostorů",
    to: "/categories/buildingParts",
    icon : <BsBuilding/>                
},
{
    text: "Soubory",
    to: "/categories/files",
    icon : <BsBuilding/>                
},
{
    text: "Činnosti",
    to: "/categories/processes",
    icon : <BsBuilding/>                
}
]

export default function CategoriesSidebar(){    
    const location = useLocation();
    return <Sidebar
        header={<STitle text="Funkce" />}
        items={links}
        getActive={(item) => location.pathname.includes(item.to)}
        color="#d9d9d9"
        toggle
    />
}