import * as React from "react";
import type { SVGProps } from "react";
const SvgArealy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M193 176h97v105h-97z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M205 188h23v20h-23zM216.5 188v20M251 188h23v20h-23zM262.5 188v20M56 205h23v20H56zM67.5 205v20M96 205h23v20H96zM107.5 205v20M56 237h23v20H56zM67.5 237v20M96 237h23v20H96zM107.5 237v20M205 217h23v20h-23zM216.5 217v20M251 217h23v20h-23zM262.5 217v20M228 248h26v33h-26zM241 248v33M205 272h14v5h-14zM265 272h14v5h-14zM60.5 272h14v5h-14zM100.5 272h14v5h-14z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "2.08px",
      }}
    />
    <path
      d="m241 134.5 71 41H170z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M233 139.153V36H119v130.5M13 281h291M40 194h95v87H40z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="m87.5 141 63.5 53H24zM119 74H64v86"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M135 48h16v16h-16zM143 48v16M169 48h16v16h-16zM177 48v16M203 48h16v16h-16zM211 48v16M135 77h16v16h-16zM143 77v16"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "2.08px",
      }}
    />
    <path
      d="M72 82h11v11H72zM77.5 82v11M93 82h11v11H93zM98.5 82v11M114 82h5v11h-5zM72 103h11v11H72zM77.5 103v11M93 103h11v11H93zM98.5 103v11M114 103h5v11h-5zM72 124h11v11H72zM77.5 124v11M93 124h11v11H93zM98.5 124v11M114 124h5v11h-5zM83 146H72v8.163M77.5 146v4.081M104 154.721V146H93M98.5 146v4.081M114 146h5v11h-5z"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "1.43px",
      }}
    />
    <path
      d="M169 77h16v16h-16zM177 77v16M203 77h16v16h-16zM211 77v16M135 108h16v16h-16zM143 108v16M169 108h16v16h-16zM177 108v16M203 108h16v16h-16zM211 108v16M135 141h16v16h-16zM143 141v16M169 141h16v16h-16zM177 141v16M219 147.129V141h-16v16M211 141v10M143 187h8v-16h-16v10.093M143 171v16M177 171h-8v16h16v-11.199M177 175v12M135 204h16v16h-16zM143 204v16M169 204h16v16h-16zM177 204v16M135 235h16v16h-16zM143 235v16M169 235h16v16h-16zM177 235v16"
      style={{
        fill: "none",
        stroke: "#262626",
        strokeWidth: "2.08px",
      }}
    />
  </svg>
);
export default SvgArealy;
