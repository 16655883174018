import { Box, Checkbox, FormControlLabel, Grid, Slider, TextField, Typography } from "@mui/material"
import { IItemProperty } from "../../../../types/overview/itemTypes"
import { useState } from "react";

export interface IItemFilterFieldProps{
    property: IItemProperty
}

export default function ItemFilterField(props: IItemFilterFieldProps){
    const [sliderValue1, setSliderValue1] = useState<number[]>([10, 100]);
    switch(props.property.dataType){
        case  "Celé číslo":
        case "Poměr (procenta)":
        case "Fyzikální veličina (s jednotkou)":
            return <Box>
                <Typography gutterBottom>
                    {props.property.name}
                </Typography>
                <Grid container spacing={2}>
                <Grid item xs={2}>
                    {sliderValue1[0]}
                </Grid>
                <Grid item xs={8}>
                    <Slider
                        value={sliderValue1}
                        onChange={(event: Event, newValue: number | number[]) => setSliderValue1(newValue as number[])}
                        valueLabelDisplay="auto"
                        min={10}
                        max={200}
                    />
                </Grid>
                <Grid item xs={2}>
                    {sliderValue1[1]}
                </Grid>
                </Grid>
            </Box>
        case "Textová hodnota (výčet: CZ_CrossSectionType)":
        case "Textová hodnota":
        case "Textová hodnota (výčet: CZ_RetainingWallType)":
            return <TextField                
                fullWidth
                id="name"
                name="name"
                label={props.property.name}
                size="small"
            />
        case "IfcBoolean":
            return <FormControlLabel
                value="top"
                control={<Checkbox />}
                label={props.property.name}
                labelPlacement="top"
            />
        default:
            return <div></div>
    }
}