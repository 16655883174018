import * as React from "react";
import type { SVGProps } from "react";
const SvgCidla = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 320 320"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 0h320v320H0z"
      style={{
        fill: "none",
        stroke: "#333",
        strokeWidth: "4.17px",
      }}
    />
    <circle
      cx={160.614}
      cy={160.008}
      r={10.979}
      style={{
        fill: "#4d4d4d",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M177.529 139.124c6.078 4.93 9.965 12.457 9.965 20.884 0 8.055-3.551 15.287-9.172 20.216"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M186.05 127.796c9.509 7.521 15.614 19.159 15.614 32.212 0 12.38-5.492 23.487-14.17 31.016M176.929 138.52l8.878-10.724M178.279 180.224l8.824 10.988"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="m200.35 147.051-.004 26.133c-4.798 3.137-7.13 14.655-10.529 15.394-11.22 2.441-12.755-7.291-2.324-21.929l.002-13.066c-14.142-15.93-7.391-26.051 1.615-22.844 4.047 1.441 6.937 11.026 11.24 16.312"
      style={{
        fill: "#4d4d4d",
      }}
    />
    <path
      d="M200.378 110.234c13.438 11.645 21.943 28.833 21.943 47.992 0 19.338-8.664 36.668-22.319 48.316"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M215.744 91.706c19.916 16.099 32.66 40.724 32.66 68.302 0 27.218-12.414 51.559-31.883 67.667M200.346 110.234l14.995-18.113M200.002 207.275l16.147 20.108"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M216.448 91.881c11.765 8.336 30.053 39.597 31.418 57.38q.215 2.81.299 5.512c1.212 38.707-17.166 61.386-31.64 71.603l-16.175-17.818c27.603-31.069 29.995-63.479 1.314-97.542z"
      style={{
        fill: "#4d4d4d",
      }}
    />
    <path
      d="M234.392 69.304c26.331 21.45 43.161 54.127 43.161 90.704 0 36.282-16.56 68.727-42.527 90.183"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M255.488 43.886c33.268 27.728 54.454 69.47 54.454 116.122 0 46.643-21.178 88.378-54.435 116.106M255.048 44.16l-20.022 24.184M235.026 250.891l20.022 24.932"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M255.488 43.886c11.464 12.536 23.645 23.659 31.607 36.159 34.771 54.585 33.472 110.064-.981 162.143-7.763 11.733-19.875 22.009-31.066 33.635l-20.022-24.932c57.606-56.202 58.511-121.648 1.007-181.365z"
      style={{
        fill: "#4d4d4d",
      }}
    />
    <path
      d="M142.471 180.876c-6.078-4.93-9.965-12.457-9.965-20.884 0-8.055 3.551-15.287 9.172-20.216"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M133.95 192.204c-9.509-7.521-15.614-19.159-15.614-32.212 0-12.38 5.492-23.487 14.17-31.016M143.071 181.48l-8.878 10.724M141.721 139.776l-8.824-10.988"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="m119.65 172.949.004-26.133c4.798-3.137 7.13-14.655 10.529-15.394 11.22-2.441 12.755 7.291 2.324 21.929l-.002 13.066c14.142 15.93 7.391 26.051-1.615 22.844-4.047-1.441-6.937-11.026-11.24-16.312"
      style={{
        fill: "#4d4d4d",
      }}
    />
    <path
      d="M119.622 209.766c-13.438-11.645-21.943-28.833-21.943-47.992 0-19.338 8.664-36.668 22.319-48.316"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M104.256 228.294c-19.916-16.099-32.66-40.724-32.66-68.302 0-27.218 12.414-51.559 31.883-67.667M119.654 209.766l-14.995 18.113M119.998 112.725l-16.147-20.108"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M103.552 228.119c-11.765-8.336-30.053-39.597-31.418-57.38-3.231-42.08 16.193-66.422 31.341-77.115l16.175 17.818c-27.603 31.069-29.995 63.479-1.314 97.542z"
      style={{
        fill: "#4d4d4d",
      }}
    />
    <path
      d="M85.608 250.696c-26.331-21.45-43.161-54.127-43.161-90.704 0-36.282 16.56-68.727 42.527-90.183"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M64.512 276.114c-33.268-27.728-54.454-69.47-54.454-116.122 0-46.643 21.178-88.378 54.435-116.106M64.952 275.84l20.022-24.184M84.974 69.109 64.952 44.177"
      style={{
        fill: "none",
        stroke: "#4d4d4d",
        strokeWidth: "4.17px",
      }}
    />
    <path
      d="M64.512 276.114c-11.464-12.536-23.645-23.659-31.607-36.159-34.771-54.585-33.472-110.064.981-162.143 7.763-11.733 19.875-22.009 31.066-33.635l20.022 24.932c-57.606 56.202-58.511 121.648-1.007 181.365z"
      style={{
        fill: "#4d4d4d",
      }}
    />
  </svg>
);
export default SvgCidla;
