import React, { useContext, useEffect } from "react";
import { SideWindowType } from "../../../components/overview/content/OverviewSplitManager";
import HierarchyMultipleFilterPage from "../../../components/general/pages/HierarchyMultipleFilterPage";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import SingleTextFilter from "../../../components/overview/filters/SingleTextFilter";
import SensorsTable from "../../../components/overview/tables/hierarchy/SensorsTable";
import { useNavigate } from "react-router-dom";

export default function SensorsPage(){
    const {changeSideWindow, closeSideWindow, openHierarchy, chartObj, controlMap, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    useEffect(() => {
        changeSideWindow(SideWindowType.ChartManager)
        openHierarchy();
        closeSideWindow();
    },[]);
    return <HierarchyMultipleFilterPage
        refreshProps={[controlMap, localMap, chartObj]}
        onRefresh={() => {
            changeSideWindow(SideWindowType.ControlMap)
            openHierarchy();
            closeSideWindow();
        }}
        getFilters={(data, onChange) => 
            <SingleTextFilter 
                data={data} 
                onChange={onChange} 
                filterProperty="name"
                labelText="Hledat dle jména čidla"/> 
        }
        getChildrenTable={(data) => <SensorsTable data={data}/>}
        modifyButtons={[]}
        onMount={() => {
            changeSideWindow(SideWindowType.ControlMap)
            openHierarchy();
            closeSideWindow();
        }}
        onReceivedData={(data) => {}}
        title="Čidla"
    />    
  }