import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleTextFilter from "../SingleTextFilter";
import ItemFilterModal from "./ItemFilterModal";
import { IItemType } from "../../../../types/overview/itemTypes";
import ItemsServiceNew from "../../../../services/items/ItemsService";

export interface IItemFilterProps{
    data: any[]
}

export default function ItemFilter(props : IItemFilterProps){
    const [typeFilter, setTypeFilter] = useState<number>(0);
    const [parentTypeFilter, setParentTypeFilter] = useState<number>(0);
    const [types, setTypes] = useState<IItemType[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [error, setError] = useState(false);
    const [errorObject, setErrorObject] = useState<any>(null);
    const [errorFilters, setErrorFilters] = useState<any>(null);

    
    useEffect(() => {
        loadData();
    }, [])
    
    function loadData(){
        const typesPromise = ItemsServiceNew.getTypes();
        Promise.all([typesPromise])
        .then(
            ([data1]) => {
                setLoaded(true);
                setLoadedData(true);
                setTypes(data1);
            },
            (error) => {
                setLoaded(true);
                setLoadedData(true);
                setError(true);
                setErrorObject(error);
                setErrorFilters(error);
            }
        )
    }
    function onFilterChange(data: any[]){
        // setFilteredData(data);
    }
    return <React.Fragment>
        <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
                <SingleTextFilter
                    data={props.data} 
                    labelText="Hledat dle jména prvku"
                    filterProperty="name"
                    onChange={onFilterChange} 
                />
            </Grid>
                <Grid item xs={12}><Grid container spacing={2} rowSpacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="parent-type-category-filter">Kategorie</InputLabel>
                            <Select                     
                                variant="filled"   
                                labelId="parent-type-category-filter"
                                label="Kategorie" 
                                fullWidth
                                value={parentTypeFilter.toString()}
                                defaultValue={""}
                                onChange={(event) => {
                                    setParentTypeFilter(parseInt(event.target.value));
                                    setTypeFilter(0);
                                }}
                            >
                                <MenuItem value={"0"}>Všechny kategorie</MenuItem>
                                {types.filter(x => x.parentTypeId === null).map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                            </Select>
                        </FormControl> 
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="type-category-filter">Typ</InputLabel>
                            <Select                     
                                variant="filled"   
                                labelId="type-category-filter"
                                label="Typ" 
                                fullWidth
                                value={typeFilter.toString()}
                                defaultValue={""}
                                onChange={(event) => {
                                    setTypeFilter(parseInt(event.target.value));
                                }}
                            >
                                <MenuItem value={"0"}>Všechny typy</MenuItem>
                                {types.filter(x => x.parentTypeId === parentTypeFilter).map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                            </Select>
                        </FormControl> 
                    </Grid>
                    <Grid item xs={3}>                
                        <ItemFilterModal/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}