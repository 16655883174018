import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import { IUser } from "../../../types/admin/adminTypes";
import AdminService from "../../../services/admin/AdminService";
import { GError, Loading } from "../../general/layouts/Components";
import { RefreshDataContext } from "../../general/contexts/RefreshDataContext";
import TabLayout from "../../general/layouts/TabLayout";
import UsersTable from "../tables/UsersTable";
import STitle from "../../general/text/STitle";
import UsersInfo from "../itemInfo/UsersInfo";

export default function UsersAdmin() {
    const [loaded, setLoaded] = useState(false);    
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<IUser[]>([]);
    
    useEffect(() => getData(), [])
    function getData(){
        AdminService.getUsers()
        .then(
            (result) => {
                setLoaded(true);
                setData(result as IUser[]);
            },
            (error) => {
                setLoaded(true);
            setError(error);
            }
        )
    }
    function getUsersTable(filter: string) : JSX.Element{
        return <UsersTable 
            data={data.filter((item) => item.role === filter)}            
        />
    }
    if (error)
        return <GError error={error.message}/>
    else if (!loaded)
        return <Loading/>;
    else {
        return (<React.Fragment>            
            <RefreshDataContext.Provider value={getData}>
                <Box sx={{p:2}}>
                    <STitle text="Uživatelé"/>
                    <TabLayout titles={["Informace", "Vlastníci", "Administrátoři", "Editoři", "Běžní uživatelé"]}>
                        <UsersInfo data={{
                            count: data.length, 
                            owners: data.filter(x => x.role === "Owner").length, 
                            admins: data.filter(x => x.role === "Administrator").length, 
                            editors: data.filter(x => x.role === "Editor").length, 
                            users: data.filter(x => x.role === "User").length}}
                        />
                        {getUsersTable("Owner")}
                        {getUsersTable("Administrator")}
                        {getUsersTable("Editor")}
                        {getUsersTable("User")}                    
                    </TabLayout>
                </Box>
            </RefreshDataContext.Provider>
        </React.Fragment>
    )}
}