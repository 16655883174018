import { PropsWithChildren } from "react";
import React from "react";
import { Box } from "@mui/material";
import TabLayout from "../layouts/TabLayout";
import STitle from "../text/STitle";
import { SkobaEntityType } from "../../../types/overview/skobaTypes";
import HierarchyHeader from "./HierarchyHeader";

export interface IHierarchySingleProps{
    tabTitles: string[];
    title: string;
    id: number;
    type: SkobaEntityType;
}

export default function HierarchySinglePageNew(props : PropsWithChildren<IHierarchySingleProps>) {
    return <Box sx={{p:1, height:"100%", '& > *': { mb: 2 }, overflow: "auto"}}>
        <HierarchyHeader title={props.title} id={props.id} type={props.type}/>
        <TabLayout titles={props.tabTitles}>
            {props.children}
        </TabLayout>                  
    </Box> 
}