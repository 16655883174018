import { Outlet } from 'react-router-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';

export interface ISidebarLayoutProps{
  sideBarContent : React.ReactElement;
  contentBackground? : string;
  level: number;

}

export function SidebarLayout(props : PropsWithChildren<ISidebarLayoutProps>) : React.ReactElement {
  function getZIndex(){
    return 10 - props.level;
  }
  return <Container maxWidth={false}
      disableGutters={true}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          width: "100%"
        }}
      >
        <Box sx={{
          height:"100%",
          boxShadow: "5px 5px 10px -5px rgba(0,0,0,0.7);",
          zIndex: getZIndex(),
          }}>
          {props.sideBarContent}
        </Box>
        <Box 
          flexGrow={1}
        sx={{
          marginTop: "10px",
          background: props.contentBackground ? props.contentBackground : "inherit"
          }}>
            <Box sx={{
            height:"100%",
            display:"100%",
            }}>
            {props.children ? props.children : <Outlet/> }
          </Box>
        </Box>
  </Container>    
}

export default SidebarLayout;