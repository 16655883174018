import axios from "axios";
import TokenService from "./TokenService";

const instanceHierarchy = axios.create({
	baseURL: process.env.REACT_APP_REST_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

// const navigate = useNavigate();

instanceHierarchy.interceptors.request.use(
	(config) => {
		const token = TokenService.getLocalAccessToken();
		if (token) {
		config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
		//   config.headers["x-access-token"] = token; // for Node.js Express back-end
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
instanceHierarchy.interceptors.response.use(
	(res) => {
		return res;
	},	
	async (err) => {
		const originalConfig = err.config;
		if (err.response.status === 401 && !originalConfig._retry) {
		  originalConfig._retry = true;
		  try {
			const request = await axios({
				method: "POST",
				url: `${process.env.REACT_APP_REST_API_URL}/auth/refresh-token`,
				withCredentials: true,
				headers: { "Content-Type": "application/json" },
				data: {
					accessToken: TokenService.getLocalAccessToken(),
					refreshToken: TokenService.getLocalRefreshToken(),
				}
			})
			const data = request.data.data;
			TokenService.updateLocalAccessToken(data.accessToken);
			TokenService.updateLocalRefreshToken(data.refreshToken);
			return instanceHierarchy(originalConfig);
		  } catch (_error) {
			TokenService.removeUser();
			window.location.href = "/signin";
			return Promise.reject(_error);
		  }
		}
		return Promise.reject(err);
	}
);
const AxiosService = {
	instanceHierarchy
};

export default AxiosService;