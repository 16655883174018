import { Typography } from "@mui/material";

export interface ISTitleProps{
    text : string;
    bold?: boolean;
}

export default function SHeader(props: ISTitleProps){
    return <Typography
            color="inherit"
            fontWeight={props.bold ? "bold" : "normal"}
            >
           {props.text.toLocaleUpperCase()}
    </Typography>
}