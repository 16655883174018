import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ISelect, ISelectBetter } from "../../../../types/overview/skobaTypes";
import { IItem, IItemType } from "../../../../types/overview/itemTypes";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import ItemsServiceNew from "../../../../services/items/ItemsService";
import ItemsSummary from "../../summary/ItemsSummary";
import ItemsTable from "../../tables/other/ItemsTable";
import ItemFilter from "../../filters/Items/ItemFilter";
import FormModal from "../../../general/modals/FormModal";
import ItemForm from "../../forms/items/ItemForm";

export interface IItemsHierarchyProps{
    location?: ISelectBetter;
    getData : () => Promise<IItem[]>;
}

export default function ItemsHierarchy(props: IItemsHierarchyProps){
    const { closeSideWindow, openHierarchy} = useContext(SideWindowContext);
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [types, setTypes] = useState<IItemType[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [error, setError] = useState(false);
    const [errorObject, setErrorObject] = useState<any>(null);
    const [errorFilters, setErrorFilters] = useState<any>(null);
    const [typeFilter, setTypeFilter] = useState<number>(0);
    const [parentTypeFilter, setParentTypeFilter] = useState<number>(0);

    useEffect(() => {
        loadData();         
        openHierarchy();
        closeSideWindow();
    }, [])

    function loadData(){
        const typesPromise = ItemsServiceNew.getTypes();
        const dataPromise = props.getData();
        Promise.all([typesPromise, dataPromise])
        .then(
            ([data1, data2]) => {
                setLoaded(true);
                setLoadedData(true);
                setData(data2);
                setTypes(data1);
            },
            (error) => {
                setLoaded(true);
                setLoadedData(true);
                setError(true);
                setErrorObject(error);
                setErrorFilters(error);
            }
        )
    }
    function onFilterChange(data: any[]){
        setFilteredData(data);
    }   
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={error} loading={!loadedData}>
            <Box sx={{p:0, '& > *': { mb: 2 }}}>
                <ItemFilter data={data}/>
                <ItemsTable data={data}/>                        
                <ItemsSummary data={filteredData}/>
               {props.location && <FormModal 
                        buttonText="Vytvořit prvek"
                        title="Vytvořit nový prvek"    
                        form={<ItemForm buttonText="Vytvořit" location={props.location} onSubmit={ItemsServiceNew.createItem}/>}
                        />}
            </Box>            
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }