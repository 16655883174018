import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface IDualTextFilterProps{
    onChange: (data : any[]) => void;
    labelTextFirst: string;
    labelTextSecond: string;
    data: any[];
    filterPropertyFirst: string;
    filterPropertySecond: string;
}

export default function DualTextFilter(props : IDualTextFilterProps){    
    const [searchTextFirst, setSearchTextFirst] = useState("");
    const [searchTextSecond, setSearchTextSecond] = useState("");

    useEffect(() => {
        props.onChange(
            props.data.filter((item) => 
            item[props.filterPropertyFirst].toLocaleLowerCase().includes(searchTextFirst.toLocaleLowerCase())
            && 
            item[props.filterPropertySecond].toLocaleLowerCase().includes(searchTextSecond.toLocaleLowerCase())
        ));
    }, [searchTextFirst, searchTextSecond, props.data]) 

    return <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={6}>
            <TextField
                variant="filled"
                fullWidth
                id="name"
                name="name"
                label={props.labelTextFirst}
                value={searchTextFirst}onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchTextFirst(event.target.value);
                }}
            />
        </Grid>   
        <Grid item xs={6}>
            <TextField     
                variant="filled"
                fullWidth
                id="name"
                name="name"
                label={props.labelTextSecond}
                value={searchTextSecond}onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchTextSecond(event.target.value);
                }}
            />
        </Grid>            
    </Grid>
}