import { Point } from "ol/geom";
import React, { useContext, useEffect, useState } from "react";
import { IVectorData, IVectorLayer } from "../../../general/maps/ControlMap";
import { getBuildingStyle } from "../../../general/maps/ControlMapStyles";
import { Feature } from "ol";
import EstateService from "../../../../services/overview/EstateService"
import FormModal from "../../../general/modals/FormModal";
import BuildingForm from "../../forms/hierarchy/BuildingForm";
import { useNavigate } from "react-router-dom";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import SingleTextFilter from "../../filters/SingleTextFilter";
import BuildingsTable from "../../tables/hierarchy/BuildingsTable";
import { SideWindowType } from "../OverviewSplitManager";
import { IBuildingDetailed } from "../../../../types/overview/estateTypes";
import HierarchyFiltersMultipleNew from "../../../general/content/HierarchyFiltersMultipleNew";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import BuildingsSummary from "../../summary/BuildingsSummary";

export interface IBuildingsHierarchyProps{
    parentId? : number;
    getData : () => Promise<any[]>;
    keepZoom?: boolean;
}

export default function BuildingsHierarchy(props: IBuildingsHierarchyProps){    
    const { changeSideWindow, openSideWindow, openHierarchy, controlMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IBuildingDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        changeSideWindow(SideWindowType.ControlMap)
        openHierarchy();
        openSideWindow();
    }, [])

    useEffect(() => {
        if(data && controlMap){
            const vectorLayers = [] as IVectorLayer[];
            const features = data.filter(x=> x.xDef && x.yDef).map(x =>
                {
                    return {
                        feature: new Feature({
                            geometry: new Point([x.xDef as number,x.yDef as number]),
                            type: "Building",
                            name: x.name,
                            id: x.id

                        }),
                        onClick: () => {
                            console.log(x.id)
                            navigate("/overview/buildings/" + x.id)
                        },
                        onLeave: () => {console.log("leave")},
                        onEnter: () => {console.log("enter")},
                    } as IVectorData
                }
            
            );        
            vectorLayers.push({layers: features, style: getBuildingStyle()});
            controlMap?.setVectorData(vectorLayers);
            if(!props.keepZoom)
                controlMap.center();
        }
    }, [data, controlMap])

    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            <HierarchyFiltersMultipleNew
                data={data}
                getChildrenTable={(data) => <BuildingsTable data={data}/>}
                getFilters={(data, onChange) => 
                    <SingleTextFilter 
                        data={data} 
                        onChange={onChange} 
                        filterProperty="name"
                        labelText="Hledat dle názvu budovy"/> 
                }
                modifyButtons={[
                    <FormModal 
                        buttonText="Vytvořit budovu"
                        title="Vytvořit novou budovu"    
                        form={<BuildingForm parent={props.parentId ? props.parentId : null} 
                            buttonText="Vytvořit" model={null} 
                            selectable={!props.parentId} 
                            onSubmit={EstateService.createBuilding}/>
                        }
                    />
                ]}
                />
            <BuildingsSummary data={data}/>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }