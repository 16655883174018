import { AiFillDelete } from "react-icons/ai";
import ConfirmModal from "./ConfirmModal";

export interface IDeleteModalProps{    
    itemName: string;
    buttonText?: string;
    size?: "small" | "medium" | "large";
    onConfirm: (() => Promise<void>);
}
  

export default function DeleteModal(props: IDeleteModalProps){
    return <ConfirmModal 
                size={props.size}
                title="Potvrzení" 
                bodyText={"Opravdu chtete smazat: " + props.itemName + "?"}
                confirmButtonText="Smazat" 
                openButtonInside={props.buttonText ? <span>{props.buttonText}</span>: <AiFillDelete/>} 
                confirmColor="error" 
                color="error" 
                onConfirm={props.onConfirm}/>
}