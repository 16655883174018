import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../../../services/overview/EstateService";
import { SideWindowType } from "../OverviewSplitManager";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import FormModal from "../../../general/modals/FormModal";
import { SkobaEntityType, ISelect, ISelectHierarchical } from "../../../../types/overview/skobaTypes";
import { IRoomDetailed } from "../../../../types/overview/estateTypes";
import RoomsTable from "../../tables/hierarchy/RoomsTable";
import TwoTextFilter from "../../filters/DualTextFilter";
import RoomForm from "../../forms/hierarchy/RoomForm";
import HierarchyFiltersMultipleNew from "../../../general/content/HierarchyFiltersMultipleNew";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";
import FloorsSummary from "../../summary/FloorsSummary";
import { prepareWFSLink } from "../../../general/maps/MapFunctions";
import RoomsSummary from "../../summary/RoomsSummary";


export interface IRoomsHierarchyProps{
    premiseOption?: ISelect;
    buildingOptions?: ISelect;
    floorOptions?: ISelect; 
    showLocalMap?: boolean;
    getData : () => Promise<any[]>;
}

export default function RoomsHierarchy(props: IRoomsHierarchyProps){
    const { changeSideWindow, openSideWindow, closeSideWindow, openHierarchy, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();

    const [data, setData] = useState<IRoomDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        if(props.showLocalMap){            
            changeSideWindow(SideWindowType.LocalMap)
            openHierarchy();
            openSideWindow();
        }
        else{            
            openHierarchy();
            closeSideWindow();
        }
    }, [])
    
    useEffect(() => {
        if(props.showLocalMap)
            setMapVectorData()
    }, [data, localMap])
    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(props.buildingOptions && localMap){            
            localMap?.setWfsLayers(prepareWFSLink(props.buildingOptions?.id, SkobaEntityType.Building));            
        }
    } 

    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            <HierarchyFiltersMultipleNew
                data={data}
                getChildrenTable={(data) => <RoomsTable data={data} parentBuilding={props.buildingOptions}/>}
                getFilters={(data, onChange) => 
                    <TwoTextFilter 
                        data={data} 
                        onChange={onChange} 
                        labelTextFirst="Hledat dle jména"
                        labelTextSecond="Hledat dle čísla"
                        filterPropertyFirst="name"
                        filterPropertySecond="number"
                    /> 
                }
                modifyButtons={(props.premiseOption || props.buildingOptions || props.floorOptions) ? [
                    <FormModal 
                        buttonText="Vytvořit prostor"
                        title="Vytvořit novou prostor"    
                        form={<RoomForm model={null}                         
                            selectable={false}
                            buttonText="Vytvořit"
                            onSubmit={EstateService.createRoom}
                            premiseOption={props.premiseOption}
                            buildingOptions={props.buildingOptions}
                            floorOptions={props.floorOptions}
                        />}
                    /> 
                ] : []}
                />
            <RoomsSummary data={data}/>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }