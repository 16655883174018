import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideWindowContext } from "../../../general/contexts/SideWindowContext";
import SingleTextFilter from "../../filters/SingleTextFilter";
import SensorsTable from "../../tables/hierarchy/SensorsTable";
import { ISelect } from "../../../../types/overview/skobaTypes";
import { ISensorDetailed } from "../../../../types/overview/estateTypes";
import HierarchyFiltersMultipleNew from "../../../general/content/HierarchyFiltersMultipleNew";
import LoadingWrapper from "../../../general/content/LoadingWrapper";
import { RefreshDataContext } from "../../../general/contexts/RefreshDataContext";


export interface ISensorsHierarchyProps{
    parent: ISelect;    
    getData : () => Promise<any[]>;
}

export default function SensorsHierarchy(props: ISensorsHierarchyProps){    
    const { changeSideWindow, openSideWindow, closeSideWindow, openHierarchy, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<ISensorDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        openHierarchy();
        closeSideWindow();
    }, [])

    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }

    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            <HierarchyFiltersMultipleNew
                data={data}
                getChildrenTable={(data) => <SensorsTable data={data}/>}
                getFilters={(data, onChange) => 
                    <SingleTextFilter 
                        data={data} 
                        onChange={onChange} 
                        filterProperty="name"
                        labelText="Hledat dle názvu čidla"/> 
                }
                modifyButtons={[]}
            />
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }