import React, { useContext } from 'react';
import { useFormik } from 'formik';
 import * as Yup from 'yup';
 import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { IUser } from '../../../types/admin/adminTypes';
import { CloseFormContext } from '../../general/contexts/CloseFormContext';
import { RefreshDataContext } from '../../general/contexts/RefreshDataContext';
 
 const UserValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    surname: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    email: Yup.string()
        .max(100, 'Too Long!')
        .email('Email není ve správném formátu')
        .required('Email nemůže být prázdný'),
    role: Yup.string()
        .nullable()
        
});

export interface IUserFormProps{
    model?: IUser;
    buttonText : string;
    onSubmit: (values : any) => Promise<any>;
}

export default function UserForm(props : IUserFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext);
  
    const [error, setError] = React.useState("");
    const [submitting, setSubmit] = React.useState(false);
    const handleSubmit = async (data : any) => {
        await props.onSubmit(data)
        .then((res) =>{
            setSubmit(false);
            handleClose();
            handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
      })
    };
    const possibleRoles = [
        {
            name: "Vlastník",
            id: "Owner"
        },
        {
            name: "Administrátor",
            id: "Administrator"
        },
        {
            name: "Editor",
            id: "Editor"
        },
        {
            name: "Uživatel",
            id: "User"
        },
    ]
    

    const formik = useFormik({
        initialValues: props.model === undefined ? {
            name: '',
            surname: "",
            role: "User",
            email: ""
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            surname: props.model.surname,
            role: props.model.role,
            email: props.model.email
        },
        validationSchema: UserValidationSchema,
        onSubmit: handleSubmit,
    });
    function getParentSelect() : JSX.Element{   
        return <FormControl fullWidth>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
                fullWidth
                labelId="role-label"
                id="role"
                name="role"
                label="Role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
            >
            {
                possibleRoles.map((v,k) =>{
                    return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                })
            }
            </Select>
      </FormControl>
    }
    return <div>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { mb: 1.5},
            }}
            onSubmit={formik.handleSubmit}
        >
        <TextField                
            fullWidth
            id="name"
            name="name"
            label="Jméno"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
        />   
        <TextField                
            fullWidth
            id="surname"
            name="surname"
            label="Přijmení"
            value={formik.values.surname}
            onChange={formik.handleChange}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
        />   
        <TextField                
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            disabled={props.model != null}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
        />       
        {getParentSelect()}       
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
            {props.buttonText}
        </Button>
        {error !== "" && 
            <Typography component="i" color="red">
                Při zpracovávání vašeho požadavku došlo k chybě: {error}
            </Typography>                  
        }
    </Box>
    </div>    
}