import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RefreshDataContext } from '../contexts/RefreshDataContext';
import { Button, Typography } from '@mui/material';
import { EditorLayout } from '../layouts/Layouts';

export interface IBaseModalProps{
  openButtonInside: JSX.Element;
  color? : 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant? : 'text' | 'outlined' | 'contained';
  size? : 'small' | 'medium' | 'large';
  title: string;
  confirmButtonText: string;
  confirmColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export interface IConfigModalProps extends IBaseModalProps{  
  bodyText: string;  
  onConfirm: (() => Promise<void>);
}

export default function ConfirmModal(props: IConfigModalProps) {
  const [show, setShow] = useState(false);
  const [error, setError] = React.useState("");
  const [submitting, setSubmit] = React.useState(false);
  const handleRefreshData = useContext(RefreshDataContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function handleConfirm(){
    props.onConfirm()
    .then(() =>{
      setSubmit(false);
      handleClose();
      handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
    })
    
  }
  return (
    <EditorLayout>        
      <Button color={props.color ? props.color : "primary"} 
          variant={props.variant ? props.variant : "contained"} 
          onClick={handleShow}
          size={props.size ? props.size : "medium"}>
        {props.openButtonInside}
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.bodyText}
          {error !== "" && 
            <Typography component="i" color="red">
            Při zpracovávání vašeho požadavku došlo k chybě: {error}
            </Typography>                  
          }  
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button color={props.confirmColor} onClick={handleConfirm}>
            {props.confirmButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </EditorLayout>
  );
}
