import React, { useContext } from "react";
import EstateService from "../../../services/overview/EstateService";
import { useNavigate } from "react-router-dom";
import { SideWindowContext } from "../../../components/general/contexts/SideWindowContext";
import ProcessesHierarchy from "../../../components/overview/content/multiple/ProcessesHierarchy";
import { Box } from "@mui/material";
import STitle from "../../../components/general/text/STitle";

export default function ProcessesPage(){
    const { changeSideWindow, openSideWindow, openHierarchy, chartObj, controlMap, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    return <React.Fragment>
        <Box sx={{p:2, height:"100%", '& > *': { mb: 2 }}}>
            <Box sx={{mb:2}}>
                <STitle text={"Činnosti"}/>
            </Box>
            <ProcessesHierarchy getData={() => EstateService.getHierarchyData("Processes")} />            
        </Box>
    </React.Fragment>
    
  }