import React, { useContext } from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup';
import { ISelect } from '../../../../types/overview/skobaTypes';
import { IFloor } from "../../../../types/overview/estateTypes";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CloseFormContext } from '../../../general/contexts/CloseFormContext';
import { RefreshDataContext } from '../../../general/contexts/RefreshDataContext';
 
 const FloorValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Too Long!')
        .required('Jméno nemůže být prázdné'),
    building: Yup.number()
        .nullable(),
    area: Yup.number()
        .nullable()
        
 });

 export interface IFloorFormProps{
    model: IFloor | null;
    buttonText : string;
    selectOptions : ISelect[];
    selectable: boolean;
    onSubmit: (values : any) => Promise<any>;
}

export default function FloorForm(props : IFloorFormProps) {
    const handleClose = useContext(CloseFormContext);
    const handleRefreshData = useContext(RefreshDataContext);
  
    const [error, setError] = React.useState("");
    const [submitting, setSubmit] = React.useState(false);
    const handleSubmit = async (data : any) => {
        await props.onSubmit(data)
        .then((res) =>{
            setSubmit(false);
            handleClose();
            handleRefreshData();
      }).catch(err => {
        setError(err.messages.join(",\n"));
      })
    };
    const formik = useFormik({
        initialValues: props.model === null ? {
            name: '',
            buildingId: props.selectOptions[0].id,
            area: '',
        } : 
        {
            id: props.model.id,
            name: props.model.name,
            buildingId: props.model.buildingId,
            area: props.model.area
        },
        validationSchema: FloorValidationSchema,
        onSubmit: handleSubmit,
    });
    function getParentSelect() : JSX.Element{   
        return <FormControl fullWidth>
            <InputLabel id="building-parent-label">Budova</InputLabel>
            <Select
                fullWidth
                labelId="building-parent-label"
                id="building"
                name="building"
                label="Budova"
                value={formik.values.buildingId}
                onChange={formik.handleChange}
                error={formik.touched.buildingId && Boolean(formik.errors.buildingId)}
                disabled={!props.selectable || submitting}
            >
            {
                props.selectOptions.map((v,k) =>{
                    return <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
                })
            }
            </Select>
      </FormControl>
    }
    return <div>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { mb: 1.5},
        }}
        onSubmit={formik.handleSubmit}
        >
            <TextField                
                fullWidth
                id="name"
                name="name"
                label="Jméno"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled={submitting}
            />  
            <TextField
                id="area"
                name="area"
                label="Plocha"
                type="number"
                value={formik.values.area}
                disabled={submitting}
                onChange={formik.handleChange}
                error={formik.touched.area && Boolean(formik.errors.area)}
                helperText={formik.touched.area && formik.errors.area}
                sx={{ marginRight: 1 }}
            />          
            {getParentSelect()}       
            <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={submitting}>
                {props.buttonText}
            </Button>
            {error !== "" && 
                <Typography component="i" color="red">
                Při zpracovávání vašeho požadavku došlo k chybě: {error}
                </Typography>                  
            }
        </Box>
    </div>    
}