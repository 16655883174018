import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface ISearchTextFilterProps{
    onChange: (searchText: string) => void;
}

export default function SearchTextFilter(props : ISearchTextFilterProps){    
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        props.onChange(searchText);
    }, [searchText]) 

    return <Grid container spacing={2} rowSpacing={2}>
    <Grid item xs={12}>
        <TextField                
            fullWidth
            id="name"
            name="name"
            variant="filled"
            label="Hledat dle jména"
            value={searchText}onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(event.target.value);
            }}
        />
    </Grid>            
</Grid>
}