import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, useTheme } from '@mui/material';
import SHeader from '../text/SHeader';

export interface ISkobaTableDataProps{
  data : any[];
}

export interface ISkobaTableProps{
    data : any[];
    header : string[];
    rowDefinition: ISkobaTableRowDefinition;
}

export interface ISkobaTableRowDefinition{
    columnDefinitions : ISkobaTableColumnDefinition[];
    buttons? : (object : any) => JSX.Element[];
}
export interface ISkobaTableColumnDefinition{
    property : any;
    getValue? : (value : any) => any;
    onClick? : (object : any) => void;  
}

export default function SkobaTable(props : ISkobaTableProps) {
  const theme = useTheme();
  return (
    <TableContainer sx={{borderRadius: "0", maxHeight:"500px", overflow: "auto", background:"#e6e6e6"}}>
      <Table stickyHeader  sx={{ minWidth: 650}} aria-label="simple table" size="small">
        <TableHead>
            <TableRow sx={{ background:"#dcdcdc!important", '& th': {background:"#dcdcdc", fontWeight: 'bold', fontSize:"1.2em", color: 'black', border: "1px solid #aeaeae; "}}}>
            {props.header.map((title, ix) => 
                <TableCell key={ix} align="center"><SHeader bold text={title}/></TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow 
                sx={{ height:"2px",
                  '& td': { color: 'black', border: "1px solid #aeaeae;", height:"0", padding:"1px"}}}>
                     {props.header.map((title, ix) => 
                <TableCell key={ix} align="center"></TableCell>)}
            </TableRow>
          {props.data.map((object, ix) => (
            <TableRow
              key={ix}
              sx={{ '& td': { color: 'black', border: "1px solid #aeaeae;"},}}
            >
              {props.rowDefinition.columnDefinitions.map((colDef, ix) =>{
                const value = colDef.getValue ? colDef.getValue(object) : object[colDef.property];
                if(value === undefined || value === "" || value === null) return <TableCell key={ix} align="center">-</TableCell>;
                return  <TableCell key={ix} align="center" onClick={() => colDef.onClick?.(object)} sx={colDef.onClick !== undefined ? {
                  cursor: "pointer",
                  color: theme.palette.primary.main + " !important"
                } : {}}>
                  {value}
                </TableCell>
              })}
              {props.rowDefinition.buttons !== undefined && 
              <TableCell sx={{background:"inherit!important", border: "none!important"}}>
                <Box sx={{display:"flex", flexDirection:"column", height:"100%"}}>
                  {props.rowDefinition.buttons(object).map((button, ix) => {
                    return <Box key={ix}>{button}</Box>;
                  })}
                </Box>
              </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}