import { createEmpty, extend } from "ol/extent";
import { Geometry } from "ol/geom";
import { Layer } from "ol/layer";
import { fromLonLat, Projection } from "ol/proj";
import VectorSource from "ol/source/Vector";
import React, { ReactNode } from "react";
import { RLayerVector, RMap } from "rlayers";
import { RView } from "rlayers/RMap";
import "./Map.css"
import "ol/ol.css";
import { IVectorLayer } from "./ControlMap";
import {createCustomLoader, GeometryType, onFeaturesLoadEnd} from "./MapFunctions";
import { Box, Button, Paper } from "@mui/material";
import { GeoJSON } from "ol/format";
import { IConnectorEntityDetailed, SkobaEntityType } from "../../../types/overview/skobaTypes";
import { getTextPointStyle } from "./LocalMapStyles";
import { RStyle } from "rlayers/style";
import { Feature } from "ol";
import { Navigate } from "react-router-dom";

export interface IActiveConnector{
    type: SkobaEntityType;
    id: number;
}

export interface ILocalMapState{
    view : RView;
    map : React.RefObject<RMap>;
    wfsLayers : IWFSLayer[];
    vectorLayers : IVectorLayer[];
    projection : Projection;
    loaded: boolean;
    cursorPosition: number[];
    activeConnectors: IActiveConnector[];
    navigateTo: string;

}
export interface IWFSLayer{
    style: (active: boolean, text?: string) => JSX.Element;
    link: string;
    type: GeometryType;
    onEnter: () => void;
    onLeave: () => void;
    onClick: (id: number) => void;
}


const origin = [14.4620, 50.0824];

// const newCenter = [-544006.67 -1144002.81]

const osmProj = new Projection({
    code: 'EPSG:5514',
    units: 'degrees',
    global: true
});

export interface ILocalMapProps{
}

class LocalMap extends React.Component<ILocalMapProps, ILocalMapState>{
    loadedLayers = 0;
    constructor(props : ILocalMapProps){
        super(props);
        this.state = ({
            navigateTo: "",
            view: { center: fromLonLat(origin), zoom: 8 },
            map : React.createRef() as React.RefObject<RMap>,
            wfsLayers: [],
            projection : osmProj,
            cursorPosition : [0,0],
            loaded : false,
            vectorLayers : [],
            activeConnectors: [],

        })        
    }
    refreshMap() : void{
        this.state.map?.current?.ol.updateSize();
    }
    //think about storing previous center and zoom
    zoomToFeature(keyPair: string[], level = 23){
        this.state.map.current?.ol.getAllLayers().forEach(x => {
            const feature = (x as Layer<VectorSource<Feature<Geometry>>>).getSource()?.getFeatures().find(x => x.get(keyPair[0]) === keyPair[1])
            if(feature){
                const extent = feature.getGeometry()?.getExtent()
                if(extent && extent[0] !== Infinity){
                    this.state.map.current?.ol.getView().fit(extent);
                    this.state.map.current?.ol.getView().setZoom(level);
                }  
            }
        })                   
    }
    centerOnLayers() : void{
        const layers = this.state.map.current?.ol.getAllLayers() as Layer<VectorSource<Feature<Geometry>>>[];
        if(layers && layers.length !== 0){
            const extent = createEmpty();
            layers.forEach(x => {
                const tempExtent =  x.getSource()?.getExtent();
                if(tempExtent)
                    extend(extent, tempExtent);
            })
            if(extent[0] !== Infinity){
                this.state.map.current?.ol.getView().fit(extent, {size: this.state.map.current?.ol.getSize()});
            }
        }       
    }
    setWfsLayers(data : IWFSLayer[]){
        this.loadedLayers = 0;
        this.setState({
            navigateTo: "",
            wfsLayers : data,    
            loaded : false,        
        })
    }
    setActiveConnectors(connectors: IActiveConnector[]){
        this.setState({
            activeConnectors: connectors
        })
    }
    getWfsLayers(): ReactNode{        
        //TODO: LEARN TO USE CALLBACKS https://mmomtchev.github.io/rlayers/#/geodata
        return <React.Fragment>
            {this.state.wfsLayers.map((x, idx) =>{
                return <RLayerVector key={idx}
                    zIndex={15}                
                    format={new GeoJSON({ featureProjection: "EPSG:404000"})}
                    onFeaturesLoadEnd={onFeaturesLoadEnd}
                    loader={createCustomLoader(x.link, () => this.centerOnLayers())}                    
                    onClick={(e) => {
                        if(x.link.includes("point")){
                            const connector = e.target.get("connector") as IConnectorEntityDetailed
                            this.setState({
                                navigateTo: "/overview/rooms/" + connector.parentId
                            })
                            // window.location.href = "/overview/rooms/" + connector.parentId
                            // window.history.pushState(null, '', "/overview/rooms/" + connector.parentId)
                            // // this.props.navigate("/overview/rooms/" + connector.parentId)
                            // // this.state.navi("/overview/rooms/" + connector.parentId);
                        }
                    }}
                    onPointerEnter={(e) => {
                        if(x.link.includes("polygons"))
                            $("#row-" + e.target.get("cislo_mist").replace(/ /g, '').replace(".","_")).addClass("selected-table-row");
                    }}
                    onPointerLeave={(e) => {
                        if(x.link.includes("polygons"))
                            $("#row-" + e.target.get("cislo_mist").replace(/ /g, '').replace(".","_")).removeClass("selected-table-row");
                    }}                
                >
                    {(x.link.includes("point") || x.link.includes("polygon")) ?
                        <RStyle
                            render={(f) => {
                                const connector = f.get("connector") as IConnectorEntityDetailed;
                                const isActive = this.state.activeConnectors.find(c => c.id == connector.parentId) !== undefined;                                
                                if(isActive){
                                    if(x.link.includes("point"))
                                        return getTextPointStyle(true, connector.parentName)
                                    return x.style(true)
                                }
                                else{
                                    if(x.link.includes("point"))
                                        return getTextPointStyle(false, connector.parentName)
                                    return x.style(false)
                                }
                            }}
                        />
                    :                        
                        <RStyle>
                            {x.style(false)}
                        </RStyle>
                    }
              </RLayerVector>
            })}
        </React.Fragment>    
    }
    render(): React.ReactNode {
        this.refreshMap();
        return (
        <React.Fragment>
            <RMap
                ref={this.state.map}
                className="local-map"
                initial={{center: fromLonLat(origin), zoom: 8}}
                view={[this.state.view, (view) => this.setState({view: view})]}
                onChange={() => {console.log("map changed")}}                
                onClick={(e) => {
                    this.setState({
                        cursorPosition: e.map.getCoordinateFromPixel(e.pixel)
                    })
                }}
                >
                {this.getWfsLayers()}
            </RMap>
            <Box sx={{
                    p: 0,
                    position: "absolute",
                    top: "50%",
                    right: "0",
                    transform: "translate(0, -50%)",
                    zIndex: 100,
                    backgroundColor: "white",
                    }}>                        
                        <Paper elevation={3} sx={{
                                paddingTop: 1,
                                paddingBottom: 1,
                                paddingLeft: 1,
                                display: 'flex',
                                flexDirection: 'column',  
                                gap: "5px",
                                backgroundColor: "#f2f2f2"
                            }}>
                            <Button size="small" 
                                onClick={() => 
                                    this.centerOnLayers()}
                            >
                                CENTER
                            </Button>
                        </Paper>
                </Box>
            <div className="map-info">
                {/* <div className="map-info-text">
                    Pozice posledního kliknutí: <strong>{this.state.cursorPosition[0].toFixed(3)} {this.state.cursorPosition[1].toFixed(3)}</strong>
                </div> */}
                
            {this.state.navigateTo !== "" &&
                <React.Fragment>
                    <Navigate to={this.state.navigateTo}/>
                </React.Fragment>
            }
            </div>
        </React.Fragment>
        )
    }
}
export default LocalMap