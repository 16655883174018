import React, { PropsWithChildren, useEffect } from "react";
import { ReactElement } from "react";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export interface ITabProps{
    titles: (JSX.Element | string)[];

}

export default function TabLayout(props : PropsWithChildren<ITabProps>) : ReactElement{
    const [value, setValue] = React.useState('0');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {        
        const tabIndex = searchParams.get('tabIndex');
        if(tabIndex){
            setValue(tabIndex);
        }
        else{
            // const newSearchParams = new URLSearchParams(searchParams.toString());
            // newSearchParams.set('tabIndex', "0");
            // setSearchParams(newSearchParams);
        }
    }, [])    
    function updateTablIndex(index : string){
        // const newSearchParams = new URLSearchParams(searchParams.toString());
        // newSearchParams.set('tabIndex', index);
        // setSearchParams(newSearchParams);
        setValue(index);
    }
    
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        updateTablIndex(newValue);
    };

    return (
        <Box sx={{ width: '100%', height:"100%", typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, mb:1, borderColor: 'divider', textAlign: "center" }}>
                    
                <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
                    {
                        props.titles.map((title, index) => {
                            return <Tab key={index} label={title} value={index.toString()} />
                        })
                    }
                </TabList>
                </Box>
                {React.Children.map(props.children, (child, index) => {
                    // maxHeight : "750px", overflowY:"auto"
                    return <TabPanel key={index} sx={{p:0}} value={index.toString()}>
                        {child}
                    </TabPanel>
                    })}                
            </TabContext>
        </Box>
      );
}